import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  GET_BILLING_REQUESTS_TYPES,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getBillingResquestsList =
  (page, limit, parameter) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      let query = "";
      if (parameter.postingStatus === "true") {
        query += `Posted=true`;
      } else if (parameter.postingStatus === "false") {
        query += `Posted=false`;
      }
      if (parameter.fromDate) {

        query += `&fromDate=${parameter.fromDate}`;
      }
      if (parameter.toDate) {
        query += `&toDate=${parameter.toDate}`;
      }

      if (parameter.matterId) {
        query += `&MatterId=${parameter.matterId}`;
      }

      const datas = await api.getBillRequestsList(page, limit, query);

      dispatch({ type: GET_BILLING_REQUESTS_TYPES, payload: datas.data });

      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) { handleApiError(error) }
  };
