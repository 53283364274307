import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import styles from "../../pages/Postings/Posting.module.scss";
import image from "../../assets/success.png";

const Modalposting = ({ data, closeModal, reset }) => {
  return (
    <div>
      {data && (
        <Dialog>
          <div className={`${styles[`postingDialog`]} d-flex `}>
            {data?.code === 200 ? (
              <div className="justifiy-content-center d-flex flex-column align-items-center vw-100">
                <img src={image} className="mx-auto mt-2 mb-2"></img>
                <div className="mt-3">
                  {data?.data?.map((transaction) => (
                    <div
                      className={`mx-auto mb-2 ${styles[`modal-text`]}`}
                      key={transaction?.transactionNo}
                    >
                      <p>
                        Transaction: {transaction?.transactionNo} -{" "}
                        {transaction?.postingType}
                      </p>
                    </div>
                  ))}
                </div>
                <div className={`mx-auto mt-1 ${styles[`modal-text`]}`}>
                  <p>Posted Successfully</p>
                </div>
                <button className={`common-btn mt-3`} onClick={reset}>
                  Done
                </button>
              </div>
            ) : (
              <>
                <div className="justifiy-content-center d-flex flex-column align-items-center vw-100">
                  <h3 className="primary mt-3 text-danger">Error!</h3>
                  <p className="mt-3 text-center">{data?.data?.message}</p>
                  <button className={`common-btn mt-3`} onClick={closeModal}>
                    Done
                  </button>
                </div>
              </>
            )}
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default Modalposting;
