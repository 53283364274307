export const PostingSubmitFilter = (list) => {
  const dateselector = new Date();
  console.log(list);
  const data = list.map((data) => {
    let fileIds = data?.documentFiles?.map((d) => d.fileID);
    if (data?.postingType?.code === "DUU") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        isCounselFee: data.isCounselFee,
        vatCode: data?.vatCode?.vatCodeId,
        vatRate: data?.vatCode?.rate,
        vatAmount: data?.vatAmount,
        documentFiles: fileIds,
        chitId: data?.chitId || undefined,
      };
    }
    if (data?.postingType?.code === "CTC") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        matterIDTo: data.matterIDTo?.matterId,
        clientBank: data.clientBankFrom?.nominalRef,
        clientBankTo: data.clientBankTo?.nominalRef,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        isCounselFee: data.isCounselFee,
        documentFiles: fileIds,
        chitId: data?.chitId || undefined,
      };
    }
    if (data?.postingType?.code === "DPU") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        payee: data.payee || "",
        isCounselFee: data.isCounselFee,
        officeBank: data?.officeBank?.nominalRef,
        vatCode: data?.vatCode?.vatCodeId,
        vatRate: data?.vatCode?.rate,
        vatAmount: data?.vatAmount,
        chitId: data?.chitId || undefined,
        documentFiles: fileIds,
      };
    }
    if (data?.postingType?.code === "ODR") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        payee: data.payee || "",
        isCounselFee: data.isCounselFee,
        officeBank: data?.officeBank?.nominalRef,
        chitId: data?.chitId || undefined,
        // vatCode: data?.vatCode?.vatCodeId,
        // vatRate: data?.vatCode?.rate,
        // vatAmount: data?.vatAmount,
        documentFiles: fileIds,
        chitId: data?.chitId || undefined,
      };
    }

    if (data?.postingType?.code === "PIN") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: 0,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        isCounselFee: data.isCounselFee,
        vatCode: data?.vatCode?.vatCodeId,
        vatRate: data?.vatCode?.rate,
        supplierRef: data?.supplierRef?.supplierRef,
        branchId: data?.branchId?.branchId,
        vatAmount: data?.vatAmount,
        nominalRef: data?.nominalRef?.nominalRef,
        documentFiles: fileIds,
        chitId: data?.chitId || undefined,
      };
    }

    if (data?.postingType?.code === "DIN") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        isCounselFee: data.isCounselFee,
        vatCode: data?.vatCode?.vatCodeId,
        vatRate: data?.vatCode?.rate,
        vatAmount: data?.vatAmount,
        supplierRef: data?.supplierRef?.supplierRef,
        documentFiles: fileIds,
        chitId: data?.chitId || undefined,
      };
    }

    if (data?.postingType?.code === "OCR") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        payer: data.payer || "",
        isCounselFee: data.isCounselFee,
        officeBank: data?.officeBank?.nominalRef,
        chitId: data?.chitId || undefined,
        // vatCode: data?.vatCode?.vatCodeId,
        // vatAmount: data?.vatAmount,
        // vatRate: data?.vatCode?.rate,
        documentFiles: fileIds,
        chitId: data.chitId,
      };
    }

    if (data?.postingType?.code === "CCR") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        payer: data.payer || "",
        isCounselFee: data.isCounselFee,
        clientBank: data?.clientBank?.nominalRef,
        chitId: data?.chitId || undefined,
        // vatAmount: data?.vatAmount,
        // vatCode: data?.vatCode?.vatCodeId,
        // vatRate: data?.vatCode?.rate,
        documentFiles: fileIds,
      };
    }

    if (data?.postingType?.code === "CDR") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        payee: data.payee || "",
        isCounselFee: data.isCounselFee,
        clientBank: data?.clientBank?.nominalRef,
        chitId: data?.chitId || undefined,
        // vatAmount: data?.vatAmount,
        // vatCode: data?.vatCode?.vatCodeId,
        // vatRate: data?.vatCode?.rate,
        documentFiles: fileIds,
      };
    }

    if (data?.postingType?.code === "DCR") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        payer: data.payer || "",
        isCounselFee: data.isCounselFee,
        ddaBank: data?.ddaBank.nominalRef,
        chitId: data?.chitId || undefined,
        // vatAmount: data?.vatAmount,
        // vatCode: data?.vatCode?.vatCodeId,
        // vatRate: data?.vatCode?.rate,
        documentFiles: fileIds,
      };
    }

    if (data?.postingType?.code === "DDR") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        netAmount: data.netAmount,
        feeEarnerRef: data.feeEarnerRef.userRef,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        payee: data.payee || "",
        isCounselFee: data.isCounselFee,
        ddaBank: data?.ddaBank?.nominalRef,
        chitId: data?.chitId || undefined,
        // vatAmount: data?.vatAmount,
        // vatCode: data?.vatCode?.vatCodeId,
        // vatRate: data?.vatCode?.rate,
        documentFiles: fileIds,
      };
    }
    if (
      data?.postingType?.code === "CTO" ||
      data?.postingType?.code === "OTC"
    ) {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        matterID: data.matterID?.matterId,
        netAmount: data.netAmount,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        feeEarnerRef: data.feeEarnerRef.userRef,
        details: data.details,
        vatAmount: data?.vatAmount,
        documentFiles: fileIds,
        clientBank: data?.clientBank.nominalRef,
        officeBank: data?.officeBank.nominalRef,
        chitId: data?.chitId || undefined,
      };
    }
    if (data?.postingType?.code === "BP") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        netAmount: data.netAmount,
        date: data?.date || dateselector,
        transactionPeriod: null,
        branchId: data?.branchId?.branchId,
        currencyCode: "GBP",
        details: data.details,
        payee: data.payee,
        officeBank: data?.officeBank.nominalRef,
        nominalRef: data?.nominalRef?.nominalRef,
        vatCode: data?.vatCode?.vatCodeId,
        vatRate: data?.vatCode?.rate,
        vatAmount: data?.vatAmount,
        documentFiles: fileIds,
        chitId: data?.chitId || undefined,
      };
    }
    if (data?.postingType?.code === "BR") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        netAmount: data.netAmount,
        date: data?.date || dateselector,
        transactionPeriod: null,
        branchId: data?.branchId?.branchId,
        currencyCode: "GBP",
        details: data.details,
        payer: data.payer,
        officeBank: data?.officeBank.nominalRef,
        nominalRef: data?.nominalRef?.nominalRef,
        vatCode: data?.vatCode?.vatCodeId,
        vatRate: data?.vatCode?.rate,
        vatAmount: data?.vatAmount,
        documentFiles: fileIds,
        chitId: data?.chitId || undefined,
      };
    }
    if (data?.postingType?.code === "BT") {
      return {
        postingType: data?.postingType?.code,
        reference: data.reference,
        netAmount: data.netAmount,
        date: data?.date || dateselector,
        transactionPeriod: null,
        currencyCode: "GBP",
        details: data.details,
        payer: data.payer,
        officeBank: data?.officeBank.nominalRef,
        officeBankTo: data?.officeBankTo.nominalRef,
        documentFiles: fileIds,
        chitId: data?.chitId || undefined,
      };
    }
    if (data?.postingType?.code === "OTN" || data?.postingType?.code === "NTO" || data?.postingType?.code === "NTB") {
      return {
        postingType: data?.postingType?.code,
        matterID: data.matterID?.matterId,
        date: data?.date || dateselector,
        reference: data.reference,
        netAmount: data.netAmount,
        currencyCode: "GBP",
        feeEarnerRef: data.feeEarnerRef.userRef,
        details: data.details,
        transactionPeriod: null,
        nominalRef: data?.nominalRef?.nominalRef,
      };
    }
  });
  return data;
};
