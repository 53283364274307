import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useState } from "react";
import { Label } from "@progress/kendo-react-labels";

const CustomVatCode = (fieldRenderProps) => {

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;

    const activevatsdata = useSelector((state) => {
        let list = state.vatcodes.activevats.data.map((item) => {
            const templist = item;
            templist.representation =
                item.vatCodeId + "  -  " + item.details + "  -  " + item.rate + "%";

            return templist;
        });
        return list;
    });

    let initialItem = {};
    activevatsdata.map((item) => {
        if (item.vatCodeId === value) {
            initialItem = item;
        }
    });

    const [valueVat, setValueVat] = useState(initialItem);
    const onValueChange = React.useCallback(
        (e) => {
            setValueVat(e.value);
            // onChange callback expects argument with 'value' property
            onChange({
                value: e.value,
            });
        },
        [onChange, value]
    );

    return (
        <div onFocus={onFocus} onBlur={onBlur} className="d-flex flex-column">
            <Label className="label mt-1 mb-2  w-100">VAT&nbsp;</Label>
            <ComboBox
                data={activevatsdata}
                value={valueVat}
                iconClassName="wa"
                textField={"representation"}
                onChange={onValueChange}
                placeholder={"Search VAT Code"}
            />
            <label className={"k-checkbox-label"} htmlFor={others.id}>
                {others.label}
            </label>
        </div>
    );
};

const CustomNominalCode = (fieldRenderProps) => {
    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;

    const nominallist = useSelector((state) => {
        let array = [];
        let list = state.nominalList.activenominals.data.map((item) => {
            if (item.subType === "ProfitAndLoss_Income") {
                const templist = item;
                templist.representation = item.nominalRef + "  -  " + item.nominalName;
                array.push(item);
                return templist;
            }
        });
        return array;
    });

    let initialItem = {};

    nominallist.map((item) => {
        if (item.nominalRef === value) {
            initialItem = item;
        }
    });

    const [valueNominal, setValueNominal] = useState(initialItem);

    const onValueChange = React.useCallback(
        (e) => {
            setValueNominal(e.value);
            // onChange callback expects argument with 'value' property
            onChange({
                value: e.value.nominalRef,
            });
        },
        [onChange, value]
    );

    return (
        <div onFocus={onFocus} onBlur={onBlur} className="d-flex flex-column">
            <Label className="label mt-1 mb-2  w-100">Nominal&nbsp;</Label>
            <ComboBox
                data={nominallist}
                value={valueNominal}
                textField={"representation"}
                iconClassName="wa"
                onChange={onValueChange}
                placeholder={"Search Nominal"}
            />
            <label className={"k-checkbox-label"} htmlFor={others.id}>
                {others.label}
            </label>
        </div>
    );
};

const EditFeeHeaderItems = (props) => {
    const ADD_NEW = props.type === "addNew";
    return (
        <Dialog
            title={ADD_NEW ? "Add Item" : `Edit ${props?.item?.details}`}
            onClose={props.cancelEdit}
            width={600}
        >
            <div className="p-3 ps-4">
                <Form
                    onSubmit={props.onSubmit}
                    initialValues={props.item}
                    render={(formRenderProps) => (
                        <FormElement
                            style={{
                                maxWidth: 1200,
                            }}
                        >
                            <fieldset className={"k-form-fieldset"}>
                                <div className="mb-2">
                                    <Label className="label mt-1 mb-2  w-100">
                                        Details&nbsp;
                                    </Label>
                                    <Field name={"details"} component={Input} />
                                </div>
                                <div className="mb-2">
                                    <Field name={"vatCode"} component={CustomVatCode} />
                                </div>
                                <div className="mb-2">
                                    <Field name={"nominalRef"} component={CustomNominalCode} />
                                </div>
                                <div className="right-align mb-3">
                                    <Label className="label mt-1 mb-2  w-100">
                                        Net Amount&nbsp;
                                    </Label>
                                    <Field
                                        name={"netAmount"}
                                        component={NumericTextBox}
                                        spinners={false}
                                        format="n2"
                                        style={{ textAlign: "right" }}
                                    />
                                </div>
                                <Field
                                    defaultChecked={true}
                                    name={"active"}
                                    component={Checkbox}
                                    label={"Active"}
                                />
                            </fieldset>
                            <button
                                style={{ visibility: "hidden" }}
                                id="fee-header-item-form"
                                type={"submit"}
                            ></button>
                        </FormElement>
                    )}
                />
            </div>
            <div className="dialog-footer">
                <button
                    className="common-btn me-2 bgBtnSecondary"
                    onClick={props.cancelEdit}
                >
                    Cancel
                </button>
                <button
                    className="common-btn me-3"
                    onClick={() =>
                        document.getElementById("fee-header-item-form").click()
                    }
                    type={"submit"}
                >
                    {ADD_NEW ? "Save" : "Update"}
                </button>
            </div>
        </Dialog>
    );
};

export default EditFeeHeaderItems;
