import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_BILLING_REQUESTS_TYPES,
} from "../types/index.js";

const billingRequestsReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_BILLING_REQUESTS_TYPES:
      return {
        ...state,
        billRequestsList: action.payload,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default billingRequestsReducer;
