import * as React from "react";
// import Modal from "../Modal";
import { Button } from "@progress/kendo-react-buttons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Form, FormElement } from "@progress/kendo-react-form";
// import { addTimeFunctionality } from "actions/time.action";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import * as api from "../../api/index";
import { Label } from "@progress/kendo-react-labels";
import { DocumentsUpload } from "../Postings/DcoumentsUpload";
import { onFileUpload } from "../../Services/fileDataService";
import { TextArea } from "@progress/kendo-react-inputs";
import MattersCombobox from "../Postings/MattersComboBox";
import Modalposting from "../Postings/PostingModal";
import CONSTANT from "CONSTANT/Matter/ComboxType";
import { useUploadForm } from "../../components/fileUploadHook";
import {
  createposting,
  getOfficeCreditMatterList,
  removeOfficeCreditMatterList,
} from "actions/postings.action";
import { clearFile } from "actions/files.action";
import OfficeCreditGrid from "./OfficeCreditAndAllocationGrid";
import SearchComboBox from "components/common/SearchComboBox";
import { toast } from "react-toastify";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { TransactionPeriod } from "components/common/TransactionPeriod";

const OfficeCreditAndAllocations = (props) => {
  const dispatch = useDispatch();
  const date = new Date();
  const [details, setDetails] = useState("");
  const [reference, setReference] = useState("");
  const [payer, setPayer] = useState("");
  const [rate, setRate] = useState("");
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dateselector, setdateselector] = useState(date);
  const [matterRef, setmatterref] = useState();
  const [officevalue, setofficevalue] = useState();
  const [allocation, setAllocation] = useState(null);
  const [resetmatter, setresetmatter] = useState(false);
  const [modalvalue, setmodalvalue] = useState(false);
  const [error, setError] = useState(undefined);
  const [resetDocs, setresetDocs] = useState(false);
  const [feeearnerref, setfeeearnerref] = useState();
  const [initialData, setInitialData] = useState({
    date: new Date(Date.now()),
  });
  const [validationError, setValidationError] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const filesCurrentData = useSelector(
    (state) => state.filesDownload.officeBillandAllocationFiles
  );

  useEffect(() => {
    return () => {
      dispatch(removeOfficeCreditMatterList());
    };
  }, []);

  const onChangematter = (event) => {
    if (event.value !== null) {
      dispatch(removeOfficeCreditMatterList());
      setmatterref(event?.value?.matterId);
      dispatch(getOfficeCreditMatterList(event.value.matterId));
    } else {
      dispatch(removeOfficeCreditMatterList());
    }
  };

  const onChangedateselect = (event) => {
    setdateselector(event.value);
  };

  const isSelected = selectedData?.some((item) => item.add === true);
  useEffect(() => {
    if (isSelected && validationError) {
      setValidationError(false);
    }
  }, [isSelected]);

  async function handleSubmit(values) {
    const data = [
      {
        postingType: "OCR",
        matterID: matterRef,
        feeEarnerRef: values?.feeEarner?.userRef,
        date: dateselector,
        netAmount: amount,
        transactionPeriod: 202201,
        currencyCode: "GBP",
        details: details,
        reference: reference,
        payer: payer,
        OfficeBank: values?.officeBank?.nominalRef,
      },
    ];

    const requiredFields = [
      matterRef,
      details,
      reference,
      payer,
      values?.officeBank?.nominalRef,
      values?.feeEarner?.userRef,
    ];

    if (isSelected) {
      if (amount == 0) {
        setValidationError(false);
      } else if (requiredFields.some((field) => !field)) {
        setValidationError(true);
        return;
      } else {
        setValidationError(false);
      }
    } else {
      if ([amount, ...requiredFields].some((field) => !field)) {
        setValidationError(true);
        return;
      }
    }

    if (allocation !== null) {
      data[0].allocations = allocation;
    }
    if (filesCurrentData[0] !== undefined) {
      const docsId = [];
      filesCurrentData.forEach((item) => {
        docsId.push(item.fileID);
      });
      data[0].DocumentFiles = docsId;
    }
    if (isSelected && allocation?.length > 0) {
      const totalAllocation = allocation
        .map((item) => item.amount)
        .reduce((a, b) => a + b, 0);
      if (totalAllocation - amount !== 0) {
        return toast.error("Invalid Allocation");
      }
    }

    try {
      setLoading(true);
      const response = await api.createposting(data);
      toast.success(
        `Posted Successfully for ${response?.data?.data[0]?.postingType} - ${response?.data?.data[0]?.transactionNo} `
      );
      reset();
    } catch (error) {
      toast.error("Error occured in posting");
    } finally {
      setLoading(false);
    }
  }
  const reset = () => {
    dispatch(removeOfficeCreditMatterList());
    setDetails("");
    setfeeearnerref(null);
    setofficevalue(null);
    setAmount(0);
    setPayer("");
    setReference("");
    setRate("");
    // setresetmatter(!resetmatter);
    setresetDocs(!resetDocs);
    dispatch(getOfficeCreditMatterList(matterRef));
    setError(false);
    dispatch(clearFile("OfficeCreditandAllocation"));
  };

  const { isSuccess, uploadForm, progress } = useUploadForm();

  const [filesData, setFilesData] = React.useState([]);

  const handleUpload = async (e) => {
    let data = onFileUpload(e);
    setFilesData([...filesData, data]);

    const parameters = {
      documentType: "Posting",
      matterId: matterRef || "",
    };
    const dataChoosen = await uploadForm(data, e.target.files[0], parameters);
    data.fileID = dataChoosen.data.data.fileID;
  };
  const onFileRemove = (fileId) => {
    setFilesData((prev) => prev.filter((file) => file.fileID !== fileId));
  };

  return (
    <div
      className="container-fluid p-0"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="row h-100 g-0">
        <div
          className="ms-2 bgForm pt-4 d-flex justify-content-center align-item-center"
          style={{
            width: "350px",
            overflow: "auto",
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Form
            ignoreModified={true}
            onSubmit={handleSubmit}
            initialValues={initialData}
            key={JSON.stringify(initialData)}
            render={(formRenderProps) => {
              return (
                <FormElement name="ocrform" id="ocrform">
                  <fieldset>
                    {formRenderProps.visited &&
                      formRenderProps.errors &&
                      formRenderProps.errors.VALIDATION_SUMMARY && (
                        <div className={"k-messagebox k-messagebox-error"}>
                          {formRenderProps.errors.VALIDATION_SUMMARY}
                        </div>
                      )}

                    <div className="mb-3">
                      <Label className="label">Matter</Label>
                      <div
                        style={{
                          borderRadius: "10px",
                          boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <MattersCombobox
                          error={validationError && !matterRef}
                          onChangematter={onChangematter}
                          reset={resetmatter}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label className="label">Date</Label>
                      <DatePicker
                        className="datepicker"
                        value={dateselector}
                        onChange={onChangedateselect}
                        dateInput={CustomDateInput}
                        toggleButton={CalenderCustomToggleButton}
                        defaultValue={dateselector}
                      />
                    </div>
                    <div className="mb-3">
                      <SearchComboBox
                        customValidation={true}
                        name="officeBank"
                        cbType={CONSTANT.OB}
                        width="auto"
                        boxShadow={true}
                        customStyle={{
                          border:
                            validationError &&
                            !formRenderProps.valueGetter("officeBank") &&
                            "1px solid red",
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="label">Reference</Label>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Input
                          // required={true}
                          width={200}
                          value={reference}
                          onChange={(e) => {
                            setReference(e.target.value);
                          }}
                          style={{
                            border:
                              validationError && !reference
                                ? "1px solid red"
                                : "1px solid #e6e6e6",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 16px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-3 right-align">
                      <Label className="label">Amount</Label>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <NumericTextBox
                          // required={true}
                          width={300}
                          style={{
                            textAlign: "right",
                            border:
                              validationError && !amount
                                ? "1px solid red"
                                : "1px solid #e6e6e6",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 16px",
                          }}
                          value={amount}
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          placeholder="0.00"
                          spinners={false}
                          format="n2"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <SearchComboBox
                        name="feeEarner"
                        cbType={CONSTANT.FE}
                        // required={false}
                        customValidation
                        width="auto"
                        boxShadow={true}
                        customStyle={{
                          border:
                            validationError &&
                            !formRenderProps.valueGetter("feeEarner") &&
                            "1px solid red",
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="label">Payer</Label>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Input
                          // required={true}
                          width={200}
                          value={payer}
                          onChange={(e) => {
                            setPayer(e.target.value);
                          }}
                          style={{
                            border:
                              validationError && !payer
                                ? "1px solid red"
                                : "1px solid #e6e6e6",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 16px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label className="label">Details</Label>
                      <div
                        style={{
                          borderRadius: "10px",
                          boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <TextArea
                          // required={true}
                          rows={4}
                          placeholder="Enter Details"
                          value={details}
                          onChange={(e) => {
                            setDetails(e.target.value);
                          }}
                          style={{
                            border:
                              validationError && !details
                                ? "1px solid red"
                                : "none",
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-3 d-flex justify-content-end">
                      <DocumentsUpload
                        progress={progress}
                        filesData={filesData}
                        onFileRemove={onFileRemove}
                        onUpload={handleUpload}
                        type={"OfficeCreditandAllocation"}
                        onError={() => {
                          const newFiles = filesData.splice(
                            filesData.length,
                            1
                          );
                          setFilesData([...newFiles]);
                        }}
                        reset={resetDocs}
                      />
                    </div>
                  </fieldset>
                </FormElement>
              );
            }}
          />

          <Modalposting
            value={modalvalue}
            reset={() => {
              reset();
            }}
            closeModal={() => {
              setmodalvalue(false);
            }}
            component={"Posting"}
          ></Modalposting>
        </div>
        <div className="col" style={{ overflowX: "auto" }}>
          <TransactionPeriod />
          <div className="mt-3 ms-3 me-3">
            <OfficeCreditGrid
              Amount={amount}
              updateAllocations={(value) => {
                setAllocation(value);
              }}
              selectedData={(value) => {
                setSelectedData(value);
              }}
            />

            <div className="buttonstyle">
              <Button
                className="common-btn"
                form="ocrform"
                type="submit"
                disabled={loading}
              >
                Post
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OfficeCreditAndAllocations;
