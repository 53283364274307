import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import * as api from "../../../api/index";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ProfitCostTemplateList from "./FixedHeaderList";
import styles from "../Settings.module.scss";
import {
  getFeeHeaderItems,
  getProfitCostDetails,
} from "actions/fixedFeeHeader.action";
import EditFixedFeeHeader from "./EditFixedFeeHeader";

const ProfitCostTemplate = (props) => {
  const [addnewform, setaddnewform] = React.useState(false);
  const dispatch = useDispatch();
  const closeform = () => {
    setaddnewform(false);
    // props.onCancel();
  };

  React.useEffect(() => {
    if (props.onClose === false) {
      closeform();
    }
  }, [props.onClose]);

  const handleSubmit = async (e) => {
    setaddnewform(false);
    try {
      let allowedApps = "";
      e.allowedApps.map((item) => {
        if (e.allowedApps.indexOf(item) + 1 === e.allowedApps.length) {
          allowedApps += item;
        } else {
          allowedApps += item + ",";
        }
        return item;
      });
      e.allowedApps = allowedApps;
      e.type = "ProfitCost";
      await api.addProfitCostTemplate(e);
      toast.success("Template Added");
      dispatch(getProfitCostDetails());
    } catch (error) {
      toast.error("Error Occured");
    }
  };

  return (
    <React.Fragment>
      <>
        <div className="row">
          <div className="col-lg-10 col-md-8"></div>
          <Button
            className={`common-btn col-lg-1 col-md-4 p-2 py-3 mt-5 ${
              styles[`button`]
            } py-4`}
            onClick={() => {
              setaddnewform(true);
              props.open();
            }}
          >
            Add<br></br>
            Template
          </Button>
        </div>
        <div className={`row mx-auto mt-5 ${styles[`branches-grid`]}`}>
          <ProfitCostTemplateList />
        </div>
      </>
      {addnewform && (
        <EditFixedFeeHeader
          cancelEdit={closeform}
          onSubmit={handleSubmit}
          type="addNew"
        />
      )}
    </React.Fragment>
  );
};

export default ProfitCostTemplate;
