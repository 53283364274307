import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";
import { filterBy } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import * as api from "../../api/index";
import {
  AmountFormat,
  AmountFormatCell,
  DateFormatCell,
} from "components/common/formatter";
import { useState, useEffect } from "react";
import SearchComboBox from "components/common/SearchComboBox";
import CONSTANT from "CONSTANT/Matter/ComboxType";
import { NameTextCell } from "components/common/GridComponents";
import { Checkbox } from "@progress/kendo-react-inputs";

const MattersTimeRecords = (props) => {
  const dispatch = useDispatch();
  const [dataAvailable, setDataAvailable] = useState(false);
  const [error, setError] = useState(undefined);
  const [error1, setError1] = useState(undefined);
  const [nominal, setnominal] = useState();
  const [nominaldata, setnominaldata] = React.useState();
  const [data, setData] = React.useState([]);
  const [initialData, setInitialData] = useState();
  const [matters, setMatters] = useState([]);

  const AmountNameHeader = (props) => {
    return (
      <div style={{ textAlign: "center", marginBottom: "8px" }}>
        {props.title}
      </div>
    );
  };
  // const matters = useSelector((state) => {
  //   if (state.billsMatterData.matterTimeRecords !== undefined) {
  //     let dataList = [];
  //     let list = state.billsMatterData.matterTimeRecords.map((item) => {
  //       dataList.push(
  //         Object.assign(
  //           {
  //             inEdit: true,
  //             type: "Time",
  //             billVat: 0,
  //             add: false,
  //           },
  //           item
  //         )
  //       );
  //     });
  //     return dataList;
  //   } else {
  //     return ["LIST_EMPTY"];
  //   }
  // });
  const onChangenominal = (event) => {
    setnominal(event.value);
  };

  const filternominalData = (filter) => {
    const data = nominallist.slice();
    return filterBy(data, filter);
  };
  const filternominal = (event) => {
    setnominaldata(filternominalData(event.filter));
  };
  const activevatsdata = useSelector((state) => {
    let list = state.vatcodes.activevats.data.map((item) => {
      const templist = item;
      templist.representation =
        item.vatCodeId + "  -  " + item.details + "  -  " + item.rate + "%";

      return templist;
    });
    return list;
  });
  const nominallist = useSelector((state) => {
    let array = [];

    let list = state.nominalList.activenominals.data.map((item) => {
      if (item.subType === "ProfitAndLoss_Income") {
        const templist = item;
        templist.representation = item.nominalRef + "  -  " + item.nominalName;
        array.push(item);
        return templist;
      }
    });
    return array;
  });

  const getNominals = async () => {
    const { data } = await api.getactivenominals();
    let array = [];

    data.data.map((item) => {
      if (item.subType === "ProfitAndLoss_Income") {
        const templist = item;
        templist.representation = item.nominalRef + "  -  " + item.nominalName;
        array.push(item);
        return templist;
      }
    });
    return array;
  };

  const handleSubmit = (values) => {
    let dataToPost = [];
    data.map((item) => {
      if (item.add === true) {
        dataToPost.push(item);
      }
    });
    if (dataToPost.length === 0) {
      setError1(true);
      return;
    }
    props.changeMatterList(dataToPost);

    props.addNominalVatInfo({
      nominalRef: nominal,
      vatCode: values.activeVats,
      feeEarnerRef: values.feeEarner.userRef,
    });

    props.cancelEdit();
  };

  const itemChange = (e) => {
    let newData = data.map((item) => {
      if (item.timeId === e.dataItem.timeId) {
        item.add = e.value;
      }
      return item;
    });
    setData(newData);
  };

  const fetchNominal = async () => {
    const data = await getNominals();
    setnominaldata(data?.slice());
    setnominal(
      data?.find(
        (item) => item.nominalRef == props.defaultValues?.defaultProfitNominal
      )
    );
  };

  const getMatterTimeRecordsData = async () => {
    try {
      const response = await api.getMatterTimeRecords(props.matterId);
      setMatters(
        response.data?.data?.map((record) => ({
          inEdit: true,
          type: "Time",
          billVat: 0,
          add: false,
          ...record,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    getMatterTimeRecordsData();
  }, [props.matterId]);

  const SelectionHeaderCell = (props) => {
    return (
      <div className="gap-2 mb-2 d-flex">
        <span>{props.title}</span>
        <Checkbox
          style={{
            width: "18px",
            height: "18px",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
          }}
          onChange={(e) => {
            if (e.value) {
              setData((prevData) =>
                prevData.map((item) => ({ ...item, add: true }))
              );
            } else {
              setData((prevData) =>
                prevData.map((item) => ({ ...item, add: false }))
              );
            }
          }}
          name="isCounselFee"
          value={data.filter((item) => !item.add).length === 0}
        />
      </div>
    );
  };

  useEffect(() => {
    fetchNominal();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (
        matters[0] !== null &&
        matters[0] !== "LIST_EMPTY" &&
        matters[0] !== undefined &&
        dataAvailable === false
      ) {
        setData(matters);
        setDataAvailable(true);
      } else if (matters[0] === "LIST_EMPTY") {
        setData([]);
        setDataAvailable(false);
      }
    }, 0);
  }, [matters]);

  const onHeaderSelectionChange = React.useCallback((event) => {
    console.log(event);
  });

  return (
    <Dialog title={`Time Record`} onClose={props.cancelEdit} width={"1100px"}>
      <div
        className="d-flex flex-column pt-4 pb-3 mb-3 px-5"
      >
          <Form
            ignoreModified={true}
            onSubmit={handleSubmit}
            initialValues={initialData}
            key={JSON.stringify(initialData)}
            render={(formRenderProps) => {
              return (
                <div className="w-100">
                  <FormElement id="timeRecord" name="timeRecord">
                    <fieldset>
                      {formRenderProps.visited &&
                        formRenderProps.errors &&
                        formRenderProps.errors.VALIDATION_SUMMARY && (
                          <div className={"k-messagebox k-messagebox-error"}>
                            {formRenderProps.errors.VALIDATION_SUMMARY}
                          </div>
                        )}
                      <div className="d-flex">
                        <div className="mb-3 me-3">
                          <Label className="label">Nominal Ref</Label>
                          <ComboBox
                            style={{ width: "300px" }}
                            data={nominaldata}
                            textField={`representation`}
                            filterable={true}
                            onFilterChange={filternominal}
                            iconClassName="wa"
                            placeholder={"Search Nominal Ref"}
                            value={nominal}
                            onChange={onChangenominal}
                          />
                        </div>

                        <div className="mb-3 me-3">
                          <SearchComboBox
                            name="feeEarner"
                            placeholder="Select Fee Earner"
                            cbType={CONSTANT.FE}
                            width="300px"
                            defaultValues={props.defaultValues}
                            defaultValueSetter={formRenderProps}
                          />
                        </div>

                        <div className="mb-3">
                          <SearchComboBox
                            name="activeVats"
                            placeholder="Select VAT Code"
                            cbType={CONSTANT.AVATS}
                            width="300px"
                            defaultValues={props.defaultValues}
                            defaultValueSetter={formRenderProps}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </FormElement>
                </div>
              );
            }}
          />

        <Grid
          data={data}
          dataItemKey={"timeId"}
          onItemChange={itemChange}
          editField="inEdit"
          className="grid-header-sm mt-2"
          style={{ height: "370px", overflow: "auto" }}
          // onHeaderSelectionChange={onHeaderSelectionChange}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
        >
          <Column
            field="date"
            title="Date"
            editable={false}
            cell={DateFormatCell}
            headerClassName="ps-4"
          />
          <Column
            field="details"
            title="Details"
            editable={false}
            width={"300"}
            cell={NameTextCell}
          />
          <Column field="units" title="Units" editable={false} />
          <Column
            field="value"
            title="Value"
            editable={false}
            cell={AmountFormatCell}
            headerCell={AmountNameHeader}
          />
          <Column
            field="value"
            title="Bill Net"
            editable={false}
            cell={AmountFormatCell}
            headerCell={AmountNameHeader}
          />
          <Column
            field="billVat"
            title="Bill Vat"
            editable={false}
            cell={AmountFormatCell}
            headerCell={AmountNameHeader}
          />
          <Column
            field="add"
            title="Select"
            className="d-flex align-items-center justify-center mt-2 ms-3"
            editor="boolean"
            width={"90px"}
            // headerSelectionValue={true}
            headerCell={SelectionHeaderCell}
          />
        </Grid>
      </div>
      <div className="dialog-footer">
        <button
          className="common-btn bgBtnSecondary border-0 me-3"
          onClick={() => {
            props.cancelEdit();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="k-button common-btn me-4"
          form="timeRecord"
        >
          Select
        </button>
      </div>
    </Dialog>
  );
};

export default MattersTimeRecords;
