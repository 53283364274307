import { combineReducers } from "redux";
import tabsReducer from "./tabs.reducers";
import mattersReducer from "./matters.reducer";
import nominalReducer from "./nominal.reducer";
import suppliersReducer from "./suppliers.reducer";
import usersReducer from "./users.reducer";
import currentuserReducer from "./currentuser.reducer";
import companyReducer from "./company.reducer";
import BranchesReducer from "./branches.reducer";
import BranchesNames from "./branchesnames.reducer";
import VatCodesReducer from "./vat.reducer";
import CurrencyReducer from "./currency.reducer";
import PostingsDetails from "./postings.reducer";
import TransactionPeriodReducer from "./transactionperiod.reducer";
import OpenProfileReducer from "./profileopener.reducer";
import AutoLoginReducer from "./autologin.reducer";
import TransactionReducer from "./postingDetails.reducer";
import FilesDownload from "./filesDownlaod.reducer";
import contactsReducer from "./contacts.reducer";
import timeReducer from "./time.reducer";
import billReducer from "./bill.reducer";
import HeaderName from "./header.reducer";
import ChitRequestReducer from "./chitRequest.reducer";
import ledgerLinks from "./ledgerlinks.reducer";
import chitPostingReducer from "./chitPosting.reducer";
import ProfitCostTemplate from "./fixedFeeHeader.reducer";
import ApplicationReducer from "./applications.reducer";
import CommonReducer from "./common.reducer";
import clientsReducer from "./clients.reducer";
import CategoriesReducer from "./categories.reducer";
import DepartmentsReducer from "./departments.reducer";
import ActivityReducer from "./activity.reducer";
import DashboardReducer from "./dashboard.reducer";
import ReferrerReducer from "./referrer.reducer";
import SourcesReducer from "./sources.reducer";
import CaseFilesReducer from "./casefiles.reducer";
import DocTypeReducer from "./doctype.reducer";
import TimeTypeReducer from "./timeType.reducer";
import BillingRequestsReducer from "./billingRequests.reducer";

const rootReducer = combineReducers({
  tabsData: tabsReducer,
  mattersList: mattersReducer,
  contactsList: contactsReducer,
  nominalList: nominalReducer,
  suppliersList: suppliersReducer,
  usersList: usersReducer,
  currentuser: currentuserReducer,
  company: companyReducer,
  time: timeReducer,
  branches: BranchesReducer,
  branchesnames: BranchesNames,
  vatcodes: VatCodesReducer,
  currency: CurrencyReducer,
  postingsdocs: PostingsDetails,
  transactionperiods: TransactionPeriodReducer,
  openProfile: OpenProfileReducer,
  loginOn: AutoLoginReducer,
  transaction: TransactionReducer,
  filesDownload: FilesDownload,
  billsMatterData: billReducer,
  headerName: HeaderName,
  chitRequest: ChitRequestReducer,
  ledgerLinks: ledgerLinks,
  chitPosting: chitPostingReducer,
  profitCostTemplate: ProfitCostTemplate,
  applications: ApplicationReducer,
  common: CommonReducer,
  clients: clientsReducer,
  categories: CategoriesReducer,
  departments: DepartmentsReducer,
  activities: ActivityReducer,
  timeTypes: TimeTypeReducer,
  dashboard: DashboardReducer,
  referrers: ReferrerReducer,
  sources: SourcesReducer,
  caseFiles: CaseFilesReducer,
  docTypes: DocTypeReducer,
  billingRequests: BillingRequestsReducer,
});

export default rootReducer;
