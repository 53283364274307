export const updateGroups = (groups, path, callback) => {
  return groups.map((group) => {
    if (group.groupName === path[0]) {
      if (path.length === 1) {
        return callback(group);
      }
      return {
        ...group,
        groups: updateGroups(group.groups, path.slice(1), callback),
      };
    }
    return group;
  });
};

export const transformGroupsToTreeData = (groups = [], parentPath = []) => {
  return groups?.map((group) => ({
    text: group.groupName,
    expanded: true,
    type: "group",
    path: [...parentPath, group.groupName],
    items: [
      ...group?.items?.map((item) => ({
        text: `${item.nominalRef} - ${item.nominalName}`,
        type: "item",
        path: [...parentPath, group.groupName, item.nominalRef],
        order: item.order,
      })),
      ...transformGroupsToTreeData(group.groups || [], [
        ...parentPath,
        group.groupName,
      ]),
    ],
    order: group.order,
  }));
};

export const transformTreeViewToAPI = (treeData) =>
  treeData.map(({ text, order, items = [] }) => ({
    groupName: text,
    order,
    groups: transformTreeViewToAPI(items.filter((i) => i.type === "group")),
    items: items
      .filter((i) => i.type === "item")
      .map(({ text, order }) => {
        const [ref, name] = text.split(" - ");
        return {
          nominalRef: ref?.trim() || "UNKNOWN_REF",
          nominalName: name?.trim() || "UNKNOWN_NAME",
          order,
        };
      }),
  }));
