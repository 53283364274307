import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Title from "./TabTitle";
import TransactionPeriod from "../../pages/TransactionPeriod/TransactionPeriod";
import ChitPosting from "../../pages/ChitPosting/ChitPosting";
import {
  useLocation,
  useNavigate,
  useParams,
  useMatch,
} from "react-router-dom";
import { addTab, removeTab } from "actions/tabs.actions";
import { nameFunction } from "components/common/formatter";
import PaymentProcessing from "pages/PaymentProcessing";
import { Tabs } from "components/Enums/TabEnums.ts";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import AccessRevokedModal from "components/common/AccessRevokedModal";
import BillRequests from "pages/BillRequests/BillRequests";

const AdminTabs = React.memo((props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useParams();
  const [tabs, setTabs] = React.useState([]);
  const location = useLocation();
  const tabsData = useSelector((state) => state.tabsData.adminTabs);
  const { allowed } = useAllowedLiscence(Tabs.ADMIN);

  const findContent = (item) => {
    switch (item) {
      case "transactionperiod":
        return "Transaction Period";
      case "yearend":
        return "Year End";
      case "billrequests":
        return "Bill Requests";
      case "chitposting":
        return "Chit Posting";
      case "paymentprocessing":
        return "Payment Processing";
      default:
        break;
    }
  };

  const urlDefiner = (e) => {
    if (params !== null) {
      let url = tabs[e]?.toLowerCase();
      if (url === undefined) {
        url = "";
      }
      navigate(`/admin/${url}`);
    }
  };

  const params = useMatch("admin/:data");

  useEffect(() => {
    if (params !== null) {
      let data = params?.params?.data;
      if (!tabs.includes(data?.toLowerCase())) {
        dispatch(addTab("admin", data?.toLowerCase()));
      } else {
        setSelected(tabs.indexOf(data?.toLowerCase()));
      }
    }
  }, [params]);

  useEffect(() => {
    if (tabsData) {
      setTabs(tabsData);
      let index = tabsData.indexOf(params?.params?.data.toLowerCase());
      setSelected(index === -1 ? 0 : index);
    }
  }, [tabsData]);

  const removeTabDispatcher = (tab) => {
    if (nameFunction(tab) === `${params?.params?.data}`) {
      let index = nameFunction(tabs[0]);
      navigate(`/admin/${index}`);
    }
    if (tabs.length === 1) {
      navigate("/");
    }
    dispatch(removeTab("admin", tab));
  };

  const [selected, setSelected] = React.useState(-1);
  // useEffect(() => {
  //     urlDefiner(selected)
  // }, [selected, tabs])

  const handleSelect = (e) => {
    // setSelected(e.selected);
    urlDefiner(e.selected);
  };

  return allowed ? (
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      keepTabsMounted={true}
      className="navigationTab"
    >
      {tabs.map((item, index) => {
        return (
          item && (
            <TabStripTab
              title={
                <Title
                  content={findContent(item)}
                  onTabRemove={removeTabDispatcher}
                  selected={findContent(tabs.at(selected))}
                  count={tabs.length}
                />
              }
              key={index}
            >
              <div>
                {item === "transactionperiod" ? <TransactionPeriod /> : ""}
                {item === "chitposting" ? <ChitPosting /> : ""}
                {item === "billrequests" ? <BillRequests /> : ""}
                {item === "paymentprocessing" && <PaymentProcessing />}
              </div>
            </TabStripTab>
          )
        );
      })}
    </TabStrip>
  ) : (
    <AccessRevokedModal removeTab={() => props.closeTab()} />
  );
});

export default AdminTabs;
