import React from "react";
import AuthorizationsGrid from "./AuthorizationsGrid";

const Authorizations = () => {
  return (
    <>
      <div>
        <div className="w-75 mx-auto">
          <div className={`row mt-5 w-100`}>
            <AuthorizationsGrid />
          </div>
        </div>
      </div>
    </>
  );
};

export default Authorizations;
