import React, { useState } from "react";
import ReportViewer from "./ReportViewerComponent";
import { RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Icon from "components/Global/icon";
import styles from "./Reports.module.scss";
import { useEffect } from "react";
import { SectionDividerFull } from "components/Contact/sectionDivider";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { CustomDateInput } from "components/common/CustomDateInput";
import { baseURL } from "api/axios.config";
import { getChartOfAccountBalance } from "api";

const NominalBalances = () => {

    const DateFormat = (value) => {
        let date = new Date(value);
        return date.toISOString().split("T")[0];
    };    

    const typesData = [
        {
            label: "Trial Balance",
            value: "",
        },
        {
            label: "Balance Sheet",
            value: "BalanceSheet",
        },
        {
            label: "Profit and Loss",
            value: "ProfitAndLoss",
        },
    ];

    const orderData = [
        {
            name: "Nominal Ref",
            value: "NominalRef",
        },
        {
            name: "Name",
            value: "NominalName",
        },
        {
            name: "Type",
            value: "Type",
        },
        {
            name: "Balance",
            value: "Balance",
        },
    ];

    let date = new Date();
    const [fromDate, setFromDate] = React.useState(date);

    const analyzeParameters = () => {
        setParameters({
            Type: type,
            ExcludeZero: excludeZero,
            ToDate: includeEndDate ? DateFormat(endDate) : undefined,
            OrderBy: orderbyType?.value,
        });
        setShouldLoad(true);
    };

    const [type, setType] = useState("");

    const [orderbyType, setOrderByType] = useState({        
        name: "Nominal Ref",
        value: "NominalRef",
    });

    const [includeFromDate, setIncludeFromDate] = useState(false);
    const [includeEndDate, setIncludeEndDate] = useState(false);
    const [excludeZero, setExcludeZero] = useState(true);
    const [shouldLoad, setShouldLoad] = useState(false);
    const [balanceSheetAccounts, setBalanceSheetAccounts] = useState([]);
    const [profitAndLossAccounts, setProfitAndLossAccounts] = useState([]);
    const [selectedBalanceSheetAccount, setSelectedBalanceSheetAccount] =
        useState(null);
    const [selectedProfitAndLossAccount, setSelectedProfitAndLossAccount] =
        useState(null);

    const onChangeFromDateSelect = (event) => {
        if (includeFromDate) {
            setFromDate(event.value);
        }
    };

    const fetchChartOfAccounts = async (type, setState) => {
        try {
            const response = await getChartOfAccountBalance(type);
            setState(response.data.data || []);
        } catch (error) {
            console.error(`Error fetching ${type} accounts:`, error);
        }
    };

    const [endDate, setEndDate] = React.useState(date);
    const [parameters, setParameters] = useState({
        Type: type,
        ExcludeZero: excludeZero,
        ToDate: includeEndDate ? DateFormat(endDate) : undefined,
        OrderBy: orderbyType?.value,
        ChartOfAccountIdBS: selectedBalanceSheetAccount?.chartOfAccountId,
        ChartOfAccountIdPL: selectedBalanceSheetAccount?.ChartOfAccountIdPL,
    });
    const onChangeEndSelect = (event) => {
        if (includeEndDate) {
            setEndDate(event.value);
        }
    };

    useEffect(() => {
        fetchChartOfAccounts("BalanceSheet", setBalanceSheetAccounts);
        fetchChartOfAccounts("ProfitAndLoss", setProfitAndLossAccounts);
    }, []);

    const UpdateButton = (
        <Button className="common-btn" onClick={analyzeParameters}>
            Update
        </Button>
    );

    const toDatePicker = (
        <div className="d-flex align-items-center justify-content-center">
            <Checkbox
                value={includeEndDate}
                className="ms-1 me-1 mt-auto mb-auto"
                onChange={(e) => {
                    setIncludeEndDate(e.value);
                }}
                label={""}
            />
            <DatePicker
                id="to"
                className="date-picker"
                value={endDate || null}
                onChange={onChangeEndSelect}
                width={"200px"}
                dateInput={CustomDateInput}
                toggleButton={CalenderCustomToggleButton}
                defaultValue={endDate || null}
                placeholder="Select to date"
            />
        </div>
    );

    const radioGroup = (
        <RadioGroup
            className=""
            data={typesData}
            value={type}
            onChange={(e) => {
                setSelectedBalanceSheetAccount();
                setSelectedProfitAndLossAccount();
                setType(e.value);
            }}
        />
    );

    const IncludeCheckbox = (
        <Checkbox
            // defaultChecked={false}
            className="checkbox-label"
            value={excludeZero}
            onChange={(e) => {
                setExcludeZero(e.value);
            }}
            style={{
                fontSize: "12px",
            }}
            label={"Exclude Zero Balances"}
        />
    );

    const orderByDropDown = (
        <div className="">
            <ComboBox
                className="ps-1"
                iconClassName="wa"
                icon={<Icon icon="arrow-down" />}
                data={orderData}
                required
                value={orderbyType}
                onChange={(e) => {
                    setOrderByType(e.value);
                }}
                dataItemKey={"value"}
                textField={"name"}
                placeholder="Order by"
                style={{ maxWidth: "325px", height: "44px" }}
            />
        </div>
    );

    const reportViewer = (
        <ReportViewer
            name={
                selectedBalanceSheetAccount || selectedProfitAndLossAccount
                    ? "ChartOfAccounts.trdp"
                    : "NominalBalances.trdp"
            }
            parameters={parameters}
            shouldLoad={shouldLoad}
            setShouldLoad={setShouldLoad}
            url={`${baseURL}/Accounts/Reporting/reports`}
        />
    );


    const balanceSheetComboBox = (
        <ComboBox
            data={balanceSheetAccounts}
            dataItemKey="chartOfAccountId"
            style={{ maxWidth: "325px", height: "44px" }}
            className="ps-1"
            iconClassName="wa"
            icon={<Icon icon="arrow-down" />}
            textField="name"
            value={selectedBalanceSheetAccount}
            onChange={(e) => setSelectedBalanceSheetAccount(e.value)}
            placeholder="Select Balance Sheet Account"
        />
    );

    const profitAndLossComboBox = (
        <ComboBox
            data={profitAndLossAccounts}
            dataItemKey="chartOfAccountId"
            textField="name"
            value={selectedProfitAndLossAccount}
            onChange={(e) => setSelectedProfitAndLossAccount(e.value)}
            placeholder="Select Profit & Loss Account"
            style={{ maxWidth: "325px", height: "44px" }}
            className="ps-1"
            iconClassName="wa"
            icon={<Icon icon="arrow-down" />}
        />
    );

    return (

        <div className="container-fluid w-100 p-4 pt-2 mt-4 ">
            <div className="row h-100">


                <div className="col" style={{ maxWidth: "300px" }}>
                    <div
                        className={`p-4 pt-4 ${styles[`reportBackground`]} ${styles[`height`]
                            }`}
                    >
                        <p className={`${styles[`heading`]} fs-lg`}>Filters</p>
                        <div className="radio d-flex flex-column radio-vertical pt-3">
                            {radioGroup}
                        </div>
                        <SectionDividerFull sectionName={""} />
                        <p className={`${styles[`heading`]} `}>Chart of Accounts</p>
                        {(type === "" || type === "BalanceSheet") && (
                            <div className="d-flex flex-column mt-3">
                                <Label className="label mb-1">Balance Sheet</Label>
                                {balanceSheetComboBox}
                            </div>
                        )}
                        {(type === "" || type === "ProfitAndLoss") && (
                            <div className="d-flex flex-column mt-3">
                                <Label className="label mb-1">Profit & Loss</Label>
                                {profitAndLossComboBox}
                            </div>
                        )}
                        <SectionDividerFull sectionName={""} />
                        <div className="d-flex flex-column mt-2">
                            <Label className="label mb-1">To</Label>
                            <div className="no-borders">{toDatePicker}</div>
                        </div>
                        <div className="d-flex flex-column pt-3">
                            <Label className="label mb-1">Order By</Label>
                            {orderByDropDown}
                        </div>
                        <SectionDividerFull sectionName={""} />
                        <div className="pt-3">{IncludeCheckbox}</div>
                        <SectionDividerFull sectionName={""} />
                        <div className="pt-3 float-end">{UpdateButton}</div>
                    </div>
                </div>


                <div className="col">
                    <div className={`row ${styles[`reportBackground`]} 1fr`}>
                        {reportViewer}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default NominalBalances;
