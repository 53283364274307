import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import EditIcon from "../../assets/edit.png";
import deleteIcon from "../../assets/common/delete.svg";
import styles from "./ChatAccounts.module.scss";

const GroupItem = ({
  item,
  selectedGroup,
  clickedItem,
  handleEditGroupName,
  handleDeleteGroup,
}) => {
  const text = item?.text;
  const newClickedItem = clickedItem?.map((ci) => ci.item);
  return (
    <li
      className={`px-3 py-1 d-flex justify-content-between rounded-1 cursor-pointer 
                ${item.type === "group" ? "fw-bold" : "fw-normal"}`}
      style={{
        border: selectedGroup === text ? "1px solid #e0e0e0" : "none",
        boxShadow:
          item.type === "group"
            ? item.path.length === 1
              ? "1px 1px 10px 1px #F1F0FF"
              : "1px 1px 5px 1px #F9F9FF"
            : "none",
        color:
          selectedGroup === text ||
          (newClickedItem && newClickedItem?.some((ci) => ci?.text === text))
            ? "#0B1DA9"
            : "#424242",
        backgroundColor:
          selectedGroup === text ||
          (newClickedItem && newClickedItem.some((ci) => ci?.text === text))
            ? "#dee5f7"
            : "transparent",
      }}
      data-type={item.type}
    >
      <div className="d-flex align-items-center justify-content-start">
        {item.type === "group" &&
          (item.path.length === 1 ? (
            <div className={styles.groupAvatar}>G</div>
          ) : (
            <div className={styles.subgroupAvatar}>SG</div>
          ))}

        {text}
      </div>

      {item.type === "group" && (
        <div className="d-flex align-items-center justify-content-end">
          <Button
            className="mx-2 p-0 border-0 bg-transparent"
            onClick={(e) => {
              e.stopPropagation();
              handleEditGroupName(item.path);
            }}
          >
            <img src={EditIcon} alt="edit" />
          </Button>

          <Button
            className="p-0 border-0 bg-transparent"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteGroup(item.path);
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </Button>
        </div>
      )}
    </li>
  );
};

export default GroupItem;
