import React, { useState } from "react";
import ReportViewer from "./ReportViewerComponent";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Icon from "components/Global/icon";
import MattersCombobox from "pages/Postings/MattersComboBox";
import styles from "./Reports.module.scss";
import { SectionDividerFull } from "components/Contact/sectionDivider";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { CustomDateInput } from "components/common/CustomDateInput";
import { baseURL } from "api/axios.config";

const Disbursements = () => {
  const DateFormat = (value) => {
    let date = new Date(value);
    return date.toISOString().split("T")[0];
  };

  const [groupByType, setGroupByType] = useState({
    name: "None",
    value: "NULL",
  });

  const [newMaterId, setNewMatterId] = useState();
  const typesData = [
    { label: "All", value: "All" },
    { label: "Paid", value: "Paid" },
    { label: "Billed", value: "Billed" },
    { label: "DUU", value: "DUU" },
    { label: "DPU", value: "DPU" },
    { label: "DUB", value: "DUB" },
    { label: "DPB", value: "DPB" },
  ];

  const groupData = [
    {
      name: "None",
      value: "NULL",
    },
    {
      name: "Date",
      value: "Date",
    },
    {
      name: "Fee Earner",
      value: "FeeEarnerRef",
    },
    {
      name: "Matter Ref",
      value: "MatterRef",
    },
  ];

  const orderData = [
    {
      name: "Matter Ref",
      value: "MatterRef",
    },
    {
      name: "Details",
      value: "details",
    },
    {
      name: "Start Date",
      value: "startDate",
    },
  ];

  let date = new Date();

  const analyzeParameters = () => {
    console.log(type)
    setParameters({
      Type: type?.value,
      ExcludeZero: excludeZero,
      ToDate: includeEndDate ? DateFormat(endDate) : undefined,
      OrderBy: orderbyType?.value,
      MatterId: newMaterId?.matterId,
      GroupBy: groupByType?.value,
    });
    setShouldLoad(true);
  };

  const [type, setType] = useState();
  const [orderbyType, setOrderByType] = useState({
    value: "MatterRef",
    name: "Matter Ref",
  });

  const [includeFromDate, setIncludeFromDate] = useState(false);
  const [includeEndDate, setIncludeEndDate] = useState(false);
  const [excludeZero, setExcludeZero] = useState(true);
  const [show, setShow] = useState(window.innerWidth);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [endDate, setEndDate] = React.useState(date);

  const [parameters, setParameters] = useState({
    Type: type,
    ExcludeZero: excludeZero,
    ToDate: includeEndDate ? DateFormat(endDate) : undefined,
    OrderBy: orderbyType?.value,
  });

  const onChangeEndSelect = (event) => {
    if (includeEndDate) {
      setEndDate(event.value);
    }
  };

  const UpdateButton = (
    <Button className="common-btn" onClick={analyzeParameters}>
      Update
    </Button>
  );

  const toDatePicker = (
    <div className="d-flex align-items-center justify-content-center">
      <Checkbox
        value={includeEndDate}
        className="ms-2 me-1 mt-auto mb-auto"
        onChange={(e) => {
          setIncludeEndDate(e.value);
        }}
        label={""}
      />
      <DatePicker
        id="to"
        className="date-picker"
        value={endDate || null}
        dateInput={CustomDateInput}
        onChange={onChangeEndSelect}
        toggleButton={CalenderCustomToggleButton}
        defaultValue={endDate || null}
        placeholder="Select to date"
      />
    </div>
  );

  const IncludeCheckbox = (
    <Checkbox
      // defaultChecked={false}
      value={excludeZero}
      className="checkbox-label"
      onChange={(e) => {
        setExcludeZero(e.value);
      }}
      label={"Exclude Zero Balances"}
    />
  );

  const orderByDropDown = (
    <div className="">
      <ComboBox
        className=""
        iconClassName="wa"
        icon={<Icon icon="arrow-down" />}
        data={orderData}
        required
        value={orderbyType}
        onChange={(e) => {
          setOrderByType(e.value);
        }}
        dataItemKey={"value"}
        textField={"name"}
        placeholder="Order by"
        style={{ maxWidth: "325px", height: "44px" }}
      />
    </div>
  );

  const typesComboBox = (
    <div className="">
      <ComboBox
        className=""
        iconClassName="wa"
        icon={<Icon icon="arrow-down" />}
        data={typesData}
        required
        value={type}
        onChange={(e) => {
          setType(e.value);
        }}
        dataItemKey={"value"}
        textField={"label"}
        placeholder="Filter by"
        style={{ maxWidth: "325px" }}
      />
    </div>
  );

  const reportViewer = (
    <ReportViewer
      name="Disbursements.trdp"
      parameters={parameters}
      shouldLoad={shouldLoad}
      setShouldLoad={setShouldLoad}
      url={`${baseURL}/Accounts/Reporting/reports`}
    />
  );

  const groupByDropDown = (
    <div className="">
      <ComboBox
        className="ps-1"
        iconClassName="wa"
        icon={<Icon icon="arrow-down" />}
        data={groupData}
        required
        value={groupByType}
        onChange={(e) => {
          setGroupByType(e.value);
        }}
        dataItemKey={"value"}
        textField={"name"}
        placeholder="Group by"
        style={{ maxWidth: "325px", height: "44px" }}
      />
    </div>
  );

  return (
    <>
      {show < 1400 ? (
        <div
          className={`container-fluid w-100 ps-4 pt-2 ${
            styles[`report-small-screen`]
          }`}
        >
          <div className="mt-4 d-flex flex-wrap">
            <div className="">
              <div className="nominal-form-to-parent-row">
                <div className="labels-containers">
                  <div className="label-to">
                    <Label editorId="to">To</Label>
                  </div>
                </div>
              </div>
              <div className="nominal-form-to-row">
                <div className="date-form-to-wrapper">
                  <div className="to">{toDatePicker}</div>
                </div>
              </div>
            </div>
            <div className="ms-5 d-flex flex-column me-lg-3">
              <Label className="label">Order By</Label>
              {orderByDropDown}
            </div>
            <div className="mt-4 pt-2 me-5">{IncludeCheckbox}</div>
            <div className="mt-4 pt-2">{UpdateButton}</div>
          </div>
          <div className={`row mt-4 ${styles[`reportBackground`]} 1fr`}>
            {reportViewer}
          </div>
        </div>
      ) : (
        <div
          className={`container-fluid w-100 p-4 pt-2 mt-4 ${
            styles[`report-large-screen`]
          }`}
        >
          <div className="row h-100">
            <div className="col" style={{ maxWidth: "300px" }}>
              <div
                className={`p-4 pt-4 ${styles[`reportBackground`]} ${
                  styles[`height`]
                }`}
              >
                <p className={`${styles[`heading`]} fs-lg`}>Filters</p>
                <div className="d-flex flex-column mt-4">
                  <Label className="label mb-1">To</Label>
                  <div className="no-borders">{toDatePicker}</div>
                </div>
                <SectionDividerFull sectionName={""} />
                <div className="d-flex flex-column">
                  <Label className={`label mb-1`}>Matter</Label>
                  <MattersCombobox
                    onChangematter={(e) => {
                      setNewMatterId(e.value);
                    }}
                    value={newMaterId}
                    style={{
                      width: "230px",
                    }}
                  />
                </div>
                <div className="d-flex flex-column mt-2">
                  <Label className={`label mb-1`}>Types</Label>
                  {typesComboBox}
                </div>
                <SectionDividerFull sectionName={""} />
                <div className="d-flex flex-column pt-1">
                  <Label className="label mb-1">Order By</Label>
                  {orderByDropDown}
                </div>
                <div className="d-flex flex-column pt-2">
                  <Label className="label mb-1">Group By</Label>
                  {groupByDropDown}
                </div>
                <div className="pt-4 float-end">{UpdateButton}</div>
              </div>
            </div>
            <div className="col">
              <div className={`row ${styles[`reportBackground`]} 1fr`}>
                {reportViewer}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Disbursements;
