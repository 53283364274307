import React, { useState, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  DateFormatCell,
  AmountFormatCellEmptyZero,
  AmountFormat,
} from "components/common/formatter";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Popup } from "@progress/kendo-react-popup";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Icon } from "@progress/kendo-react-common";
import styles from "./LedgerGrid.module.scss";
import { useEffect } from "react";
import { IconContainer } from "pages/CaseFile";
import { TbReportAnalytics } from "react-icons/tb";
import { getPreviousTransactions } from "api/accountsAPI";
import previousIcon from "assets/previous-icon.png";

const cellWithBackGround = (props, type, method) => {
  const style = { background: "", opacity: 1 };

  if (type === "CLIENT") {
    style.background =
      "linear-gradient(180deg, #ECECEC 0%, rgba(190, 190, 190, 0.12) 100%)";
  } else if (type === "DDA") {
    style.background = "linear-gradient(180deg, #D7D7D7 0%, #EAEAEA 100%)";
  } else if (type === "OFFICE") {
    style.background =
      "linear-gradient(180deg, #FAFAFA 0%, rgba(225, 225, 225, 0.12) 100%)";
  }

  const amount = props.dataItem[props.field];

  return (
    <td style={style} className="text-end bg-none">
      <span style={{ opacity: "0.95", background: "none" }}>
        {(() => {
          if (!isNaN(amount)) {
            if (method === "Credit" && amount < 0) {
              return AmountFormat(Math.abs(amount), { checkMillion: true });
            }
            if (method === "Debit" && amount > 0) {
              return AmountFormat(Math.abs(amount), { checkMillion: true });
            }
            if (method === "Amount") {
              return AmountFormat(amount);
            }
          }
          return "";
        })()}
      </span>
    </td>
  );
};

const CenterTextHeaderCell = (props) => (
  <span
    className="d-flex pb-2 justify-content-center"
    style={{ color: "#000000", fontSize: "13px", fontWeight: "700" }}
  >
    {props.title}
  </span>
);

const RefCell = (props, handleActionRef) => {
  return (
    <td
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        handleActionRef(event, props.dataItem.transactionNo);
      }}
    >
      <u>{props.dataItem.reference}</u>
    </td>
  );
};

const LedgerGridContent = ({ data, showDDA, handleActionRef, maxHeight }) => (
  <Grid
    scroll
    style={{ maxHeight: maxHeight, height: maxHeight }}
    data={data}
    className="pb-1 mb-1 grid-fs13"
  >
    <GridColumn>
      <GridColumn
        field="date"
        title="Date"
        width="100px"
        cell={DateFormatCell}
      />
      <GridColumn field="postingType" title="Type" width="55px" />
      <GridColumn
        field="reference"
        title="Ref"
        width="110px"
        cell={(props) => RefCell(props, handleActionRef)}
        className="text-wrap"
      />
      <GridColumn
        field="details"
        title="Details"
        width="320px"
        className="text-prewrap"
      />
      <GridColumn
        field="vatAmount"
        title="VAT"
        width="90px"
        headerCell={CenterTextHeaderCell}
        cell={AmountFormatCellEmptyZero}
      />
    </GridColumn>

    <GridColumn title="OFFICE" headerCell={CenterTextHeaderCell}>
      <GridColumn
        field="grossAmount"
        title="Debit"
        headerCell={CenterTextHeaderCell}
        cell={(e) => cellWithBackGround(e, "OFFICE", "Debit")}
        width="95px"
      />
      <GridColumn
        field="grossAmount"
        title="Credit"
        headerCell={CenterTextHeaderCell}
        cell={(e) => cellWithBackGround(e, "OFFICE", "Credit")}
        width="95px"
      />
      <GridColumn
        field="balance"
        title="Balance"
        cell={(e) => cellWithBackGround(e, "OFFICE", "Amount")}
        width="95px"
      />
    </GridColumn>

    <GridColumn title="CLIENT" headerCell={CenterTextHeaderCell}>
      <GridColumn
        field="clientAmount"
        title="Debit"
        headerCell={CenterTextHeaderCell}
        cell={(e) => cellWithBackGround(e, "CLIENT", "Debit")}
        width="95px"
      />
      <GridColumn
        field="clientAmount"
        title="Credit"
        headerCell={CenterTextHeaderCell}
        cell={(e) => cellWithBackGround(e, "CLIENT", "Credit")}
        width="95px"
      />
      <GridColumn
        field="clientBalance"
        title="Balance"
        headerCell={CenterTextHeaderCell}
        cell={(e) => cellWithBackGround(e, "CLIENT", "Amount")}
        width="110px"
      />
    </GridColumn>

    {showDDA && (
      <GridColumn title="DDA" headerCell={CenterTextHeaderCell}>
        <GridColumn
          field="ddaAmount"
          title="Debit"
          headerCell={CenterTextHeaderCell}
          cell={(e) => cellWithBackGround(e, "DDA", "Debit")}
          width="95px"
        />
        <GridColumn
          field="ddaAmount"
          title="Credit"
          headerCell={CenterTextHeaderCell}
          cell={(e) => cellWithBackGround(e, "DDA", "Credit")}
          width="95px"
        />
        <GridColumn
          field="ddaBalance"
          title="Balance"
          headerCell={CenterTextHeaderCell}
          cell={(e) => cellWithBackGround(e, "DDA", "Amount")}
          width="95px"
        />
      </GridColumn>
    )}
  </Grid>
);

export default function MatterGrid(props) {
  const anchor = useRef(null);
  const [show, setShow] = useState(false);
  const [showPrevTrans, setShowPrevTrans] = useState(false);
  const [prevTransactions, setPrevTransactions] = useState([]);
  const maxHeight =
    props.comp === "ledgerCard" ? "calc(100vh - 260px)" : "calc(100vh - 300px)";
  const [gridData, setGridData] = useState([]);
  const [fixedScroll, setFixedScroll] = useState(props.fixedScroll);

  const onClick = () => {
    setShow(!show);
  };

  const scrollHandler = (event) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      props.getMoreData();
    }
  };

  useEffect(() => {
    setGridData(props.gridData);
  }, [props.gridData.length]);

  useEffect(() => {
    setFixedScroll(props.fixedScroll);
  }, [props.fixedScroll]);

  const fetchPreviousTransactions = async () => {
    try {
      const response = await getPreviousTransactions(props.matterId);
      setPrevTransactions(response.data);
    } catch (error) {
      console.error("Error fetching previous transactions:", error);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <LedgerGridContent
          data={gridData}
          showDDA={props.showDDA}
          handleActionRef={props.handleActionRef}
          maxHeight={maxHeight}
        />

        <div className={`${styles["setting-btn-left"]}`}>
          <div className="d-flex gap-3 align-items-center">
            <button
              className={`${styles["dark-background"]} border-0`}
              onClick={() => {
                if (props.matterId) props.setOpenReport(true);
              }}
              disabled={!props?.matterId}
              ref={anchor}
            >
              <Icon name="print" />
            </button>
            <button
              className="btn p-0 d-flex align-items-center justify-content-center border-0"
              onClick={() => {
                if (props.matterId) {
                  fetchPreviousTransactions();
                  setShowPrevTrans(true);
                }
              }}
              disabled={!props?.matterId}
            >
              <div className={`${styles["dark-background"]}  `}>
                <img src={previousIcon} width={15} height={15} />
              </div>
            </button>

            {props?.isClosed && (
              <div>
                <button
                  className="btn py-0 px-2 d-flex align-items-center gap-2"
                  style={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "4px",
                  }}
                >
                  <>
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#FF0000",
                        display: "inline-block",
                      }}
                    />
                    <span className="fs-md fw-semibold">Closed</span>
                  </>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className={`${styles["setting-btn-container"]}`}>
          <button
            className={`${styles["dark-background"]} border-0`}
            onClick={() => setShow(!show)}
            ref={anchor}
          >
            <Icon name="gear" />
          </button>
          <Popup
            popupAlign={{ horizontal: "right", vertical: "top" }}
            anchorAlign={{ horizontal: "right", vertical: "bottom" }}
            anchor={anchor.current}
            show={show}
            popupClass={"popup-content"}
          >
            <div className="p-3">
              <Checkbox
                value={props.showDDA}
                onClick={() => props.setShowDDA((prev) => !prev)}
                label={"Show DDA Account"}
              />
            </div>
          </Popup>
        </div>

        {showPrevTrans && (
          <Dialog
            title="Previous Transactions"
            onClose={() => setShowPrevTrans(false)}
            className="dialog-xxl"
          >
            <div className="m-4">
              <LedgerGridContent
                data={prevTransactions}
                showDDA={props.showDDA}
                handleActionRef={props.handleActionRef}
                maxHeight={maxHeight}
              />
            </div>
            <DialogActionsBar>
              <div className="dialog-footer d-flex justify-content-end">
                <button
                  className="common-btn me-5"
                  onClick={() => setShowPrevTrans(false)}
                >
                  Close
                </button>
              </div>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </>
  );
}
