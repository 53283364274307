// import "./Settings.css";
import { BiCog } from "react-icons/bi";
import React from "react";
import { useState } from "react";
import Profile from "./Profile";
import Company from "./Company/Company";
import Branches from "./Branches/branches";
import { useMatch } from "react-router-dom";
import ProfitCostTemplate from "./FixedHeaderSettings/FixedHeader";
import ContactLinkDataGrid from "pages/Settings/ContactLinksType";
import Icon from "components/Global/icon";
import styles from "./Settings.module.scss";
import WorkType from "./WorkTypes/WorkTypes";
import Department from "./Departments/Departments";
import ActivityTypes from "./ActivityTypes/Activities";
import TimeTypes from "./TimeTypes/TimeTypes";
import UsersList from "./users/Userslist";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Teams from "./Teams/Teams";
import SecurityProfiles from "./SecurityProfiles/SerurityProfiles";
import Integrations from "./Integrations/Integrations";
import Vatcodes from "./VAT/vat";
import Applcations from "./Applications/Applications";
import { useDispatch } from "react-redux";
import Currency from "./Currency/currency";
import DefaultNominals from "./DefaultNominals";
import Sources from "./Sources/Sources";
import Referrers from "./Referrers/Referrer";
import {
  AccountsSectionItems,
  BillingSectionItems,
  CaseSectionItems,
  CompanySectionItems,
  MatterSectionItems,
  UsersSectionItems,
  getBreadCrumb,
} from "components/SettingMenu/SettingMenuData";
import MatterSetup from "./Company/MatterSetup";
import SettingMenuItemId from "components/SettingMenu/SettingMenuItemId.ts";
import DocTypes from "./DocumentTypes/DocTypes";
import RiskAssessment from "./RiskAssessment";
import UserRates from "./UserRates/UserRates";
import BillingTemplates from "./BillingTemplates";
import ChartofAccounts from "../ChartAccounts/ChartofAccounts";
import Authorizations from "./Authorizations";

const Options = (props) => {
  const [openoption, setopenoption] = useState(-1);
  const [shownewusers, setshownewusers] = useState(false);
  const [shownewbranches, setshownewbranches] = useState(false);
  const [shownewVat, setshownewVat] = useState(false);
  const [shownewCurrency, setshownewCurrency] = useState(false);
  const [showNewTemplate, setshowNewTemplate] = useState(false);
  const [showNewApplications, setshowNewApplications] = useState(false);
  const params = useMatch("/settings/:data");
  const urlDirector = () => {
    let check = false;
    [
      ...CompanySectionItems,
      ...AccountsSectionItems,
      ...BillingSectionItems,
      ...CaseSectionItems,
      ...MatterSectionItems,
      ...UsersSectionItems,
    ].map((item) => {
      if (
        item?.route?.toLowerCase() === `/${params?.params?.data?.toLowerCase()}`
      ) {
        setopenoption(item.id);
        check = true;
        return;
      }
    });
    if (!check) setopenoption(0);
  };
  useEffect(() => {
    if (params?.params.data !== null) {
      urlDirector();
    }
  }, [params]);

  const onClose = () => {
    setshownewusers(false);
    setshownewbranches(false);
    setshownewVat(false);
    setshownewCurrency(false);
    setshowNewTemplate(false);
    setshowNewApplications(false);
    navigate("/settings");
  };
  const navigate = useNavigate(``);

  const onClick = () => {
    if (openoption !== 0) {
      navigate("/settings");
    } else {
      navigate(`/`);
    }
  };
  const componentToRender = () => {
    switch (openoption) {
      case SettingMenuItemId.Profile:
        return <Profile component={"Option"} />;
      case SettingMenuItemId.Company:
        return (
          <Company
            onCancel={() => {
              onClose();
            }}
          />
        );
      case SettingMenuItemId.Users:
        return (
          <UsersList
            open={() => {
              setshownewusers(true);
            }}
            onClose={shownewusers}
            onCancel={onClose}
          />
        );
      case SettingMenuItemId.Branches:
        return (
          <Branches
            open={() => {
              setshownewbranches(true);
            }}
            onClose={shownewbranches}
            // onCancel={onClose}
          />
        );
      case SettingMenuItemId.Defaultnominals:
        return <DefaultNominals />;
      case SettingMenuItemId.Vat:
        return (
          <Vatcodes
            open={() => {
              setshownewVat(true);
            }}
            onClose={shownewVat}
          />
        );
      case SettingMenuItemId.Currencies:
        return (
          <Currency
            open={() => {
              setshownewCurrency(true);
            }}
            onClose={shownewCurrency}
            onCancel={onClose}
          />
        );
      case SettingMenuItemId.ChartOfAccounts:
        return <ChartofAccounts />;

      case SettingMenuItemId.FixedFeeBilling:
        return (
          <ProfitCostTemplate
            open={() => {
              setshowNewTemplate(true);
            }}
            onClose={showNewTemplate}
            onCancel={onClose}
          />
        );
      case SettingMenuItemId.Applications:
        return (
          <Applcations
            open={() => {
              setshowNewApplications(true);
            }}
            onClose={showNewApplications}
            onCancel={onClose}
          />
        );
      case SettingMenuItemId.Contactcategories:
        return <ContactLinkDataGrid />;
      case SettingMenuItemId.Worktypes:
        return (
          <WorkType
            open={() => {
              setshownewCurrency(true);
            }}
            onClose={shownewCurrency}
            onCancel={onClose}
          />
        );
      case SettingMenuItemId.Departments:
        return (
          <Department
            open={() => {
              setshownewCurrency(true);
            }}
            onClose={shownewCurrency}
            onCancel={onClose}
          />
        );
      case SettingMenuItemId.Activitytypes:
        return (
          <ActivityTypes
            open={() => {
              setshownewCurrency(true);
            }}
            onClose={shownewCurrency}
            onCancel={onClose}
          />
        );
      case SettingMenuItemId.Timetypes:
        return (
          <TimeTypes
            open={() => {
              setshownewCurrency(true);
            }}
            onClose={shownewCurrency}
            onCancel={onClose}
          />
        );
      case SettingMenuItemId.Teams:
        return <Teams />;
      case SettingMenuItemId.SecurityProfiles:
        return <SecurityProfiles />;
      case SettingMenuItemId.Integrations:
        return <Integrations />;
      case SettingMenuItemId.Sources:
        return <Sources />;
      case SettingMenuItemId.Referrers:
        return <Referrers />;
      case SettingMenuItemId.MatterSettings:
        return <MatterSetup updateAddress={() => {}} />;
      case SettingMenuItemId.DocumentRegisterTypes:
        return <DocTypes />;
      case SettingMenuItemId.RiskAssessmentSettings:
        return <RiskAssessment />;
      case SettingMenuItemId.UserRates:
        return <UserRates />;
      case SettingMenuItemId.BillingTemplates:
        return <BillingTemplates type={"BillingTemplate"} />;
      case SettingMenuItemId.Authorizations:
        return <Authorizations />;
      default:
        return <> </>;
    }
  };

  return (
    <>
      <div
        className={`container-fluid p-3 pt-0 bg-white h-100 w-100 ${styles["borders"]}`}
      >
        <div className="row mt-1">
          <div className="col-8">
            <span
              className="fs-xl fw-semibold app-bar-title ps-4"
              onClick={() => {
                navigate("/settings");
              }}
            >
              Settings
            </span>
            <span className="fs-lg fw-semibold app-bar-title ps-2">
              {getBreadCrumb(openoption)}
            </span>
          </div>
          <div className="col float-end">
            <div
              className="d-flex align-items-center justify-content-end cursor-pointer"
              onClick={onClick}
            >
              <div
                className={`${
                  styles[`icon-back`]
                } d-flex align-items-center justify-content-center`}
              >
                <Icon icon={"arrow-left"} />
              </div>
              <span className="fs-lg">
                {openoption === 0 ? "Exit Settings" : "Back to Settings"}
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="w-100  border-bottom border-opacity-50 mt-2 mb-2"></div>
        </div>
        {openoption === 0 ? (
          <>
            <div
              className={`row mt-3 overflow-auto pb-3 px-4 ${styles["settings-height"]}`}
            >
              <div className="col-4">
                <div>
                  <div className="fs-lg">
                    <u>Company</u>
                  </div>
                  {CompanySectionItems.map((item, index) => (
                    <SettingsItem item={item} key={index} />
                  ))}
                </div>
                <div>
                  <div className="fs-lg mt-5">
                    <u>Users</u>
                  </div>
                  {UsersSectionItems.map((item, index) => (
                    <SettingsItem item={item} key={index} />
                  ))}
                </div>
              </div>
              <div className="col-4">
                <div>
                  <div className="fs-lg">
                    <u>Matter Management</u>
                  </div>
                  {MatterSectionItems.map((item, index) => (
                    <SettingsItem item={item} key={index} />
                  ))}
                </div>
                <div>
                  <div className="fs-lg mt-5">
                    <u>Case Management</u>
                  </div>
                  {CaseSectionItems.map((item, index) => (
                    <SettingsItem item={item} key={index} />
                  ))}
                </div>
              </div>
              <div className="col-4">
                <div>
                  <div className="fs-lg">
                    <u>Accounts</u>
                  </div>
                  {AccountsSectionItems.map((item, index) => (
                    <SettingsItem item={item} key={index} />
                  ))}
                </div>
                <div className="mt-5">
                  <div className="fs-lg">
                    <u>Billing</u>
                  </div>
                  {BillingSectionItems.map((item, index) => (
                    <SettingsItem item={item} key={index} />
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>{componentToRender()}</div>
        )}
      </div>
    </>
  );
};

export default Options;

const SettingsItem = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div display="d-flex flex-column">
      <div
        onClick={() => {
          navigate(`/settings${item.route}`);
        }}
        className={`d-flex ${styles[`menu-item`]} mt-3 align-items-center`}
      >
        <div className="">
          <img className={"icon-styles"} src={item.icon} alt="Item Icon"></img>
        </div>
        <span className={`${styles[`menu-style`]}`}>{item.name}</span>
      </div>
    </div>
  );
};
