import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";

const EditBillingTemplate = (props) => {
  const ADD_NEW = props.type === "addNew";
  return (
    <Dialog
      title={ADD_NEW ? "Add Billing Template" : `Edit ${props?.item?.templateName}`}
      onClose={props.cancelEdit}
      width={600}
    >
      <div className="p-3 ps-4">
        <Form
          onSubmit={props.onSubmit}
          initialValues={props.item}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 1200,
              }}
            >
              <fieldset className={"k-form-fieldset"}>
                <div className="mb-2">
                  <Label className="label mt-1 mb-2 w-100">Template Name</Label>
                  <Field
                    name={"templateName"}
                    component={Input}
                    placeHolder={"Enter Details"}
                  />
                </div>
                <Field
                  defaultChecked={true}
                  name={"active"}
                  component={Checkbox}
                  label={"Active"}
                />
                <button
                  style={{ visibility: "hidden" }}
                  id="app-form"
                  type={"submit"}
                ></button>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={props.cancelEdit}
        >
          Cancel
        </button>
        <Button
          className="common-btn me-3"
          disabled={props.loading}
          onClick={() => document.getElementById("app-form").click()}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </Button>
      </div>
    </Dialog>
  );
};

export default EditBillingTemplate;
