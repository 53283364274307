import * as React from "react";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { useState, useEffect } from "react";
import { Label } from "@progress/kendo-react-labels";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { nominalData } from "./services";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { AmountFormat } from "components/common/formatter";
import { MyCommandCell } from "./MyCommandCell.jsx";
import { DropDownCell } from "./MyDropdownCell.jsx";
import { getItems, deleteItem, updateData } from "./services";
import { Button } from "@progress/kendo-react-buttons";
import { CellRender, RowRender } from "./Renderers";
import { useDispatch } from "react-redux";
import Modalposting from "../Postings/PostingModal";
import {
  clearNominalFields,
  createJournalPosting,
} from "actions/postings.action";
import { usePreComponentCalls } from "components/layout/PreComponentLoad";
import { toast } from "react-toastify";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { CustomDateInput } from "components/common/CustomDateInput";
import { TransactionPeriod } from "components/common/TransactionPeriod";
import { v4 as uuidV4 } from "uuid";

const JournalPostings = () => {
  const [credits, setCredits] = useState(0);
  const [debits, setDebits] = useState(0);
  const [tableError, setTableError] = useState(false);
  const editField = "inEdit";
  const date = new Date();
  const [localData, setData] = useState(nominalData);
  const [reference, setReference] = useState("");
  const [modalvalue, setmodalvalue] = useState(false);
  const [dateselector, setdateselector] = useState(date);
  const [details, setDetails] = useState("");
  const { handleCalls } = usePreComponentCalls();
  const [resetKey, setResetKey] = useState(uuidV4());
  const [validationError, setValidationError] = useState(false);
  const [transactionPeriod, setTransactionPeriod] = useState(null);
  useEffect(() => {
    handleCalls("Journal Posting");
  }, []);
  const dispatch = useDispatch();
  const EDIT_FIELD = "inEdit";
  const CommandCell = (props) => (
    <MyCommandCell
      {...props}
      remove={remove}
      discard={discard}
      cancel={cancel}
      editField={editField}
    />
  );

  const remove = (dataItem) => {
    const newData = deleteItem(dataItem, localData);
    setTableError(newData.length < 2);
    setData([...newData]);
  };

  const discard = (dataItem) => {
    const newData = [...localData];
    newData.splice(0, 1);
    setTableError(newData.length < 2);
    setData(newData);
  };

  const cancel = (dataItem) => {
    const originalItem = getItems().find(
      (p) => p.ProductID === dataItem.ProductID
    );
    const newData = localData.map((item) =>
      item.ProductID === originalItem.ProductID ? originalItem : item
    );
    setTableError(newData.length < 2);
    setData(newData);
  };

  const enterEdit = (dataItem) => {
    let newData = localData.map((item) =>
      item.ProductID === dataItem.ProductID ? { ...item, inEdit: true } : item
    );
    setData(newData);
  };

  const itemChange = (event) => {
    if (
      event.field === "CreditAmount" &&
      event.dataItem.DebitAmount !== 0 &&
      event.dataItem.DebitAmount !== null
    ) {
      return;
    }
    if (
      event.field === "DebitAmount" &&
      event.dataItem.CreditAmount !== 0 &&
      event.dataItem.CreditAmount !== null
    ) {
      return;
    }
    const field = event.field || "";
    if (field === "CreditAmount" || field === "DebitAmount") {
      let value = parseInt(event.value);
      var newData = localData.map((item) =>
        item.ProductID === event.dataItem.ProductID
          ? { ...item, [field]: value !== NaN ? value : 0 }
          : item
      );
      setData(newData);
    } else {
      const newData = localData.map((item) =>
        item.ProductID === event.dataItem.ProductID
          ? { ...item, [field]: event.value }
          : item
      );
      setData(newData);
    }
  };
  const generateId = (data) =>
    data.reduce((acc, current) => Math.max(acc, current.ProductID), 0) + 1;

  const addNew = () => {
    const id = generateId(localData);
    const newDataItem = {
      ProductID: id,
      inEdit: false,
      NominalInfo: {
        nominalRef: null,
      },
      CreditAmount: 0,
      DebitAmount: 0,
    };
    setTableError(localData.length + 1 < 2);
    setData([...localData, newDataItem]);
  };
  const calculateCreditDebit = () => {
    let sumCredit = 0;
    let sumDebit = 0;
    localData.map((item) => {
      sumCredit = sumCredit + item.CreditAmount;
      sumDebit = sumDebit + item.DebitAmount;
    });
    setCredits(sumCredit);
    setDebits(sumDebit);
  };

  useEffect(() => {
    calculateCreditDebit();
  }, [localData]);

  const exitEdit = () => {
    const newData = localData.map((item) => ({
      ...item,
      [EDIT_FIELD]: undefined,
    }));
    setData(newData);
    updateData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let cAmount = null;
    let dAmount = null;
    const data = [
      {
        postingType: "JNL",
        date: dateselector,
        reference: reference,
        details: details,
        transactionPeriod: transactionPeriod?.period,
        journalEntries: [],
      },
    ];

    if (localData.length < 2) {
      setTableError(true);
      return;
    }
    if (reference === "" || details === "") {
      setValidationError(true);
      return;
    }

    localData.map((item) => {
      if (
        !item.NominalInfo.nominalRef ||
        item.CreditAmount + item.DebitAmount === 0 ||
        item.CreditAmount + item.DebitAmount === NaN
      ) {
        return;
      } else {
        cAmount = cAmount + item.CreditAmount;
        dAmount = dAmount + item.DebitAmount;
        data[0].journalEntries.push({
          nominalRef: item.NominalInfo.nominalRef,
          amount:
            item.CreditAmount === 0 || item.CreditAmount === null
              ? -item.DebitAmount
              : item.CreditAmount,
        });
      }
    });

    if (cAmount !== dAmount) {
      return toast.error("Credit and Debit Amounts Do Not Match", {
        style: {
          marginRight: "10px",
        },
      });
    }
    if (data[0]?.journalEntries?.length > 0) {
      dispatch(createJournalPosting(data));
      dispatch(clearNominalFields());
      setmodalvalue(true);
    }
  };

  const onChangedateselect = (event) => {
    setdateselector(event.value);
  };
  const customCellRender = (td, props) => (
    <CellRender
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      editField={EDIT_FIELD}
    />
  );

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={exitEdit}
      editField={EDIT_FIELD}
    />
  );
  const SpecialCell1 = (props) => {
    const amount = props.dataItem.NominalInfo.nominalName;
    return <td style={{ textAlign: "left" }}>{amount}</td>;
  };
  const SpecialCell2 = (props) => {
    const amount = props.dataItem.NominalInfo.type;
    return <td style={{ textAlign: "left" }}>{amount}</td>;
  };

  return (
    <div
      className="container-fluid ps-0 pt-0 bg"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="row h-100 g-0">
        <div
          className="bgForm ms-2 pt-4 d-flex justify-content-center align-item-center"
          style={{
            width: "350px",
            background: "#F8F8F8",
            overflow: "auto",
            maxHeight: "calc(100vh - 129px)",
          }}
        >
          <div
            style={{
              width: "85%",
            }}
          >
            <div className="mb-3">
              <Label className="mb-1">Date</Label>
              <div>
                <DatePicker
                  className="datepicker"
                  value={dateselector}
                  dateInput={CustomDateInput}
                  toggleButton={CalenderCustomToggleButton}
                  onChange={onChangedateselect}
                  defaultValue={dateselector}
                />
              </div>
            </div>
            <div className="mb-3">
              <Label className="mb-1">Reference</Label>
              <Input
                width={300}
                value={reference}
                required
                onChange={(e) => {
                  setReference(e.target.value);
                }}
                placeholder={"Enter Reference"}
                maxLength={40}
                style={{
                  border:
                    validationError && reference === "" ? "1px solid red" : "",
                }}
              ></Input>
            </div>
            <div className="mb-3">
              <Label className="mb-1">Details</Label>
              <TextArea
                cols={3}
                required
                placeholder="Enter Details"
                value={details}
                style={{
                  maxWidth: "675px",
                  height: "65px",
                  border:
                    validationError && details === "" ? "1px solid red" : "",
                }}
                onChange={(e) => {
                  setDetails(e.target.value);
                }}
                maxLength={200}
              />
            </div>
          </div>
        </div>
        <div className="col" style={{ overflowX: "auto" }}>
          <TransactionPeriod onRefreshTransactionPeriod={setTransactionPeriod} />
          <div
            className="ms-4 me-3"
            style={{
              height: "calc(100vh - 410px)",
            }}
          >
            <Grid
              style={{ maxHeight: "300px", overflow: "auto" }}
              data={localData}
              onItemChange={itemChange}
              editField={editField}
              dataItemKey={"ProductID"}
              key={resetKey}
              className="text-align-right mt-4"
              cellRender={customCellRender}
              rowRender={customRowRender}
            >
              {tableError && (
                <GridToolbar>
                  <i style={{ color: "red" }}>
                    Please fill at least two records
                  </i>
                </GridToolbar>
              )}
              <Column
                field="NominalInfo"
                title="Nominal Ref"
                width="210px"
                cell={DropDownCell}
                editable={true}
                headerClassName="ps-3"
              />
              <Column
                field="NominalInfo.nominalName"
                cell={SpecialCell1}
                title="Details"
                 width="80px"
                editable={false}
              />
              <Column
                field="NominalInfo.type"
                title="Type"
                editable={false}
                cell={SpecialCell2}
                width="120px"
              />
              <Column
                field="CreditAmount"
                title="Credit Amount"
                editor="text"
                cell={NumericCell}
                width={200}
              />
              <Column
                field="DebitAmount"
                title="Debit Amount"
                editor="text"
                cell={NumericCell}
                width={200}
              />
              <Column cell={CommandCell} width="80px" />
            </Grid>
            <div>
              <Button
                title="Add new"
                className="common-btn bgBtnSecondary mt-2"
                onClick={addNew}
              >
                Add new line
              </Button>
            </div>
          </div>
          <hr />
          <div className="w-100 d-flex justify-content-end mt-3">
            <div className="pb-3 pt-2">
              <div className="d-flex align-items-center">
                <Label className="me-5">Credits</Label>
                <Input
                  value={AmountFormat(credits)}
                  style={{ textAlign: "right", width: "180px" }}
                ></Input>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-2">
                <Label className="me-5">Debits</Label>
                <Input
                  value={AmountFormat(debits)}
                  style={{ textAlign: "right", width: "180px" }}
                ></Input>
              </div>

              <div className="buttonstyle mt-2">
                <Button
                  className="common-btn bgBtnSecondary me-2"
                  type="button"
                  onClick={() => {
                    setDetails("");
                    setReference("");
                    setdateselector(new Date(Date.now()));
                    setData([...nominalData]);
                    setResetKey(uuidV4());
                    setValidationError(false);
                    setTableError(false);
                  }}
                >
                  Clear
                </Button>
                <Button
                  className="common-btn"
                  form="myForm"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Post
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modalposting
        value={modalvalue}
        closeModal={() => {
          setmodalvalue(false);
        }}
        reset={() => {
          setData([...nominalData]);
          setDetails("");
          setReference("");
          setdateselector(new Date(Date.now()));
          setResetKey(uuidV4());
          setValidationError(false);
        }}
        component={"Posting"}
      ></Modalposting>
    </div>
  );
};

export default JournalPostings;

const disablField = (props) => {
  if (
    props.field === "CreditAmount" &&
    props.dataItem.DebitAmount !== 0 &&
    props.dataItem.DebitAmount !== null
  ) {
    return true;
  }
  if (
    props.field === "DebitAmount" &&
    props.dataItem.CreditAmount !== 0 &&
    props.dataItem.CreditAmount !== null
  ) {
    return true;
  }
};

export const NumericCell = (props) => {
  return (
    <td className="right-align-full">
      {true ? (
        <NumericTextBox
          format={"n2"}
          value={props.dataIndex[props.field]}
          placeholder="0.00"
          className="m-2"
          style={{
            height: "35px",
          }}
          readOnly={disablField(props)}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value,
                // counter: props.dataItem.counter
              });
            }
          }}
          spinners={false}
        />
      ) : (
        ""
      )}
    </td>
  );
};
