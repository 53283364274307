import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import Bills from "./Bills";

const BillPostingModal = ({ billingRequestPayload, onClose }) => {
  return (
    <Dialog
      onClose={onClose}
      width={"90%"}
      height={"90vh"}
      title={"Add Billing Request"}
      className="batch-posting-dialog"
    >
      <Bills
        isDialog={true}
        onClose={onClose}
        billingRequestPayload={billingRequestPayload}
        component="BillRequest"
      />
    </Dialog>
  );
};

export default BillPostingModal;
