import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import { createposting, getfeeearner } from "actions/postings.action.js";
import "@progress/kendo-react-dropdowns";
import Modalposting from "../Postings/PostingModal.jsx";
import { getChitPostingtypes } from "actions/chitRequest.action.js";
import "react-router-dom";
import MattersCombobox from "../Postings/MattersComboBox.jsx";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Checkbox, RadioGroup } from "@progress/kendo-react-inputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { HandleGridBody } from "components/common/HandleGridBody.js";
import {
  AmountFormatCell,
  DateFormatCell,
} from "components/common/formatter.js";
import { Button } from "@progress/kendo-react-buttons";
import BatchPostings from "pages/BatchPosting/index.jsx";
import { Dialog } from "@progress/kendo-react-dialogs";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton.jsx";
import styles from "../ChitRequest/ChitRequest.module.scss";
import { handleApiError } from "Helper/index.js";
import * as api from "../../api/index.js";
import { AuthorizeChits } from "../../api/accountsAPI.js";
import { AiOutlineFile } from "react-icons/ai";
import DocumentViewer from "components/common/DocumentViewer";
import ChitImports from "pages/ChitRequest/ImportChits.jsx";
import { toast } from "react-toastify";
import { Tooltip } from "@progress/kendo-react-tooltip";

const PostingStatus = [
  {
    label: "Unposted",
    value: "false",
  },
  {
    label: "Posted",
    value: "true",
  },
  {
    label: "All",
    value: "",
  },
];

const postingdata = [
  {
    code: "DUU",
    name: "Anticipated Disbursement",
  },
  {
    code: "DPU",
    name: "Paid Disbursement",
  },
  {
    code: "DIN",
    name: "Disbursement Invoice",
  },
  {
    code: "PIN",
    name: "Purchase Invoice",
  },
  {
    code: "OCR",
    name: "Office Credit",
  },
  {
    code: "ODR",
    name: "Office Debit",
  },
  {
    code: "CCR",
    name: "Client Credit",
  },
  {
    code: "CDR",
    name: "Client Debit",
  },
  {
    code: "DCR",
    name: "DDA Credit",
  },
  {
    code: "DDR",
    name: "DDA Debit",
  },
  {
    code: "BP",
    name: "Bank Payments",
  },
  {
    code: "BR",
    name: "Bank Receipts",
  },
  {
    code: "BT",
    name: "Bank Transfer",
  },
  {
    code: "CTO",
    name: "Client To Office",
  },
  {
    code: "OTC",
    name: "Office To Client",
  },
];

const ChitPosting = (props) => {
  const date = new Date();
  const dispatch = useDispatch();
  const [modalvalue, setmodalvalue] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showBatchPosting, setShowBatchPosting] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date()?.toISOString().split("T")[0]);
  const [fileData, setFileData] = useState([]);
  const [showDoc, setShowDoc] = useState(false);
  const { userlist: user } = useSelector((state) => state.currentuser);

  const feeearnerdata = useSelector((state) => {
    return state.postingsdocs.feeearner.data;
  });
  const [matterref, setmatterref] = useState(null);
  const [list, setLists] = useState({
    data: [],
    total: 20,
  });
  const [filterParameters, setFilterParmeters] = useState({
    postingStatus: "false",
  });
  const [valueFrom, setValueFrom] = useState(false);
  const [valueTo, setValueTo] = useState(false);
  const [dataState, setDataState] = useState({
    take: 200,
    skip: 0,
    sort: [
      {
        field: "matterRef",
        dir: "asc",
      },
    ],
  });
  const [valuePosting, setValuePosting] = useState([]);
  const [fromDateSelector, setFromDateSelector] = useState(date);
  const [postingStatus, setPostingStatus] = useState("false");
  const [showImports, setShowImports] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [authorized, setAuthorized] = useState([]);

  const [postingTypes, setPostingTypes] = useState([
    { code: "0", name: "Loading" },
  ]);
  useEffect(() => {
    if (postingdata.length !== 1) {
      let list = [];
      postingdata.map((item) => {
        list.push(item.code);
      });
      setPostingTypes(list);
    }
  }, [postingdata]);
  const [toDateSelector, setToDateSelector] = useState(date);
  const { yearStartDate } = useSelector((state) => state.company.company);
  const onChangeFromDateSelect = (event) => {
    setFromDateSelector(event.value);
    let fromDate = null;
    let date = new Date(event.value);
    if (event.value) {
      fromDate = date?.toISOString().split("T")[0];
    }
    setFromDate(fromDate);
  };
  const onChangeToDateSelect = (event) => {
    setToDateSelector(event.value);
    let toDate = null;
    let date = new Date(event.value);
    if (event.value) {
      toDate = date?.toISOString().split("T")[0];
    }
    setToDate(toDate);
  };

  useEffect(() => {
    if (yearStartDate !== undefined) {
      let yearsToStartDate = new Date(yearStartDate);
      setFromDateSelector(yearsToStartDate);
      setFromDate(yearsToStartDate?.toISOString().split("T")[0]);
    }
  }, [yearStartDate]);

  const onChangePosting = (event) => {
    setValuePosting([...event.value]);
  };

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100, 200],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = useState(createState(0, 10));

  const changePostingStatus = (e) => {
    setPostingStatus(e.value);
  };
  const onChangematter = (event) => {
    if (event.target.value !== null && event.target.value !== undefined) {
      setmatterref(event?.target?.value?.matterId);
    } else {
      setmatterref(null);
    }
  };
  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };

  const PostHeaderCell = () => {
    return (
      <td>
        <Checkbox checked={selectAll} onChange={handleSelectAll} />
      </td>
    );
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    setLists((prevList) => {
      const updatedData = prevList.data.map((item) => {
        item.select = newSelectAll; // Select or unselect all rows
        return item;
      });

      setCheckedList(
        newSelectAll ? updatedData.map((item) => item.chitId) : []
      );

      return { ...prevList, data: updatedData };
    });
  };

  const PostCell = (props) => {
    return (
      <td>
        {props.dataItem.posted === false &&
        !props.dataItem.authorizationRequired ? (
          <Checkbox
            type="checkbox"
            checked={props.dataItem.select === true}
            onChange={() => {
              setLists((prevList) => {
                const updatedData = prevList.data.map((item) => {
                  if (item.chitId === props.dataItem.chitId) {
                    item.select = !item.select; // Toggle selection

                    if (item.select) {
                      setCheckedList((prevList) => [...prevList, item.chitId]);
                    } else {
                      setCheckedList((prevList) =>
                        prevList.filter((id) => id !== item.chitId)
                      );
                    }
                  }
                  return item;
                });

                // Update 'Select All' checkbox state
                setSelectAll(updatedData.every((item) => item.select));

                return { ...prevList, data: updatedData };
              });
            }}
          />
        ) : null}
      </td>
    );
  };

  const filterParamatersBinding = () => {
    let parameters = {};
    if (matterref !== null) {
      parameters.matterId = matterref;
    }
    if (valuePosting.length !== 0) {
      parameters.postingTypes = valuePosting;
    }
    parameters.postingStatus = postingStatus;
    if (valueFrom) {
      parameters.fromDate = fromDate;
    }
    if (valueTo) {
      parameters.toDate = toDate;
    }
    setFilterParmeters(parameters);
  };

  useEffect(() => {
    filterParamatersBinding();
  }, [
    postingStatus,
    fromDateSelector,
    toDateSelector,
    valuePosting,
    matterref,
    valueFrom,
    valueTo,
  ]);

  useEffect(() => {
    dispatch(getChitPostingtypes());
    // dispatch(getfeeearner());
  }, []);

  const addToBatch = () => {
    const data = list.data.filter((item) => item.select === true);
    data.forEach((item) => {
      item.postingCode = item.postingType;
      item.date = new Date(item.date);
      if (typeof item.matterID !== "object") {
        item.matterID = {
          matterRef: item.matterRef,
          matterId: item.matterID,
        };
      }
      return item;
    });
    setSelectedList(data);
    if (props.component === "batchPosting") {
      props.onAddToBatch(data);
      props.onClose();
    } else {
      setShowBatchPosting(true);
    }
  };

  const authorizeChit = async () => {
    const data = authorized.map((chitId) => ({
      chitID: chitId,
    }));

    try {
      const response = await AuthorizeChits(data);
      if (response.status === 200) {
        handleRefresh();
        toast.success("Chit authorised successfully");
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const dataReceived = (list) => {
    if (list.data) {
      list?.data?.map((item) => {
        item.select = false;
        item.postingField = postingdata?.filter(
          (item1) => item.postingType === item1.code
        )[0];
        item.postingType = item.postingField;
        if (
          item?.postingField?.code === "DCR" ||
          item?.postingField?.code === "DDR"
        ) {
          item.ddaBank = item.nominalInfo;
        }
        if (
          item?.postingField?.code === "CCR" ||
          item?.postingField?.code === "CDR"
        ) {
          item.clientBank = item.nominalInfo;
        }
        if (
          item?.postingField?.code === "OCR" ||
          item?.postingField?.code === "ODR" ||
          item?.postingField?.code === "DPU"
        ) {
          item.officeBank = item.nominalInfo;
        }
        item.feeearner = feeearnerdata.filter(
          (item1) => item.feeEarnerRef === item1.userRef
        )[0];
        item.feeEarnerRef = item.feeearner;
        checkedList.map((item1) => {
          if (item.chitId === item1) {
            item.select = true;
          }
        });
      });

      if (props.component === "batchPosting") {
        const data = list.data.filter(
          (item) => item.postingField?.code === props.postingType
        );
        setLists({
          data: data,
          total: data.length,
        });
        return;
      }
      setLists(list);
    }
  };
  const handleRefresh = () => {
    setAuthorized([]);
    setCheckedList([]);
    setSelectAll(false);
    setRefresh(!refresh);
  };

  const postingDoc = async (chitId) => {
    // setShow(!show);
    try {
      const { data } = await api.getPostingDocuments(chitId);
      setFileData(data.data);

      setShowDoc(true);
    } catch (error) {
      handleApiError(error);
    }
  };
  const DocumentCounterCell = (e) => {
    return (
      <td>
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          {e.dataItem.documentCount > 0 && (
            <>
              <Button
                onClick={() => postingDoc(e.dataItem.chitId)}
                className="border-0"
              >
                <AiOutlineFile color="blue" size={20} />
              </Button>
            </>
          )}
          <span>
            {!e.dataItem.documentCount || e.dataItem.documentCount === 0
              ? ""
              : e.dataItem.documentCount}
          </span>
        </div>
      </td>
    );
  };

  const AuthorizationCell = (e) => {
    const handleAuthorizationChange = () => {
      setLists((prevList) => {
        const updatedData = prevList.data.map((item) => {
          if (item.chitId === e.dataItem.chitId) {
            item.auth = !item.auth;

            if (item.auth) {
              setAuthorized((prevList) => [...prevList, item.chitId]);
            } else {
              setAuthorized((prevList) =>
                prevList.filter((id) => id !== item.chitId)
              );
            }
          }
          return item;
        });
        return { ...prevList, data: updatedData };
      });
    };

    const isDisabled =
      e.dataItem.excludedAuthUsers.includes(user.userRef) ||
      (!user?.profile?.accounts.AuthorizationLevel1 &&
        !user?.profile?.accounts.AuthorizationLevel2 &&
        !user?.profile?.accounts.AuthorizationLevel3);

    const tooltipMessage = e.dataItem.excludedAuthUsers.includes(user.userRef)
      ? `User '${user.userRef}' cannot authorise multiple levels of the same Chit Request`
      : "You do not have the required authorisation level to authorise this Chit Request";

    return (
      <td>
        {e.dataItem?.authorizationRequired ? (
          <Tooltip
            anchorElement="target"
            position="top"
            parentTitle
            style={{
              maxWidth: "275px",
            }}
          >
            <span title={isDisabled ? tooltipMessage : ""}>
              <Checkbox
                className="cursor-pointer"
                checked={e.dataItem.auth}
                onChange={handleAuthorizationChange}
                disabled={isDisabled}
              />
            </span>
          </Tooltip>
        ) : null}
      </td>
    );
  };

  return (
    <>
      <div className="page fluid-container">
        {showDoc && (
          <DocumentViewer data={fileData} onClose={() => setShowDoc(false)} />
        )}
        {props.component !== "batchPosting" && (
          <div className="row">
            <div className="col-12 d-flex flex-wrap align-items-start justify-content-start ">
              <div className="d-flex flex-column mb-2 me-4">
                <Label className="mb-2 filter-font">Posting Type</Label>
                <MultiSelect
                  data={postingTypes}
                  onChange={onChangePosting}
                  value={valuePosting}
                  className="dropdown-width"
                  size={"small"}
                />
              </div>

              <div className="d-flex flex-column me-4 mb-1">
                <Label className="mb-2 filter-font">Matter</Label>
                <MattersCombobox
                  onChangematter={onChangematter}
                  className="dropdown-width"
                />
              </div>
              <div className=" me-2  mt-1">
                <div className="nominal-form-to-parent-row">
                  <div className="labels-containers">
                    <Label editorId={"dp"}>From</Label>
                    <div className="label-to">
                      <Label editorId="to">To</Label>
                    </div>
                  </div>
                </div>
                <div className="nominal-form-to-row">
                  <div
                    className="date-form-to-wrapper"
                    style={{ height: "40px" }}
                  >
                    <div className="from">
                      <div className="d-flex align-items-center justify-content-center">
                        <Checkbox
                          value={valueFrom}
                          className="ms-2 me-1"
                          onChange={(e) => {
                            setValueFrom(e.value);
                          }}
                          label={""}
                        />
                        <DatePicker
                          id="from"
                          className="date-picker"
                          value={fromDateSelector || null}
                          onChange={onChangeFromDateSelect}
                          dateInput={CustomDateInput}
                          toggleButton={CalenderCustomToggleButton}
                          defaultValue={fromDateSelector || null}
                          placeholder="Select to date"
                        />
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="to">
                      <div className="d-flex align-items-center justify-content-center">
                        <Checkbox
                          value={valueTo}
                          className="ms-2 me-1"
                          onChange={(e) => {
                            setValueTo(e.value);
                          }}
                          label={""}
                        />
                        <DatePicker
                          id="to"
                          className="date-picker"
                          value={toDateSelector || null}
                          onChange={onChangeToDateSelect}
                          dateInput={CustomDateInput}
                          toggleButton={CalenderCustomToggleButton}
                          defaultValue={toDateSelector || null}
                          placeholder="Select to date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex flex-column radio me-4">
                  <Label className="mb-2 filter-font">Type</Label>
                  <RadioGroup
                    data={PostingStatus}
                    onChange={changePostingStatus}
                    defaultValue={PostingStatus[0].value}
                    className="d-flex flex-row mt-1  radio-group-gap"
                  />
                </div>
              </div>
              <div className="mt-4 me-auto">
                <Button className="common-btn" onClick={handleRefresh}>
                  <span className="d-flex align-items-center">
                    <span className="k-icon k-i-reload me-2"></span>
                    Refresh
                  </span>
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="row mt-3 ">
          <Grid
            {...dataState}
            data={list}
            onDataStateChange={dataStateChange}
            className={`fs-md ${styles["chit-posting-grid"]}`}
            sortable={true}
            pageable={state.pageable}
            pageSize={state.pageSize}
          >
            <GridColumn field="postingType.code" title="Type" width={60} />
            <GridColumn field="matterRef" title="Matter" width="140px" />
            <GridColumn
              field="date"
              title="Date"
              cell={DateFormatCell}
              width="120px"
            />
            <GridColumn field="details" title="Details" />
            <GridColumn field="supplierRef" title="Supplier" />
            <GridColumn
              field="vatAmount"
              title="Vat"
              cell={AmountFormatCell}
              width="100px"
            />
            <GridColumn
              field="netAmount"
              title="Gross Amount"
              cell={AmountFormatCell}
              width="140px"
            />
            <GridColumn
              field="documentCount"
              title="Doc"
              width="80px"
              cell={DocumentCounterCell}
              headerClassName="grid-header-center"
            />
            <GridColumn
              field="authorizationRequired"
              title="Auth"
              width="80px"
              cell={AuthorizationCell}
            />
            <GridColumn
              field=""
              title=""
              cell={PostCell}
              width="80px"
              headerCell={PostHeaderCell}
              headerClassName="text-center align-middle p-0"
            />
          </Grid>
        </div>
        <div className="row mt-3 mb-3 d-flex justify-content-between">
          <div className="col-auto">
            <Button
              className="common-btn"
              type="button"
              style={{ maxWidth: "130px" }}
              onClick={() => {
                setShowImports(true);
              }}
            >
              Import Chits
            </Button>
          </div>
          <div className="col-auto d-flex gap-2">
            <Button
              className="common-btn"
              style={{ maxWidth: "130px" }}
              onClick={authorizeChit}
              disabled={authorized.length === 0}
            >
              Authorize Chit
            </Button>
            <Button
              className="common-btn"
              style={{ maxWidth: "130px" }}
              disabled={checkedList.length === 0}
              onClick={addToBatch}
            >
              Add to Batch
            </Button>
          </div>
        </div>
        {showImports && (
          <ChitImports cancelEdit={() => setShowImports(false)} />
        )}
        <HandleGridBody
          dataState={dataState}
          onDataReceived={dataReceived}
          currentComponent={
            props.component === "batchPosting" ? "batchPosting" : "chitPosting"
          }
          parameters={filterParameters}
          refresh={refresh}
        />
        <Modalposting
          value={modalvalue}
          reset={() => {
            //   resetfields();
          }}
          closeModal={() => {
            setmodalvalue(false);
          }}
          component={"Posting"}
        ></Modalposting>
      </div>
      {showBatchPosting && (
        <Dialog
          style={{ overflow: "hidden" }}
          title={`Batch Posting`}
          className="batch-posting-dialog dialog-xxl"
          onClose={() => {
            setShowBatchPosting(false);
          }}
        >
          <div className="p-2" style={{ overflow: "hidden !important" }}>
            <BatchPostings
              type={"chitPosting"}
              selectedList={selectedList}
              onClose={() => {
                setShowBatchPosting(false);
                handleRefresh();
              }}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};
export default ChitPosting;
