import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ActiveCell, SignatureCell, EditCommandCell } from "../GridComponents";
import { CiViewList } from "react-icons/ci";
import EditFixedFeeHeader from "./EditFixedFeeHeader";
import {
  clearFeeHeaderItem,
  editFeeHeader,
  getFeeHeaderItems,
  getProfitCostDetails,
} from "actions/fixedFeeHeader.action";
import ViewFixedHeader from "./ViewFixedHeader";

const EditCommandCell1 = (props) => {
  if (typeof props.dataItem.allowedApps === "string") {
    const array = props?.dataItem?.allowedApps?.split(",");
    props.dataItem.allowedApps = array;
  }
  return <EditCommandCell {...props} component="ProfitCost" />;
};

const ViewCommandCell = (props) => {
  const dispatch = useDispatch();
  return (
    <td>
      <Button
        style={{ marginLeft: "10px", backgroundColor: "white" }}
        onClick={() => {
          dispatch(getFeeHeaderItems(props.dataItem.fixedFeeHeaderID));
          props.enterViewEdit(props.dataItem);
        }}
      >
        <CiViewList size={25} color={"#241CCA"} />
      </Button>
    </td>
  );
};

const NameHeader = (props) => {
  return (
    <div
      onClick={(e) => {
        // props.onClick(e);
      }}
      style={{ textAlign: "center", marginBottom: "7px" }}
    >
      <span className="">{props.title}</span>
      {props.children}
    </div>
  );
};

const ProfitCostTemplateList = () => {
  const [openForm, setOpenForm] = React.useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [gridLoading, setGridLoading] = useState(true);
  const [editItem, setEditItem] = React.useState({
    ProductID: 1,
  });
  const [viewItem, setViewItem] = React.useState({
    ProductID: 1,
  });
  const [openViewForm, setOpenViewForm] = React.useState(false);

  const templatesData = useSelector(
    (state) => state.profitCostTemplate.profitCostTemplateList
  );
  useEffect(() => {
    if (templatesData !== null && templatesData !== undefined) {
      templatesData.data.map((item) => {
        return (item.symbol = item.name[0]);
      });
      setData(templatesData);
    }
  }, [templatesData]);
  const enterEdit = (item) => {
    setOpenForm(true);
    setEditItem(item);
  };

  const enterViewEdit = (item) => {
    setOpenViewForm(true);
    setViewItem(item);
  };

  const handleSubmit = (event) => {
    let allowedApps = "";
    event.allowedApps.map((item) => {
      if (event.allowedApps.indexOf(item) + 1 === event.allowedApps.length) {
        allowedApps += item;
      } else {
        allowedApps += item + ",";
      }
    });
    let data = {
      name: event.name,
      details: event.details,
      currencyCode: event.currencyCode,
      active: event.active,
      allowedApps: allowedApps,
      type: "Profitcost",
    };
    dispatch(editFeeHeader(event.fixedFeeHeaderID, data));
    setOpenForm(false);

    setTimeout(() => {
      dispatch(getProfitCostDetails());
    }, 1000);
  };

  const [products, setProducts] = React.useState({
    data: [],
    total: 20,
  });

  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
    total: 20,
  });

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const MyEditCommandCell = (props) => (
    <EditCommandCell1 {...props} enterEdit={enterEdit} />
  );

  const MyViewCommandCell = (props) => (
    <ViewCommandCell {...props} enterViewEdit={enterViewEdit} />
  );

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (products) => {
    setProducts(products);
  };

  useEffect(() => {
    dispatch(getProfitCostDetails());
    setTimeout(() => {
      setGridLoading(false);
    }, 500);
  }, []);

  return (
    <div className="settings-grid">
      {gridLoading && <LoadingPanel />}
      <Grid {...dataState} data={data} onDataStateChange={dataStateChange}>
        <Column cell={SignatureCell} title=" " field="symbol" width="80" />
        <Column field="name" title="Name" />
        <Column field="details" title="Details" />
        <Column field="allowedApps" title="Allowed Apps" />
        <Column cell={ActiveCell} title="Active" width="80" />
        <Column
          title="Items"
          cell={MyViewCommandCell}
          width="80"
          headerCell={NameHeader}
        />
        <Column
          title="Edit"
          cell={MyEditCommandCell}
          width="60"
          headerCell={NameHeader}
        />
      </Grid>
      {openForm && (
        <EditFixedFeeHeader
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )}
      {openViewForm && (
        <ViewFixedHeader
          cancelEdit={() => {
            setOpenViewForm(false);
            dispatch(clearFeeHeaderItem());
          }}
          // onSubmit={handleSubmit}
          item={viewItem}
        />
      )}
    </div>
  );
};

const LoadingPanel = () => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );
  const gridContent = document && document.querySelector(".k-grid-content");
  return gridContent
    ? ReactDOM.createPortal(loadingPanel, gridContent)
    : loadingPanel;
};

export default ProfitCostTemplateList;
