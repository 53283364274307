import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Avatar, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { toast } from "react-toastify";

import styles from "./Casefile.module.scss";
import { getClientData, getClientLoginAccess, getMatterAllInfo } from "api";
import { getMattersAllURLInfo } from "actions/matters.action";

import CaseActivitiesPage from "./CaseActivities/CaseActivitiesPage";
import MatterLedgerCardPage from "pages/Matters/MattersLedgerCardPage";
import CaseContactsPage from "../CaseFile/CaseContactsPage";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import CaseWorkFlows from "./CaseWorkFlow/CaseWorkFlow";
import TimeRecorded from "components/Time/TimeRecorded";
import MatterForm from "pages/Matters/MatterForm";
import AddClients from "pages/Clients/AddViewClients";
import BillingModal from "./BillingModal";
import EmailCompletion from "components/common/EmailComposition";
import CaseTasks from "./CaseTasks";
import useFilterState from "../../components/CaseFiles/useFilterState";
import EmailDialog from "components/common/EmailComposition/EmailDialog";
import ChitRequest from "pages/ChitRequest/ChitRequest";
import { ClientServices } from "./ClientServices";

/*** IMPORT ICONS*/
import CaseFileImage from "../../assets/CaseFile.png";
import ClientIcon from "../../assets/Client.png";
import mail from "../../assets/CaseFileIcons/newEmail.svg";
import filtersAppliedIcon from "../../assets/CaseFileIcons/FilterApplied.svg";
import ChitRequestIcon from "../../assets/CaseFileIcons/chit_request.svg";
import billingIcon from "../../assets/CaseFileIcons/billingGuide.svg";
import ConflictCheckImage from "../../assets/CaseFileIcons/ConflictCheck.png";
import ClientDropDown from "../../assets/CaseFileIcons/app-bar/Client.png";
import ChitRequestDropDown from "../../assets/CaseFileIcons/app-bar/ChitRequest.png";
import ConflictCheckDropDown from "../../assets/CaseFileIcons/app-bar/ConflictCheck.png";
import BillingGuideDropDown from "../../assets/CaseFileIcons/app-bar/BillingGuide.png";
import EmailDropDown from "../../assets/CaseFileIcons/app-bar/Email.png";
import MatterDropdown from "../../assets/CaseFileIcons/app-bar/Matter.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdArrowDropDown } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import ConflictCheck from "./ConflictCheck";
import { ClientIdVerification } from "./ClientServices/ClientServicesTasks/IdVerification";
import BillingRequestModal from "pages/Bills/BillingRequestModal";

export const NameAlias = (props) => {
  return (
    <Avatar
      type={"image"}
      shape={"circle"}
      style={{ cursor: "pointer", backgroundColor: "#FFB800", color: "black" }}
    >
      {props.data}
    </Avatar>
  );
};

const options = [
  "Activities",
  "Time Recording",
  "Tasks",
  "Work Flows",
  "Client Services",
  "Contacts",
  "Ledger Card",
];

const itemRender = (props) => {
  return (
    <div className="flex items-center mt-1">
      {props.item.icon && (
        <img
          src={props.item.icon}
          className="k-mr-3 flex-shrink-0"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      )}
      <span className="flex-grow">{props.item.text}</span>
    </div>
  );
};

const CaseFileView = (props) => {
  const anchor = useRef();
  const anchorContent = useRef();
  const [currentMatterDetails, setcurrentMatterDetails] = useState([]);
  const [billingRequestMatter, setBillingRequestMatter] = useState();
  const [activeItem, setActiveItem] = useState(0);
  const [addActivity, setAddActivity] = useState(false);
  const [attachContact, setAttachContact] = useState(false);
  const [reference, setReference] = useState();
  const [clientReference, setClientReference] = useState();
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [viewActivity, setViewActivity] = useState();
  const [openEmailCompletion, setOpenEmailCompletion] = useState(false);
  const [openExistingEmailCompletion, setOpenExistingEmailCompletion] =
    useState();
  const [editData, setEditData] = useState({});
  const [showClientForm, setShowClientForm] = React.useState(false);
  const [addTime, setAddTime] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const [showScreens, setShowScreens] = useState(false);
  const [showMatterForm, setshowMatterForm] = React.useState(false);
  const [editWorkflow, setEditWorkflow] = useState(false);
  const [showClientView, setshowClientView] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [refreshClicked, setRefreshClicked] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [showBillingGuide, setShowBillingGuide] = useState(false);
  const [showChitRequest, setShowChitRequest] = useState(false);
  const [showConflictCheck, setShowConflictCheck] = useState(false);
  const [clientTask, setClientTask] = useState();

  const filterAnchorRef = useRef(null);
  const filterContentRef = useRef(null);

  const activitiesFilterState = useFilterState();
  const timeRecordingFilterState = useFilterState();
  const tasksFilterState = useFilterState();

  useEffect(() => {
    async function getMatterInfo() {
      const datas = await getMatterAllInfo(props?.id);
      setcurrentMatterDetails(datas?.data?.data);
    }
    getMatterInfo();
  }, [props?.id]);

  const clientsDataGetter = async (id) => {
    try {
      setIsLoading(true);
      const data = await getClientData(id);
      setEditData(data?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("No client exists against this Id. Try again with different Id");
      navigate("/clients");
    }
  };

  const navigateToClientPortal = async (id) => {
    try {
      setIsLoading(true);
      const response = await getClientLoginAccess(
        currentMatterDetails?.clientId,
        currentMatterDetails?.matterId
      );

      // Assuming the URL is in response.data.url
      const clientPortalUrl = response.data;

      if (clientPortalUrl) {
        window.open(clientPortalUrl, "_blank"); // Open the URL in a new tab
      } else {
        toast.error("Invalid URL received from the server.");
      }
      setIsLoading(false); // Stop loading after navigation
    } catch (error) {
      setIsLoading(false);
      toast.error("Not found");
    }
  };

  const onClientRefClickActions = () => {
    setClientReference(currentMatterDetails?.clientRef);
    clientsDataGetter(currentMatterDetails?.clientId);
    setshowClientView(true);
  };

  const mattersDataGetter = async (id) => {
    try {
      dispatch(getMattersAllURLInfo(currentMatterDetails, false));
    } catch (error) {
      alert("No matter exists against this Id. Try again with different Id");
      navigate("/matters");
    }
  };

  const onMatterRefClickActions = (props) => {
    // navigate(`/matters/${props.dataItem.matterId}`);
    setReference(currentMatterDetails?.matterRef);
    mattersDataGetter(currentMatterDetails?.matterId);
    setshowMatterForm(true);
  };

  const actionButtonItems = [
    {
      text: "Client",
      icon: ClientDropDown,
      action: onClientRefClickActions,
    },
    {
      text: "Matter",
      icon: MatterDropdown,
      action: onMatterRefClickActions,
    },
    {
      text: "Conflict Check",
      icon: ConflictCheckDropDown,
      action: () => setShowConflictCheck(true),
    },
    {
      text: "Billing Guide",
      icon: BillingGuideDropDown,
      action: () => setShowBillingGuide(true),
    },
    {
      text: "Chit Request",
      icon: ChitRequestDropDown,
      action: () => setShowChitRequest(true),
    },
    {
      text: "Billing Request",
      icon: ChitRequestDropDown,
      action: () => setBillingRequestMatter(currentMatterDetails),
    },
    {
      text: "Email",
      icon: EmailDropDown,
      action: () => setOpenEmailCompletion(true),
    },
  ];

  const servicesButtonItems = [
    {
      text: "Data Collection Form",
      action: () => setShowScreens(true),
    },
    {
      text: "Data Collection File",
      action: () => {},
    },
    {
      text: "ID Verification",
      action: () => setClientTask("ID-Verification"),
    },
    {
      text: "Request Documents",
      action: () => {},
    },
    {
      text: "Sign Documents",
      action: () => {},
    },
    {
      text: "Funds on Account",
      action: () => {},
    },
  ];

  // useEffect(() => {
  //   const close = (e) => {
  //     if (e?.target?.role === "menu") return;
  //     // if (anchorContent.current && anchorContent.current.contains(e.target))
  //     //   return;
  //     if (!anchor.current?.contains(e.target)) {
  //       setShowPopUp(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", close);
  //   return () => {
  //     document.removeEventListener("mousedown", close);
  //   };
  // }, []);

  return (
    <div className="page">
      <div
        id="CaseFile Header"
        className="d-flex mb-4 ps-2 ms-1"
        style={{
          height: "40px",
        }}
      >
        <div className="row w-100">
          <div
            className={`${styles.legalProceeding} align-items-center d-flex justify-content-between px-4`}
          >
            <div className={`${styles["sub-text"]}`}>
              {currentMatterDetails?.client?.clientName} -{" "}
              <u>{currentMatterDetails?.matterRef}</u> -{" "}
              <b className={`${styles.headerMatterDetails} `}>
                {currentMatterDetails?.details}{" "}
              </b>
              {currentMatterDetails?.appCode && (
                <span> - [{currentMatterDetails?.appCode}]</span>
              )}
            </div>
            <div className="d-flex align-items-center gap-3">
              {/* <div ref={anchor}> */}
              {/* <IoEllipsisHorizontalSharp
                    size={20}
                    onClick={() => setShowPopUp(!showPopUp)}
                  /> */}
              <IconContainer
                title="Email "
                onClick={() => {
                  setOpenEmailCompletion(true);
                }}
              >
                <img
                  title="New Email"
                  src={mail}
                  width={18}
                  height={18}
                  alt="Email Icon"
                ></img>
              </IconContainer>

              <IconContainer
                title="New Chit Request"
                onClick={() => {
                  setShowChitRequest(true);
                }}
              >
                <img
                  title="Chit Request"
                  src={ChitRequestIcon}
                  width={18}
                  height={18}
                  alt="Chit Request Icon"
                ></img>
              </IconContainer>

              <IconContainer
                title="Billing Guide"
                onClick={() => {
                  setShowBillingGuide(true);
                }}
              >
                <img
                  title="Billing Guide"
                  src={billingIcon}
                  width={18}
                  height={18}
                  alt="Billing Icon"
                ></img>
              </IconContainer>

              <IconContainer
                title="Conflict Check"
                onClick={() => {
                  setShowConflictCheck(true);
                }}
              >
                <img
                  title="Matter"
                  src={ConflictCheckImage}
                  alt="Matter Icon"
                ></img>
              </IconContainer>

              <IconContainer
                title="Matter"
                onClick={() => onMatterRefClickActions()}
              >
                <img title="Matter" src={CaseFileImage} alt="Matter Icon"></img>
              </IconContainer>

              <IconContainer
                title="Client"
                onClick={() => onClientRefClickActions()}
              >
                <img title="Client" src={ClientIcon} alt="Client Icon"></img>
              </IconContainer>

              <DropDownButton
                text="Actions"
                items={actionButtonItems}
                popupSettings={{
                  animate: false,
                  popupClass: styles["popup"],
                }}
                itemRender={itemRender}
                onItemClick={(e) =>
                  actionButtonItems
                    .filter((item) => item.text === e.item.text)[0]
                    ?.action()
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-end"
        style={{
          height: "35px",
        }}
      >
        {activeItem === 0 && (
          //Activities selected
          <>
            <div
              ref={filterAnchorRef}
              onClick={() =>
                activitiesFilterState.setFilterClicked(
                  !activitiesFilterState.filterClicked
                )
              }
            >
              {!activitiesFilterState.filterApplied ? (
                <Button
                  className="common-btn me-2 p-2"
                  style={{
                    width: "35px",
                    minWidth: "35px",
                    height: "35px",
                    zIndex: "100",
                  }}
                >
                  <FaFilter color="white" size={16} />
                </Button>
              ) : (
                <Button
                  className="common-btn me-2 p-2 "
                  style={{
                    width: "35px",
                    minWidth: "35px",
                    height: "35px",
                    zIndex: "100",
                  }}
                >
                  <img
                    src={filtersAppliedIcon}
                    alt="Filters Applied"
                    width={25}
                    height={25}
                  />
                </Button>
              )}
            </div>
            <Button
              className="me-2  common-btn"
              icon="refresh"
              style={{
                width: "35px",
                height: "35px",
                minWidth: "35px",
                zIndex: "100",
              }}
              onClick={() => {
                setRefreshClicked(true);
              }}
            ></Button>
            <Button
              style={{
                width: "100px",
                minWidth: "100px",
                height: "35px",
                zIndex: "100",
              }}
              className="common-btn"
              onClick={() => setAddActivity(true)}
            >
              Add Activity
            </Button>
          </>
        )}

        {activeItem === 1 && (
          //Time Recording
          <>
            <div
              ref={filterAnchorRef}
              onClick={() =>
                timeRecordingFilterState.setFilterClicked(
                  !timeRecordingFilterState.filterClicked
                )
              }
            >
              {!timeRecordingFilterState.filterApplied ? (
                <Button
                  className="common-btn me-2 p-2"
                  style={{
                    width: "35px",
                    minWidth: "35px",
                    height: "35px",
                    zIndex: "100",
                  }}
                >
                  <FaFilter color="white" size={16} />
                </Button>
              ) : (
                <Button
                  className="common-btn me-2 p-2  "
                  style={{
                    width: "35px",
                    minWidth: "35px",
                    height: "35px",
                    zIndex: "100",
                  }}
                >
                  <img
                    src={filtersAppliedIcon}
                    alt="Filters Applied"
                    width={25}
                    height={25}
                  />
                </Button>
              )}
            </div>
            <Button
              className="me-2  common-btn"
              icon="refresh"
              style={{
                width: "35px",
                minWidth: "35px",
                height: "35px",
                zIndex: "100",
              }}
              onClick={() => {
                setRefreshClicked(true);
              }}
            ></Button>

            <Button
              style={{
                width: "100px",
                minWidth: "100px",
                height: "35px",
                zIndex: "100",
              }}
              className="common-btn"
              onClick={() => setAddTime(true)}
            >
              Add Time
            </Button>
          </>
        )}

        {activeItem === 2 && (
          <>
            <div
              ref={filterAnchorRef}
              onClick={() =>
                tasksFilterState.setFilterClicked(
                  !tasksFilterState.filterClicked
                )
              }
            >
              {!tasksFilterState.filterApplied ? (
                <Button
                  className="common-btn me-2 p-2"
                  style={{
                    width: "35px",
                    minWidth: "35px",
                    height: "35px",
                    zIndex: "100",
                  }}
                >
                  <FaFilter color="white" size={16} />
                </Button>
              ) : (
                <Button
                  className="common-btn me-2 p-2 "
                  style={{
                    width: "35px",
                    minWidth: "35px",
                    height: "35px",
                    zIndex: "100",
                  }}
                >
                  <img
                    src={filtersAppliedIcon}
                    alt="Filters Applied"
                    width={25}
                    height={25}
                  />
                </Button>
              )}
            </div>
            <Button
              className="me-2  common-btn"
              icon="refresh"
              style={{
                width: "35px",
                minWidth: "35px",
                height: "35px",
                zIndex: "100",
              }}
              onClick={() => {
                setRefreshClicked(true);
              }}
            ></Button>

            <Button
              style={{
                width: "100px",
                minWidth: "100px",
                height: "35px",
                zIndex: "100",
              }}
              className="common-btn"
              onClick={() => setAddTask(true)}
            >
              Add Task
              <RiArrowDropDownLine />
            </Button>
          </>
        )}

        {activeItem === 3 && (
          //workflow
          <div>
            <Button
              className="me-2  common-btn"
              icon="refresh"
              style={{
                width: "35px",
                minWidth: "35px",
                height: "35px",
                zIndex: "100",
              }}
              onClick={() => {
                setRefreshClicked(true);
              }}
            ></Button>

            <Button
              className="common-btn"
              style={{
                width: "120px",
                minWidth: "120px",
                height: "35px",
                zIndex: "100",
              }}
              onClick={() => setEditWorkflow(!editWorkflow)}
            >
              {editWorkflow ? "Exit Edit Mode" : "Edit Workflow"}
            </Button>
          </div>
        )}

        {activeItem === 4 && (
          <div>
            <Button
              style={{
                width: "50px",
                minWidth: "50px",
                height: "35px",
                zIndex: "100",
              }}
              className="common-btn me-2"
              onClick={() => navigateToClientPortal()}
              disabled={isLoading}
            >
              Portal
            </Button>

            {/*<Button*/}
            {/*              style={{ width: "70px", minWidth: "70px", height: "35px", zIndex: "100" }}*/}
            {/*  className="common-btn me-2"*/}
            {/*  onClick={() => setAttachContact(true)}*/}
            {/*>*/}
            {/*  Template*/}
            {/*</Button>*/}

            {/* <Button
              style={{
                width: "100px",
                minWidth: "100px",
                height: "35px",
                zIndex: "100",
              }}
              className="common-btn pe-2"
              onClick={() => setShowScreens(true)}
            >
              <span className="">Add Task </span>
              <MdArrowDropDown size={22} style={{ marginBottom: "2px" }} />
            </Button> */}

            <DropDownButton
              text={
                <>
                  <span className="ms-2 pt-1">Add Task </span>
                  <MdArrowDropDown size={22} style={{ marginBottom: "2px" }} />
                </>
              }
              items={servicesButtonItems}
              // className="common-btn"
              popupSettings={{
                animate: false,
                popupClass: `${styles["popup"]} me-4`,
              }}
              buttonClass={`${styles["button-overlay"]} common-btn h-100 px-2`}
              style={{
                height: "35px",
              }}
              itemRender={itemRender}
              onItemClick={(e) =>
                servicesButtonItems
                  .filter((item) => item.text === e.item.text)[0]
                  ?.action()
              }
            />
          </div>
        )}

        {activeItem === 5 && (
          <div>
            <Button
              className="me-2  common-btn"
              icon="refresh"
              style={{
                width: "35px",
                minWidth: "35px",
                height: "35px",
                zIndex: "100",
              }}
              onClick={() => {
                setRefreshClicked(true);
              }}
            ></Button>

            <Button
              style={{
                width: "120px",
                minWidth: "120px",
                height: "35px",
                zIndex: "100",
              }}
              className="common-btn"
              onClick={() => setAttachContact(true)}
            >
              Attach Contact
            </Button>
          </div>
        )}

        {activeItem === 6 && (
          <div>
            <Button
              className="me-2  common-btn"
              icon="refresh"
              style={{
                width: "35px",
                minWidth: "35px",
                height: "35px",
                zIndex: "100",
              }}
              onClick={() => {
                setRefreshClicked(true);
              }}
            ></Button>
          </div>
        )}
      </div>
      <div
        className="row align-items-center"
        style={{
          marginTop: "-35px",
        }}
      >
        <div className={`styles["view-tittle"] col`}>
          <TabStrip
            scrollable={true}
            onSelect={(e) => setActiveItem(e.selected)}
            keepTabsMounted={true}
            selected={activeItem}
            className="pagetab case-files-tab"
          >
            {options.map((item) => {
              return (
                <TabStripTab
                  title={
                    <PageTabItemTitle
                      title={item}
                      index={options.indexOf(item)}
                      selected={activeItem}
                    />
                  }
                  key={item}
                >
                  <div className="" style={{ marginTop: "30px" }}>
                    {item === "Activities" && (
                      <CaseActivitiesPage
                        filterAnchor={filterAnchorRef}
                        filterContentRef={filterContentRef}
                        filterClicked={activitiesFilterState.filterClicked}
                        setFilterClicked={
                          activitiesFilterState.setFilterClicked
                        }
                        setFilterApplied={
                          activitiesFilterState.setFilterApplied
                        }
                        filterApplied={activitiesFilterState.filterApplied}
                        addActivity={addActivity}
                        refreshClicked={refreshClicked}
                        setRefreshClicked={setRefreshClicked}
                        activeItem={activeItem}
                        setAddActivity={(value) => setAddActivity(value)}
                        addFormText={
                          currentMatterDetails?.matterRef +
                          "-" +
                          currentMatterDetails?.details
                        }
                        matterId={props?.id}
                        matterDetails={currentMatterDetails}
                      />
                    )}

                    {item === "Time Recording" && (
                      <TimeRecorded
                        {...props}
                        filterAnchor={filterAnchorRef}
                        filterContentRef={filterContentRef}
                        setFilterApplied={
                          timeRecordingFilterState.setFilterApplied
                        }
                        filterApplied={timeRecordingFilterState.filterApplied}
                        addTime={addTime}
                        refreshClicked={refreshClicked}
                        setRefreshClicked={setRefreshClicked}
                        filterClicked={timeRecordingFilterState.filterClicked}
                        setFilterClicked={
                          timeRecordingFilterState.setFilterClicked
                        }
                        activeItem={activeItem}
                        setAddTime={() => setAddTime(false)}
                        fromCaseFile={true}
                        selectedMatterId={currentMatterDetails?.matterId}
                        matter={currentMatterDetails}
                      />
                    )}
                    {item === "Tasks" && (
                      <CaseTasks
                        filterAnchor={filterAnchorRef}
                        filterContentRef={filterContentRef}
                        filterClicked={tasksFilterState.filterClicked}
                        setFilterClicked={tasksFilterState.setFilterClicked}
                        setFilterApplied={tasksFilterState.setFilterApplied}
                        filterApplied={tasksFilterState.filterApplied}
                        addTask={addTask}
                        addFormText={
                          currentMatterDetails?.matterRef +
                          "-" +
                          currentMatterDetails?.details
                        }
                        refreshClicked={refreshClicked}
                        setRefreshClicked={setRefreshClicked}
                        activeItem={activeItem}
                        setAddTask={(value) => setAddTask(value)}
                        matterId={currentMatterDetails?.matterId}
                        matterDetails={currentMatterDetails}
                      />
                    )}

                    {item === "Work Flows" && (
                      <CaseWorkFlows
                        editWorkflow={editWorkflow}
                        selTab={props.selTab}
                        reference={currentMatterDetails}
                        matterId={currentMatterDetails?.matterId}
                        refreshClicked={refreshClicked}
                        setRefreshClicked={setRefreshClicked}
                        activeItem={activeItem}
                      />
                    )}

                    {item === "Client Services" && (
                      <ClientServices
                        matter={currentMatterDetails}
                        showScreens={showScreens}
                        setShowScreens={setShowScreens}
                      />
                    )}
                    {item === "Contacts" && (
                      <CaseContactsPage
                        refreshClicked={refreshClicked}
                        setRefreshClicked={setRefreshClicked}
                        activeItem={activeItem}
                        attachContact={attachContact}
                        setAttachContact={(value) => setAttachContact(value)}
                        matterId={currentMatterDetails?.matterId}
                      />
                    )}
                  </div>
                  {item === "Ledger Card" && (
                    <MatterLedgerCardPage
                      selTab={props.selTab}
                      reference={currentMatterDetails?.matterId}
                      refreshClicked={refreshClicked}
                      setRefreshClicked={setRefreshClicked}
                      activeItem={activeItem}
                    />
                  )}
                </TabStripTab>
              );
            })}
          </TabStrip>

          {showChitRequest && (
            <ChitRequest
              cancelEdit={() => {
                setShowChitRequest(false);
              }}
              component={"modal"}
              matter={currentMatterDetails}
            />
          )}

          {showMatterForm && (
            <MatterForm
              isView={reference !== undefined}
              reference={reference}
              onMatterRefClickActions={onMatterRefClickActions}
              setshowMatterForm={() => {
                setshowMatterForm(false);
                setReference(undefined);
              }}
            />
          )}
          {showClientView && !isLoading && (
            <AddClients
              data={editData}
              formId={"view"}
              onClose={() => {
                setshowClientView(false);
              }}
              open={() => {
                setshowClientView(true);
              }}
              reference={reference}
              type={"VIEW"}
            />
          )}
          {}
          {showClientForm && (
            <Dialog
              title={"Update Client"}
              onClose={() => {
                setShowClientForm(false);
              }}
              open={() => {
                setShowClientForm(true);
              }}
              className="dialog-xxl"
              // width={"80vw"}
            >
              <div className={`container ${styles["clinet-dialog-top-div"]}`}>
                <div className="">
                  <AddClients
                    data={editData}
                    isView={true}
                    formId={"edit"}
                    // title={`Add Client`}
                    onClose={() => {
                      setShowClientForm(false);
                    }}
                    open={() => {
                      setShowClientForm(true);
                    }}
                  />
                </div>
              </div>
              <div className="dialog-footer mt-2 px-0">
                <Button
                  type="button"
                  className="common-btn bgBtnSecondary border-0 me-2"
                  onClick={() => {
                    setShowClientForm(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="common-btn me-4"
                  type="submit"
                  onClick={() =>
                    document.getElementById("client-submitter").click()
                  }
                  form={"edit"}
                >
                  {"Update Client"}
                </Button>
              </div>
            </Dialog>
          )}
          {showBillingGuide && (
            <BillingModal
              onClose={() => setShowBillingGuide(false)}
              matterId={currentMatterDetails?.matterId}
            />
          )}
          {openEmailCompletion && (
            <EmailDialog
              title={"Email Details"}
              matterId={currentMatterDetails?.matterId}
              onClose={() => setOpenEmailCompletion()}
              openExistingEmailDialog={(e) => {
                setOpenExistingEmailCompletion(e);
                setOpenEmailCompletion();
              }}
            />
          )}

          {clientTask === "ID-Verification" && (
            <ClientIdVerification
              onClose={() => setClientTask()}
              matterId={currentMatterDetails?.matterId}
              client={currentMatterDetails?.client}
            />
          )}

          {showConflictCheck && (
            <Dialog
              title={"Conflict Check"}
              onClose={() => setShowConflictCheck(false)}
              width={"900px"}
            >
              <ConflictCheck
                onClose={() => setShowConflictCheck(false)}
                fromCaseFile={true}
                matterId={currentMatterDetails?.matterId}
                currentMatterDetails={currentMatterDetails}
              />
            </Dialog>
          )}

          {openExistingEmailCompletion && (
            <EmailCompletion
              matter={currentMatterDetails}
              data={openExistingEmailCompletion}
              onClose={() => setOpenExistingEmailCompletion()}
            />
          )}
          {/* <Popup
            show={showPopUp}
            anchor={anchor.current}
            popupClass={`${styles["popup-content"]} w-100`}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            popupAlign={{
              vertical: "top",
              horizontal: "right",
            }}
            animate={false}
          >
            <div
              ref={anchorContent}
              className="d-flex flex-column my-2 mx-2 justify-content-start align-items-start "
            >
              <MenuList
                border={true}
                icon={<IoReceiptSharp role="button" size={18} />}
                name="Billing Guide"
                noMargins
                onClick={() => {
                  setShowBillingGuide(true);
                  setShowPopUp(false);
                }}
              />
              <MenuList
                icon={<MdEmail role="button" size={18} color="black" />}
                name="New Email"
                noMargins
                onClick={() => {
                  setOpenEmailCompletion(true);
                  setShowPopUp(false);
                }}
              />
            </div>
          </Popup> */}

          {billingRequestMatter && (
            <BillingRequestModal
              onClose={() => {
                setBillingRequestMatter();
              }}
              selectedMatter={billingRequestMatter}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseFileView;

export const IconContainer = (props) => {
  return (
    <Tooltip anchorElement="target" position="top">
      <div
        title={props.title}
        className={`${
          styles["image-block"]
        } d-flex align-items-center justify-content-center ${
          !props.disabled && "cursor-pointer"
        }`}
        onClick={() => {
          if (props.onClick) props.onClick();
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
};
