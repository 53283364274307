import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { importSampleChit } from "api";
import axios from "axios";
import { baseURL } from "api/axios.config";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { toast } from "react-toastify";

const ChitImports = (props) => {
  const { accessToken } = useOidcAccessToken();
  const [loading, setLoading] = React.useState(false);
  const importSampleChits = async () => {
    const res = await importSampleChit();
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    const fileName = `Sample Chit.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const onFileUpload = async (e) => {
    try {
      setLoading(true);
      const fileName = e.target.files[0].name;
      const form = new FormData();
      form.append("File", e.target.files[0]);

      await axios.post(`${baseURL}/Chits/Import`, form, {
        headers: {
          type: "file",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      props.cancelEdit();
      toast.success(`${fileName} has been imported successfully`);
    } catch (error) {
      toast.error(`Unexpected Error Occcured`);
      props.cancelEdit();
    }
    setLoading(false);
  };

  return (
    <Dialog title={"Import Chits"} onClose={props.cancelEdit} width={500}>
      <div
        className="p-3 ps-4 mx-auto"
        style={{ height: "150px", width: "80%" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div>Import Chits</div>
          {loading ? (
            <div
              className="spinner-border spinner"
              role="status"
              style={{ marginRight: "60px" }}
            ></div>
          ) : (
            <label className="common-btn me-4 text-center" role="button">
              <input
                name="file"
                type="file"
                accept=".csv"
                onChange={onFileUpload}
              />
              Import
            </label>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div>Download Sample</div>
          <button className="common-btn me-3" onClick={importSampleChits}>
            {" "}
            Download
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ChitImports;
