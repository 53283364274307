import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import FixedFeeHeaderItemsList from "./FixedHeaderItemsList";
import * as api from "../../../api/index";
import { useDispatch } from "react-redux";
import EditFeeHeaderItems from "./EditFeeHeaderItems";
import { toast } from "react-toastify";
import { getFeeHeaderItems } from "actions/fixedFeeHeader.action";

const FixedHeaderItems = (props) => {

    const [addnewform, setaddnewform] = React.useState(false);

    const closeform = () => {
        setaddnewform(false);
    };
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (props.onClose === false) {
            closeform();
        }
    }, [props.onClose]);

    const handleSubmit = async (e) => {
        setaddnewform(false);
        try {
            let value = e.netAmount * (e?.vatCode?.rate / 100);
            const data = {
                ...e,
                vatCode: e?.vatCode?.vatCodeId,
                vatValue: value,
                vatRate: e?.vatCode?.rate,
            };
            let item = [];
            item.push(data);
            await api.createFixedFeeHeaderItems(props.item.fixedFeeHeaderID, item);
            toast.success("Item Added");
            dispatch(getFeeHeaderItems(props.item.fixedFeeHeaderID));
        } catch (error) {
            toast.error("Error Occured");
        }
    };

    return (
        <React.Fragment>
            <div className="w-100 d-flex flex-column align-items-end">
                <div className="float-end">
                    <Button
                        className="common-btn m-3"
                        onClick={() => {
                            setaddnewform(true);
                        }}
                    >
                        Add Item
                    </Button>
                </div>
                <FixedFeeHeaderItemsList item={props.item} />
            </div>
            {addnewform && (
                <EditFeeHeaderItems
                    cancelEdit={closeform}
                    onSubmit={handleSubmit}
                    type="addNew"
                />
            )}
        </React.Fragment>
    );
};

export default FixedHeaderItems;
