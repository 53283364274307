import React, { useState } from "react";
import ReportViewer from "./ReportViewerComponent";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Icon from "components/Global/icon";
import styles from "./Reports.module.scss";
import { useEffect } from "react";
import { SectionDividerFull } from "components/Contact/sectionDivider";
import { baseURL } from "api/axios.config";
import { getBankRec } from "api/accountsAPI";

const BankRec = () => {
  const [bankTypes] = useState([
    { label: "Office Bank", value: "BalanceSheet_OfficeBank" },
    { label: "Client Bank", value: "BalanceSheet_ClientBank" },
    { label: "DDA Bank", value: "BalanceSheet_DDABank" },
  ]);

  const [shouldLoad, setShouldLoad] = useState(false);
  const [bankRecData, setBankRecData] = useState([]);
  const [selectedBankType, setSelectedBankType] = useState(null);
  const [selectedBankRec, setSelectedBankRec] = useState(null);
  const [includeUnreconciled, setIncludeUnreconciled] = useState(true);
  const [bankRecError, setBankRecError] = useState(false);
  const [parameters, setParameters] = useState({
    BankRecID: null,
    IncludeUnreconciled: 1,
  });
  useEffect(() => {
    fetchBankRecData();
  }, [selectedBankType]);

  useEffect(() => {
    if (bankRecData?.length > 0) {
      const firstBankRec = bankRecData[0];
      setSelectedBankRec(firstBankRec);
      updateParameters(firstBankRec.bankRecId);
    }
  }, [bankRecData]);

  const updateParameters = (bankRecId) => {
    setParameters({
      BankRecID: bankRecId,
      IncludeUnreconciled: includeUnreconciled ? 1 : 0,
    });
  };

  const analyzeParameters = () => {
    if (!selectedBankRec) {
      setBankRecError(true);
      return;
    }
    setBankRecError(false);
    updateParameters(selectedBankRec.bankRecId);
    setShouldLoad(true);
  };

  const fetchBankRecData = async () => {
    try {
      const subtype = selectedBankType ? selectedBankType.value : null;
      const response = await getBankRec(subtype);
      const data = response.data.data || [];
      setBankRecData(data);

      if (data.length > 0) {
        setSelectedBankRec(data[0]);
      } else {
        setSelectedBankRec(null);
      }
    } catch (error) {
      console.error("Error fetching Bank Reconciliation data:", error);
    }
  };

  const itemRender = (li, itemProps) => {
    const dataItem = itemProps.dataItem;
    const itemChildren = (
      <div className="d-flex justify-content-center align-items-center flex-column w-100">
        <div
          className="d-flex flex-column justify-content-center ms-2 w-100 my-2 ps-2"
          style={{
            backgroundColor: "#F8F8F8",
            borderRadius: "5px",
            padding: "6px",
          }}
        >
          <div className="d-flex">
            <span className="fw-semibold fs-sm">{dataItem.nominalRef}</span>
            <span className="ms-2 fw-semibold fs-sm text-decoration-underline">
              {dataItem.nominalName}
            </span>
          </div>
          <div className="d-flex">
            <span className="fw-semibold fs-sm me-1">Balance: </span>
            <span className="fs-sm">{dataItem.statementBalance}</span>
            <span className="fs-sm ms-auto">
              {new Date(dataItem.toDate).toLocaleDateString()}
            </span>
          </div>
        </div>
        <hr
          className="ms-1 m-0"
          style={{
            width: "95%",
            border: "0.5px solid #E1E1E1",
          }}
        />
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (element) => {
    const selectedNominalName = selectedBankRec.nominalName || "";

    return (
      <div className="d-flex w-100 ms-2 align-items-center">
        <span>
          {element.props.value} - {selectedNominalName}
        </span>
      </div>
    );
  };

  return (
    <div className="container-fluid w-100 p-4 pt-2 mt-4 ">
      <div className="row h-100">
        <div className="col" style={{ maxWidth: "300px" }}>
          <div
            className={`p-4 pt-4 ${styles[`reportBackground`]} ${
              styles[`height`]
            }`}
          >
            <p className={`${styles[`heading`]} fs-lg`}>Filters</p>
            <div className="d-flex flex-column mt-3">
              <Label className="label mb-1">Bank Type</Label>
              <ComboBox
                data={bankTypes}
                textField="label"
                iconClassName="wa"
                value={selectedBankType}
                onChange={(e) => setSelectedBankType(e.value)}
                placeholder="Select Bank Type"
                style={{ maxWidth: "325px", height: "44px" }}
                className="ps-1"
                icon={<Icon icon="arrow-down" />}
              />{" "}
            </div>
            <div className="d-flex flex-column mt-3">
              <Label className="label mb-1">Bank Reconciliation</Label>
              <ComboBox
                data={bankRecData}
                dataItemKey="bankRecId"
                required
                textField={"nominalRef"}
                value={selectedBankRec}
                onChange={(e) => {
                  setSelectedBankRec(e.target.value);
                  setBankRecError(false);
                }}
                placeholder="Select Bank Reconciliation"
                style={{
                  maxWidth: "325px",
                  height: "44px",
                  border: bankRecError ? "1px solid red" : undefined,
                }}
                className="ps-1"
                iconClassName="wa"
                icon={<Icon icon="arrow-down" />}
                itemRender={itemRender}
                valueRender={selectedBankRec && valueRender}
              />
            </div>
            <SectionDividerFull sectionName={""} />
            <div className="pt-3">
              <Checkbox
                className="checkbox-label"
                value={includeUnreconciled}
                onChange={(e) => setIncludeUnreconciled(e.value)}
                label="Include Unreconciled Items"
                style={{ fontSize: "12px" }}
              />
            </div>{" "}
            <SectionDividerFull sectionName={""} />
            <div className="pt-3 float-end">
              <Button
                className="common-btn"
                type="submit"
                onClick={analyzeParameters}
              >
                Update
              </Button>
            </div>
          </div>
        </div>

        <div className="col">
          <div className={`row ${styles[`reportBackground`]} 1fr`}>
            {parameters.BankRecID && (
              <ReportViewer
                name="BankReconciliation.trdp"
                parameters={parameters}
                shouldLoad={shouldLoad}
                setShouldLoad={setShouldLoad}
                url={`${baseURL}/Accounts/Reporting/reports`}
              />
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankRec;
