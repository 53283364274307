import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { updateTransactionperiods } from "actions/transactionperiod.action";
import { AmountFormatCell } from "components/common/formatter";
import { NumericCell } from "../DibursementPayment/DisbursementInputField";
import { DateFormatCell } from "components/common/formatter";
import { process } from "@progress/kendo-data-query";
const EDIT_FIELD = "inEdit";
const initialDataState = {
  sort: [{ field: "postingType", dir: "asc" }],
  take: 50,
  skip: 0,
};
const OfficeCreditGrid = (props) => {
  const [dataState, setDataState] = useState(initialDataState);
  const [data, setData] = useState([]);

  const matters = useSelector(
    (state) => {
      if (state.postingsdocs.officeCreditMatterList !== undefined) {
        return state.postingsdocs.officeCreditMatterList.map((item) => ({
          ...item,
          inEdit: true,
          add: false,
          amount: 0,
        }));
      }
      return ["LIST_EMPTY"];
    },
    (prev, next) => {
      if (prev[0] === "LIST_EMPTY" && next[0] === "LIST_EMPTY") return true;
      if (prev.length !== next.length) return false;
      return prev.every(
        (item, index) => item.postingId === next[index].postingId
      );
    }
  );
  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };

  useEffect(() => {
    if (matters[0] === "LIST_EMPTY") {
      setData([]);
    } else if (!data.length) {
      setData(matters);
    }
  }, [matters]);

  const mergeAllocation = () => {
    const result = data
      .filter((item) => item.add)
      .map((item) => ({
        postingId: item.postingId,
        amount: item.amount,
      }));
    props.updateAllocations(result);
  };

  const calculateAmount = () => {
    let total = 0;
    data.map((item) => {
      if (
        item.add === true &&
        item.amount !== null &&
        item.amount !== undefined
      ) {
        total = total + parseInt(item.amount);
      }
    });
  };
  const AmountNameHeader = (props) => {
    return (
      <div
        onClick={(e) => {
          props.onClick(e);
        }}
        style={{ textAlign: "center", cursor: "pointer" }}
      >
        {props.title}
        {props.children}
      </div>
    );
  };
  useEffect(() => {
    mergeAllocation();
    calculateAmount();
  }, [data]);

  const itemChange = (e) => {
    let newData = data.map((item) => {
      if (item.postingId === e.dataItem.postingId) {
        if (
          e.field === "amount" &&
          e.value <= item.amountDue &&
          item.amountDue >= 0
        ) {
          item[e.field || ""] = e.value;
        } else if (
          e.field === "amount" &&
          e.value < 0 &&
          e.value > item.amountDue &&
          item.amountDue < 0
        ) {
          item[e.field || ""] = e.value;
        } else if (e.field === "add") {
          item[e.field || ""] = e.value;
        }
      }
      return item;
    });
    setData(newData);
    if (e.field === "amount") {
      if (
        e.dataItem.amount !== 0 &&
        e.dataItem.amount !== null &&
        e.dataItem.add === false
      ) {
        let newData = data.map((item) => {
          if (item.postingId === e.dataItem.postingId) {
            item.add = true;
          }
          return item;
        });
        setData(newData);
      } else if (
        (e.dataItem.amount === 0 || e.dataItem.amount === null) &&
        e.dataItem.add === true
      ) {
        let newData = data.map((item) => {
          if (item.postingId === e.dataItem.postingId) {
            item.add = false;
          }
          return item;
        });
        setData(newData);
      }
    }
    function getAmountToZero(sum) {
      return -sum;
    }
    if (e.field === "add") {
      if (e.dataItem.add === true) {
        let newData = data.map((item) => {
          if (item.postingId === e.dataItem.postingId) {
            const sum = data
              .filter((item) => item.add === true)
              .reduce((acc, item) => acc + item.amount, 0);

            let diff = sum - props.Amount;

            if (props.Amount === 0 || !props.Amount) {
              diff = sum;
            }

            if (sum !== 0 || props.Amount !== 0) {
              if (item.amountDue >= 0) {
                const allocatedAmount = Math.min(
                  getAmountToZero(diff),
                  item.amountDue
                );
                item.amount =
                  allocatedAmount >= 0
                    ? Math.abs(allocatedAmount)
                    : item.amountDue;
              } else {
                const allocatedAmount = Math.max(
                  getAmountToZero(diff),
                  item.amountDue
                );
                item.amount =
                  allocatedAmount < 0 ? allocatedAmount : item.amountDue;
              }
            } else {
              item.amount = item.amountDue;
            }
          }
          return item;
        });
        setData(newData);
      } else {
        let newData = data.map((item) => {
          if (item.postingId === e.dataItem.postingId) {
            item.amount = 0.0;
          }
          return item;
        });
        setData(newData);
      }
    }
    props.selectedData(newData);
  };

  return (
    <React.Fragment>
      <Grid
        style={{ maxHeight: "calc(100vh - 260px)" }}
        data={process(data, dataState)}
        dataItemKey={"postingId"}
        onItemChange={itemChange}
        onDataStateChange={dataStateChange}
        pageable
        {...dataState}
        sortable
        editField="inEdit"
        className="accounts-table office-credit-grid"
      >
        <Column
          field="date"
          title="Date"
          editable={false}
          headerClassName="ps-4"
          width="120px"
          cell={DateFormatCell}
        />
        <Column
          field="postingType"
          title="Type"
          editable={false}
          width="80px"
        />
        <Column
          field="reference"
          title="Reference"
          editable={false}
          width="100px"
        />
        <Column field="details" title="Details" editable={false} />
        <Column
          field="vatAmount"
          title="Vat"
          editable={false}
          cell={AmountFormatCell}
          headerCell={AmountNameHeader}
          headerClassName="pb-3"
          width="120px"
        />
        <Column
          field="grossAmount"
          title="Gross Amount"
          editable={false}
          cell={AmountFormatCell}
          headerCell={AmountNameHeader}
          headerClassName="pb-3"
          width="120px"
        />
        <Column
          field="amountPaid"
          title="Paid"
          editable={false}
          cell={AmountFormatCell}
          headerCell={AmountNameHeader}
          headerClassName="pb-3"
          width="120px"
        />
        <Column
          field="amountDue"
          title="Due"
          editable={false}
          cell={AmountFormatCell}
          headerCell={AmountNameHeader}
          headerClassName="pb-3"
          width="120px"
        />
        <Column
          field="add"
          title="Select"
          editor="boolean"
          className="text-center"
          width="80px"
        />

        <Column
          field="amount"
          title="Allocate"
          editor="numeric"
          cell={NumericCell}
          headerCell={AmountNameHeader}
          headerClassName="pb-3"
          width="140px"
        />
      </Grid>
      <div className="buttonstyle-3"></div>
    </React.Fragment>
  );
};
export default OfficeCreditGrid;
