import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import MattersCombobox from "../Postings/MattersComboBox.jsx";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Checkbox, RadioGroup } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { HandleGridBody } from "components/common/HandleGridBody.js";
import { useState } from "react";
import {
  AmountFormat,
  AmountFormatCell,
  DateFormatCell,
} from "components/common/formatter.js";
import { Button } from "@progress/kendo-react-buttons";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton.jsx";
import styles from "./BillRequests.module.scss";
import { handleApiError } from "Helper/index.js";
import * as api from "../../api/index.js";
import { AiOutlineFile } from "react-icons/ai";
import { Tooltip } from "@progress/kendo-react-tooltip";

import DocumentViewer from "components/common/DocumentViewer";
import BillPostingModal from "pages/Bills/BillRequestModal.jsx";

const PostingStatus = [
  {
    label: "Unposted",
    value: "false",
  },
  {
    label: "Posted",
    value: "true",
  },
  {
    label: "All",
    value: "",
  },
];

const NullableCell = (props) => {
  const value = props.dataItem[props.field];
  return (
    <td className="text-end">
      {value && value !== 0 ? AmountFormat(value) : ""}
    </td>
  );
};

const BillRequests = (props) => {
  const date = new Date();
  const dispatch = useDispatch();

  const [refresh, setRefresh] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date()?.toISOString().split("T")[0]);
  const [showBillingRequest, setShowBillingRequest] = useState(false);
  const [billingRequestPayload, setBillingRequestPayload] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [showDoc, setShowDoc] = useState(false);

  const [matterref, setmatterref] = useState(null);
  const [list, setLists] = useState({
    data: [],
    total: 20,
  });
  const [filterParameters, setFilterParmeters] = useState({
    postingStatus: "false",
  });
  const [valueFrom, setValueFrom] = useState(false);
  const [valueTo, setValueTo] = useState(false);
  const [dataState, setDataState] = useState({
    take: 200,
    skip: 0,
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  });
  const [fromDateSelector, setFromDateSelector] = useState(date);
  const [postingStatus, setPostingStatus] = useState("false");
  const [toDateSelector, setToDateSelector] = useState(date);
  const { yearStartDate } = useSelector((state) => state.company.company);

  const getBillRequestDetails = async (bill) => {
    try {
      const response = await api.getBillingRequestDetails(bill.billRequestId);
      setShowBillingRequest(true);
      setBillingRequestPayload({ ...bill, ...response.data.data });
    } catch (error) {}
  };
  const onChangeFromDateSelect = (event) => {
    setFromDateSelector(event.value);
    let fromDate = null;
    let date = new Date(event.value);
    if (event.value) {
      fromDate = date?.toISOString().split("T")[0];
    }
    setFromDate(fromDate);
    handleRefresh();
  };
  const onChangeToDateSelect = (event) => {
    setToDateSelector(event.value);
    let toDate = null;
    let date = new Date(event.value);
    if (event.value) {
      toDate = date?.toISOString().split("T")[0];
    }
    setToDate(toDate);
    handleRefresh();
  };

  useEffect(() => {
    if (yearStartDate !== undefined) {
      let yearsToStartDate = new Date(yearStartDate);
      setFromDateSelector(yearsToStartDate);
      setFromDate(yearsToStartDate?.toISOString().split("T")[0]);
    }
  }, [yearStartDate]);

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100, 200],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = useState(createState(0, 10));

  const changePostingStatus = (e) => {
    setPostingStatus(e.value);
    handleRefresh();
  };
  const onChangematter = (event) => {
    if (event.target.value !== null && event.target.value !== undefined) {
      setmatterref(event?.target?.value?.matterId);
    } else {
      setmatterref(null);
    }
    handleRefresh();
  };

  const filterParamatersBinding = () => {
    let parameters = {};
    if (matterref !== null) {
      parameters.matterId = matterref;
    }
    parameters.postingStatus = postingStatus;
    if (valueFrom) {
      parameters.fromDate = fromDate;
    }
    if (valueTo) {
      parameters.toDate = toDate;
    }
    setFilterParmeters(parameters);
  };

  useEffect(() => {
    filterParamatersBinding();
  }, [
    postingStatus,
    fromDateSelector,
    toDateSelector,
    matterref,
    valueFrom,
    valueTo,
  ]);

  const PostButtonCell = (props) => {
    const handleClick = () => {
      getBillRequestDetails(props.dataItem);
    };

    return (
      <td>
        <Button
          className="common-btn"
          style={{ minWidth: "50px", height: "25px" }}
          onClick={handleClick}
        >
          Post
        </Button>
      </td>
    );
  };

  const dataReceived = (list) => {
    if (list.data) {
      list.data = list.data.map((item) => ({
        ...item,
        grossAmount: (item.netAmount || 0) + (item.vatAmount || 0),
      }));

      setLists(list);
    }
  };
  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };
  const postingDoc = async (chitId) => {
    try {
      const { data } = await api.getPostingDocuments(chitId);
      setFileData(data.data);

      setShowDoc(true);
    } catch (error) {
      handleApiError(error);
    }
  };
  const DocumentCounterCell = (e) => {
    return (
      <td>
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          {e.dataItem.documentCount > 0 && (
            <>
              <Button
                onClick={() => postingDoc(e.dataItem.chitId)}
                className="border-0"
              >
                <AiOutlineFile color="blue" size={20} />
              </Button>
            </>
          )}
          <span>
            {!e.dataItem.documentCount || e.dataItem.documentCount === 0
              ? ""
              : e.dataItem.documentCount}
          </span>
        </div>
      </td>
    );
  };

  const StatusCell = (props) => {
    const { dataItem, rejectReason } = props;
    const { status } = dataItem;

    return (
      <td className="text-center align-middle">
        <Tooltip anchorElement="target" position="auto">
          <span
            className={`d-inline-flex  justify-content-center align-items-center py-2 px-3 fs-7
                        ${
                          status === "Rejected"
                            ? "bg-danger badge cursor-pointer text-white "
                            : "text-black"
                        }`}
            style={{ fontSize: "11px" }}
            title={status === "Rejected" && rejectReason ? rejectReason : ""}
          >
            {status}
          </span>
        </Tooltip>
      </td>
    );
  };

  return (
    <>
      <div className="page fluid-container">
        {showDoc && (
          <DocumentViewer data={fileData} onClose={() => setShowDoc(false)} />
        )}

        <div className="row">
          <div className="col-12 d-flex flex-wrap align-items-start justify-content-start ">
            <div className="d-flex flex-column me-4 mb-1">
              <Label className="mb-2 filter-font">Matter</Label>
              <MattersCombobox
                onChangematter={onChangematter}
                className="dropdown-width"
              />
            </div>
            <div className=" me-2  mt-1">
              <div className="nominal-form-to-parent-row">
                <div className="labels-containers">
                  <Label editorId={"dp"}>From</Label>
                  <div className="label-to">
                    <Label editorId="to">To</Label>
                  </div>
                </div>
              </div>
              <div className="nominal-form-to-row">
                <div
                  className="date-form-to-wrapper"
                  style={{ height: "40px" }}
                >
                  <div className="from">
                    <div className="d-flex align-items-center justify-content-center">
                      <Checkbox
                        value={valueFrom}
                        className="ms-2 me-1"
                        onChange={(e) => {
                          setValueFrom(e.value);
                          handleRefresh();
                        }}
                        label={""}
                      />
                      <DatePicker
                        id="from"
                        className="date-picker"
                        value={fromDateSelector || null}
                        onChange={onChangeFromDateSelect}
                        dateInput={CustomDateInput}
                        toggleButton={CalenderCustomToggleButton}
                        defaultValue={fromDateSelector || null}
                        placeholder="Select to date"
                      />
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="to">
                    <div className="d-flex align-items-center justify-content-center">
                      <Checkbox
                        value={valueTo}
                        className="ms-2 me-1"
                        onChange={(e) => {
                          setValueTo(e.value);
                          handleRefresh();
                        }}
                        label={""}
                      />
                      <DatePicker
                        id="to"
                        className="date-picker"
                        value={toDateSelector || null}
                        onChange={onChangeToDateSelect}
                        dateInput={CustomDateInput}
                        toggleButton={CalenderCustomToggleButton}
                        defaultValue={toDateSelector || null}
                        placeholder="Select to date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex flex-column radio me-4">
                <Label className="mb-2 filter-font">Type</Label>
                <RadioGroup
                  data={PostingStatus}
                  onChange={changePostingStatus}
                  defaultValue={PostingStatus[0].value}
                  className="d-flex flex-row mt-1 gap-3"
                />
              </div>
            </div>
            <div className="mt-4 me-auto">
              <Button className="common-btn" onClick={handleRefresh}>
                <span className="d-flex align-items-center">
                  <span className="k-icon k-i-reload me-2"></span>
                  Refresh
                </span>
              </Button>
            </div>
          </div>
        </div>

        <div className="row mt-3 " style={{ background: "" }}>
          <Grid
            {...dataState}
            data={list}
            onDataStateChange={dataStateChange}
            className={`fs-md ${styles["bill-request-grid"]}`}
            sortable={true}
            pageable={state.pageable}
            pageSize={state.pageSize}
          >
            <GridColumn field="matterRef" title="Matter" width="140px" />
            <GridColumn
              field="date"
              title="Date"
              cell={DateFormatCell}
              width="120px"
            />
            <GridColumn title="Details" field="details" width="150px" />
            <GridColumn title="F/E" field="feeEarnerRef" width="100px" />
            <GridColumn
              title="WIP"
              field="wipNetAmount"
              width="100px"
              cell={NullableCell}
              headerClassName="grid-header-center"
            />
            <GridColumn
              title="P/C"
              field="pcNetAmount"
              width="100px"
              cell={NullableCell}
              headerClassName="grid-header-center"
            />
            <GridColumn
              title="Disb"
              field="disbNetAmount"
              width="100px"
              cell={NullableCell}
              headerClassName="grid-header-center"
            />
            <GridColumn
              title="Exp"
              field="expNetAmount"
              width="100px"
              cell={NullableCell}
              headerClassName="grid-header-center"
            />
            <GridColumn
              title="VAT"
              field="vatAmount"
              width="100px"
              cell={NullableCell}
              headerClassName="grid-header-center"
            />
            <GridColumn
              field="grossAmount"
              title="Gross"
              cell={AmountFormatCell}
              width="140px"
              headerClassName="grid-header-center"
            />
            <GridColumn
              field="documentCount"
              title="Doc"
              width="80px"
              cell={DocumentCounterCell}
              headerClassName="grid-header-center"
            />
            <GridColumn
              field="status"
              title="Status"
              width="120px"
              headerClassName="grid-header-center"
              cell={(props) => (
                <StatusCell
                  {...props}
                  rejectReason={props.dataItem.rejectReason}
                />
              )}
            />
            <GridColumn field="" title="" cell={PostButtonCell} width="120px" />
          </Grid>
        </div>

        <HandleGridBody
          dataState={dataState}
          onDataReceived={dataReceived}
          currentComponent={"billingRequests"}
          parameters={filterParameters}
          refresh={refresh}
        />

        {showBillingRequest && (
          <BillPostingModal
            onClose={() => {
              setShowBillingRequest(false);
              setBillingRequestPayload();
              setRefresh(!refresh);
            }}
            billingRequestPayload={billingRequestPayload}
          />
        )}
      </div>
    </>
  );
};
export default BillRequests;
