import React, { useCallback, useRef, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { baseURL } from "api/axios.config";


const ReportDesigner = ({ serviceUrl, reportName }) => {

    const iframeRef = useRef(null);
    const { accessToken } = useOidcAccessToken(); // Retrieve the access token

    const onViewerInitializing = useCallback((e, args) => {
        args.reportViewerOptions.authenticationToken = accessToken;
    }, [accessToken]);


    useEffect(() => {
        if (iframeRef.current) {
            const iframe = iframeRef.current;
            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

            // Resolve the final service URL
            const resolvedServiceUrl =
                serviceUrl && serviceUrl.trim() !== ""
                    ? serviceUrl
                    : `${baseURL}/accounts/reporting/reportdesigner`;

            //HM - telerik_WebReportDesigner's property persistSession: false, is not working.
            //So removing local storage items to load provided reportname.

            localStorage.removeItem("RecentReports");
            localStorage.removeItem("PreviouslyOpenedReports");
            localStorage.removeItem("LastOpenedReport");


            // Clear and set up the iframe content
            iframeDoc.open();
            iframeDoc.write(`
                <!DOCTYPE html>
                <html>
                  <head></head>
                  <body></body>
                </html>
              `);
            iframeDoc.close();

            const loadScript = (src) => {
                return new Promise((resolve, reject) => {
                    const script = iframeDoc.createElement("script");
                    script.src = src;
                    script.onload = () => resolve();
                    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
                    iframeDoc.head.appendChild(script);
                });
            };

            // Inject the fetch override to pass the access token
            const injectFetchOverride = () => {
                const script = iframeDoc.createElement("script");
                script.type = "text/javascript";
                script.text = `
                  (function() {
                    const originalFetch = window.fetch;
                    window.fetch = function(url, args) {
                      if (!args) {
                        args = { headers: { Authorization: 'Bearer ${accessToken}' } };
                      } else if (!args.headers) {
                        args.headers = { Authorization: 'Bearer ${accessToken}' };
                      } else {
                        args.headers = { ...args.headers, Authorization: 'Bearer ${accessToken}' };
                      }
                      return originalFetch(url, args);
                    };
                  })();
                `;
                iframeDoc.head.appendChild(script);
            };

            // Load required scripts sequentially
            loadScript("https://ajax.googleapis.com/ajax/libs/jquery/3.7.0/jquery.min.js")
                .then(() => loadScript("https://kendo.cdn.telerik.com/2022.3.913/js/kendo.all.min.js"))
                .then(() => loadScript("/kendo-ui-license.js"))
                .then(() => loadScript("/TelerikReportDesigner/telerikReportViewer-18.3.24.1112.min.js"))
                .then(() => loadScript("/TelerikReportDesigner/webReportDesigner-18.3.24.1112.min.js"))
                .then(() => {

                    // Inject fetch override before initialising the Telerik Web Report Designer
                    injectFetchOverride();

                    // Create a container for the report designer
                    const container = iframeDoc.createElement("div");
                    container.id = "reportDesignerContainer";
                    iframeDoc.body.appendChild(container);

                    const $ = iframe.contentWindow.jQuery;
                    if (!$) {
                        console.error("ReportDesigner: jQuery not loaded in iframe.");
                        return;
                    }

                    $(container).telerik_WebReportDesigner({
                        toolboxArea: { layout: "list" },
                        persistSession: false,
                        serviceUrl: resolvedServiceUrl,
                        report: reportName,
                        viewerInitializing: onViewerInitializing,
                    });

                    // Hide the menu
                    setTimeout(() => {
                        const menu = iframeDoc.querySelector(".top-menu-area__menu");
                        if (menu) {
                            menu.style.display = "none";
                        }
                    }, 1000);


                })
                .catch((error) => {
                    console.error("ReportDesigner: Error loading scripts:", error);
                });



        }
    }, [accessToken, serviceUrl, reportName, onViewerInitializing]);



    return (
        <iframe
            ref={iframeRef}
            style={{ width: "100%", height: "calc(100% - 5px)", border: "none", borderRadius: "0 0 20px 20px" }}
            title="Telerik Report Designer"
        ></iframe>
    );
};

export default ReportDesigner;
