import React, { useState } from "react";
import ReportViewer from "./ReportViewerComponent";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Icon from "components/Global/icon";
import styles from "./Reports.module.scss";
import { SectionDividerFull } from "components/Contact/sectionDivider";
import { baseURL } from "api/axios.config";

const ClientListing = () => {


    const orderData = [
        {
            name: "Client Ref",
            value: "ClientRef",
        },
        {
            name: "Client Name",
            value: "ClientName",
        },
        {
            name: "Type",
            value: "Type",
        },
    ];

    const analyzeParameters = () => {
        setParameters({
            OrderBy: orderbyType?.value,
        });
        setShouldLoad(true);
    };

    const [orderbyType, setOrderByType] = useState({
        value: "ClientName",
        name: "Client Name",
    });

    const [shouldLoad, setShouldLoad] = useState(false);

    const [parameters, setParameters] = useState({
        OrderBy: orderbyType?.value,
    });

    const UpdateButton = (
        <Button className="common-btn" onClick={analyzeParameters}>
            Update
        </Button>
    );



    const orderByDropDown = (
        <div className="">
            <ComboBox
                className="ps-1"
                iconClassName="wa"
                icon={<Icon icon="arrow-down" />}
                data={orderData}
                required
                value={orderbyType}
                onChange={(e) => {
                    setOrderByType(e.value);
                }}
                dataItemKey={"value"}
                textField={"name"}
                placeholder="Order by"
                style={{ maxWidth: "325px", height: "44px" }}
            />
        </div>
    );

    const reportViewer = (
        <ReportViewer
            name="ClientListing.trdp"
            parameters={parameters}
            shouldLoad={shouldLoad}
            setShouldLoad={setShouldLoad}
            url={`${baseURL}/Accounts/Reporting/reports`}
        />
    );



    return (
        <div className="container-fluid w-100 p-4 pt-2 mt-4 ">
            <div className="row h-100">
                <div className="col" style={{ maxWidth: "300px" }}>
                    <div
                        className={`p-4 pt-4 ${styles[`reportBackground`]} ${styles[`height`]
                            }`}
                    >
                        <p className={`${styles[`heading`]} fs-lg`}>Filters</p>
                        <SectionDividerFull sectionName={""} />
                        <div className="d-flex flex-column pt-3">
                            <Label className="label mb-1">Order By</Label>
                            {orderByDropDown}
                        </div>
                        <SectionDividerFull sectionName={""} />
                        <div className="pt-3 float-end">{UpdateButton}</div>
                    </div>
                </div>
                <div className="col">
                    <div className={`row ${styles[`reportBackground`]} 1fr`}>
                        {reportViewer}
                    </div>
                </div>
            </div>
        </div>


    );
};

export default ClientListing;
