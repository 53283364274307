import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Title from "./TabTitle";
import {
  useLocation,
  useNavigate,
  useParams,
  useMatch,
} from "react-router-dom";
import { addTab, removeTab } from "actions/tabs.actions";
import { nameFunction } from "components/common/formatter";
import NominalBalances from "pages/Reports/NominalBalaces";
import SupplierBalances from "pages/Reports/SupplierBalance";
import MatterBalances from "pages/Reports/MatterBalances";
import { Tabs } from "components/Enums/TabEnums.ts";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import AccessRevokedModal from "components/common/AccessRevokedModal";
import ClientListing from "pages/Reports/ClientListing";
import BillingReport from "pages/Reports/BillingReports";
import Disbursements from "pages/Reports/DisbursementReport";
import BankRec from "pages/Reports/BankRec";

const ReportTabs = React.memo((props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useParams();
  const [tabs, setTabs] = React.useState([]);
  const location = useLocation();
  const tabsData = useSelector((state) => state.tabsData.reportTabs);
  const { allowed } = useAllowedLiscence(Tabs.REPORTS);

  const findContent = (item) => {
    switch (item) {
      case "supplierbalances":
        return "Supplier Balances";
      case "nominalbalances":
        return "Nominal Balances";
      case "matterbalances":
        return "Matter Balances";
      case "clientlisting":
        return "Client Listing";
      case "disbursements":
        return "Disbursements";
      case "billing":
        return "Billing";
      case "bankrec":
        return "Bank Rec";
    }
  };

  const urlDefiner = (e) => {
    if (params !== null) {
      let url = tabs[e]?.toLowerCase();
      if (url === undefined) {
        url = "";
      }
      navigate(`/reports/${url}`);
    }
  };

  const params = useMatch("reports/:data");
  useEffect(() => {
    if (params !== null) {
      let data = params?.params?.data;
      if (!tabs.includes(data?.toLowerCase())) {
        dispatch(addTab("reports", data?.toLowerCase()));
      } else {
        setSelected(tabs.indexOf(data?.toLowerCase()));
      }
    }
  }, [params]);

  useEffect(() => {
    if (tabsData) {
      setTabs(tabsData);
      let index = tabsData.indexOf(params?.params?.data.toLowerCase());
      setSelected(index === -1 ? 0 : index);
    }
  }, [tabsData]);

  const removeTabDispatcher = (tab) => {
    if (nameFunction(tab) === `${params?.params?.data}`) {
      let index = nameFunction(tabs[0]);
      navigate(`/reports/${index}`);
    }
    if (tabs.length === 1) {
      props.closeTab("Reports");
    }
    dispatch(removeTab("reports", tab));
  };

  const [selected, setSelected] = React.useState(-1);

  const handleSelect = (e) => {
    urlDefiner(e.selected);
  };

  return allowed ? (
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      keepTabsMounted={true}
      className="navigationTab"
    >
      {tabs.map((item, index) => {
        return (
          item && (
            <TabStripTab
              title={
                <Title
                  content={findContent(item)}
                  onTabRemove={removeTabDispatcher}
                  selected={findContent(tabs.at(selected))}
                  count={tabs.length}
                />
              }
              key={index}
            >
              <div>
                {item === "supplierbalances" ? <SupplierBalances /> : ""}
                {item === "nominalbalances" ? <NominalBalances /> : ""}
                {item === "matterbalances" ? <MatterBalances /> : ""}
                {item === "clientlisting" ? <ClientListing /> : ""}
                {item === "billing" ? <BillingReport /> : ""}
                {item === "disbursements" ? <Disbursements /> : ""}
                {item === "bankrec" ? <BankRec /> : ""}
              </div>
            </TabStripTab>
          )
        );
      })}
    </TabStrip>
  ) : (
    <AccessRevokedModal removeTab={() => props.closeTab()} />
  );
});

export default ReportTabs;
