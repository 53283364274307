import * as React from "react";
import * as ReactDOM from "react-dom";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Settings.module.scss";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useEffect } from "react";
import { getReportTemplates } from "api";
import * as api from "../../../api/index";
import { toast } from "react-toastify";
import {
    ActiveCell,
    EditCommandCell,
    ReportDesignerButton,
    SignatureCell,
} from "../GridComponents";
import EditBillingTemplate from "./EditBillingTemplate";
import ReportDesigner from "components/ReportDesigner";
import { Dialog } from "@progress/kendo-react-dialogs";

const BillingTemplates = ({ type }) => {
    const [addNewForm, setAddNewForm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [gridLoading, setGridLoading] = React.useState(false);
    const [editItem, setEditItem] = React.useState();
    const [editReport, setEditReport] = React.useState();
    const [data, setData] = React.useState([]);
    const [dataState] = React.useState({
        take: 10,
        skip: 0,
    });

    const enterEdit = (item) => {
        setAddNewForm(true);
        setEditItem(item);
    };

    const enterReport = (item) => {
        setEditReport(item);
    };

    const getBillingTemplatesData = async () => {
        try {
            setGridLoading(true);
            const response = await getReportTemplates(type);
            const updatedData = response.data?.data.map((template) => ({
                ...template,
                symbol: template.templateName[0],
            }));
            setData(updatedData);
        } catch {
        } finally {
            setGridLoading(false);
        }
    };

    useEffect(() => {
        getBillingTemplatesData();
    }, []);

    const closeform = () => {
        if (editItem) setEditItem();
        setAddNewForm(false);
    };
    const handleSubmit = async (e) => {
        try {
            setLoading(true);
            if (editItem) {
                await api.editReportTemplate(
                    {
                        active: true,
                        ...e,
                    },
                    type,
                    e.reportTemplateId
                );
                toast.success("Template Updated");
                setEditItem();
            } else {
                const dataResponse = await api.addReportTemplate(
                    {
                        active: true,
                        ...e,
                        reportType: 1,
                    },
                    type
                );
                toast.success("Template Added");
                enterReport(dataResponse?.data?.data);
            }
            setAddNewForm(false);
            getBillingTemplatesData();
        } catch (error) {
            toast.error("Error Occured");
        } finally {
            setLoading(false);
        }
    };

    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} enterEdit={enterEdit} />
    );

    const ReportDesignerEditCell = (props) => {
        return <ReportDesignerButton {...props} enterEdit={enterReport} />;
    };
    return (
        <React.Fragment>
            <>
                <div className="row">
                    <div className="col-lg-10 col-md-8"></div>
                    <Button
                        className={`common-btn col-lg-1 col-md-4 p-2 mt-5 ${styles[`button`]
                            }`}
                        style={{
                            minWidth: "120px",
                        }}
                        onClick={() => {
                            setAddNewForm(true);
                        }}
                    >
                        Add Template
                    </Button>
                </div>

                
                <div className={`row mx-auto mt-3 w-75`}>
                    <div className="settings-grid">
                        {gridLoading && <LoadingPanel />}
                        <Grid {...dataState} data={data}>
                            <Column
                                title=" "
                                field="symbol"
                                cell={SignatureCell}
                                width="80"
                            />
                            <Column
                                field="templateName"
                                title="Template Name"
                                width="300px"
                            />
                            <Column cell={ActiveCell} title="Active" width="80" />
                            <Column
                                cell={ReportDesignerEditCell}
                                title="Edit Template"
                                width="150"
                            />
                            <Column title=" " cell={MyEditCommandCell} width="80" />
                        </Grid>
                    </div>
                </div>
            </>
            {addNewForm && (
                <EditBillingTemplate
                    cancelEdit={closeform}
                    onSubmit={handleSubmit}
                    loading={loading}
                    item={editItem}
                    type={editItem ? "" : "addNew"}
                />
            )}
            {editReport && (
                <Dialog
                    title={"Report Designer"}
                    onClose={() => {
                        setEditReport(false);
                    }}
                    width={"100vw"}
                    height={"100vh"}
                    className="pt-1 ps-3 pe-3 pb-2"
                >
                    <ReportDesigner reportName={editReport?.reportResourceId + ".trbp"} />
                </Dialog>
            )}
        </React.Fragment>
    );
};

export const LoadingPanel = () => {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
        </div>
    );
    const gridContent = document && document.querySelector(".k-grid-content");
    return gridContent
        ? ReactDOM.createPortal(loadingPanel, gridContent)
        : loadingPanel;
};

export default BillingTemplates;
