import { Error } from "@progress/kendo-react-labels";
import { Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  MaskedTextBox as input,
  TextArea,
  Checkbox,
  NumericTextBox,
  Input,
} from "@progress/kendo-react-inputs";
import { useEffect, useState } from "react";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";

export const CLabel = ({ label }) => {
  return <Label className="label">{label}</Label>;
};

export const validateCounselFee = (fieldRenderProps) => {
  const { validationMessage, value, onChange, visited, ...others } =
    fieldRenderProps;
  return (
    <div className="d-flex">
      <div className="d-flex flex-column me-3">
        <Checkbox
          style={{
            width: "18px",
            height: "18px",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
          }}
          onChange={onChange}
          name="isCounselFee"
          value={value}
        />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
      <Label>Counsel Fee</Label>
    </div>
  );
};

export const validateAddReference = (fieldRenderProps) => {
  const { validationMessage, value, onChange, visited, ...others } =
    fieldRenderProps;
  return (
    <div className="d-flex flex-column">
      <CLabel label="Reference" />
      <div className="d-flex flex-column">
        <Input
          style={{ boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)" }}
          id="reference"
          value={value}
          onChange={onChange}
          placeholder={"Add Reference"}
        ></Input>
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    </div>
  );
};

export const validateDetails = (fieldRenderProps) => {
  const { validationMessage, onChange, value, visited, width } =
    fieldRenderProps;
  return (
    <div className="d-flex flex-column">
      <CLabel label="Details" />
      <div className="d-flex flex-column">
        <TextArea
          style={{
            width: width || "327px",
            height: "60px",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
          }}
          placeholder={"Enter Details"}
          id="details"
          name="details"
          onChange={onChange}
          type="text"
          value={value}
          rows={2}
        />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    </div>
  );
};

export const validateNotes = (fieldRenderProps) => {
  const { validationMessage, onChange, value, visited } = fieldRenderProps;
  return (
    <div className="d-flex flex-column">
      <CLabel label="Notes" />
      <div className="d-flex flex-column">
        <TextArea
          style={{
            width: "260px",
            height: "120px",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
          }}
          placeholder={"Enter Notes"}
          id="notes"
          name="notes"
          onChange={onChange}
          type="text"
          value={value}
          rows={2}
        />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    </div>
  );
};

export const validatePayee = (fieldRenderProps) => {
  const { validationMessage, value, onChange, visited, ...others } =
    fieldRenderProps;
  return (
    <div className="d-flex flex-column">
      <CLabel label="Payee" />
      <div className="d-flex flex-column">
        <Input
          style={{ boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)" }}
          id="payee"
          onChange={onChange}
          placeholder="Add Payee"
          value={value}
        ></Input>
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    </div>
  );
};

export const validatePayer = (fieldRenderProps) => {
  const { validationMessage, value, onChange, visited, ...others } =
    fieldRenderProps;
  return (
    <div className="d-flex flex-column">
      <CLabel label="Payer" />
      <div className="d-flex flex-column">
        <Input
          style={{ boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)" }}
          id="payer"
          onChange={onChange}
          placeholder="Add Payer"
          value={value}
        ></Input>
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    </div>
  );
};

export const ValidateNetAmount = (fieldRenderProps) => {
  const {
    validationMessage,
    value,
    onChange,
    visited,
    VatCode,
    formRenderProps,
    ...others
  } = fieldRenderProps;

  const handleNetAmountChange = (e) => {
    const netAmount = e.value;

    // Calculate VAT based on VatCode and NetAmount
    const vatRate = VatCode?.rate || 0; // Use 0 if VatCode is not provided or invalid
    const calculatedVat = netAmount * (vatRate / 100);

    // Call onChange with both NetAmount and VAT values
    onChange({
      value: netAmount, // Pass calculated VAT back to parent
    });
    formRenderProps.onChange("vatAmount", {
      value: calculatedVat,
    });
  };

  return (
    <div className="d-flex flex-column right-align">
      <CLabel label="Net Amount" />
      <div className="d-flex flex-column">
        <NumericTextBox
          style={{ boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)" }}
          id="netAmount"
          onChange={handleNetAmountChange}
          value={value}
          spinners={false}
          format="n2"
          placeholder="0.00"
          {...others}
        />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    </div>
  );
};

export const ValidateVATAmount = (fieldRenderProps) => {
  const { validationMessage, value, onChange, visited, ...others } =
    fieldRenderProps;

  // Calculate VAT value dynamically
  // const calculatedVAT =
  //   !fieldRenderProps?.VatCode?.rate || !fieldRenderProps.NetAmount
  //     ? 0.0
  //     : fieldRenderProps.NetAmount * (fieldRenderProps?.VatCode?.rate / 100);

  // const handleValueChange = (e) => {
  //   onChange(e.value); // Call onChange to update the form state
  // };

  return (
    <div className="d-flex flex-column right-align">
      <CLabel label="VAT Amount" />
      <div className="d-flex flex-column">
        <NumericTextBox
          {...others}
          onChange={onChange}
          style={{ boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)" }}
          placeholder="0.00"
          spinners={false}
          id="vatAmount"
          value={value} // Use provided value or calculated VAT
          format="n2"
        />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    </div>
  );
};

export const validateDate = (fieldRenderProps) => {
  const { validationMessage, value, onChange, visited } = fieldRenderProps;
  const date = new Date();
  return (
    <div className="d-flex flex-column">
      <CLabel label="Date" />
      <DatePicker
        style={{ boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)" }}
        className="datepicker"
        id="date-posting"
        value={value}
        onChange={onChange}
        dateInput={CustomDateInput}
        toggleButton={CalenderCustomToggleButton}
        defaultValue={date}
        format="dd/MM/yyyy"
      />
    </div>
  );
};
