import * as React from "react";
// import Modal from "../Modal";
import { Button } from "@progress/kendo-react-buttons";
import * as api from "../../api/index";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Form, FormElement } from "@progress/kendo-react-form";
import { useEffect } from "react";
import { DocumentsUpload } from "../Postings/DcoumentsUpload";
import { TextArea } from "@progress/kendo-react-inputs";
import { useUploadForm } from "components/fileUploadHook";
import MattersCombobox from "../Postings/MattersComboBox";
import Modalposting from "../Postings/PostingModal";
import { onFileUpload } from "Services/fileDataService";
import { clearFilesDispPayment } from "actions/files.action";
import MatterAccount from "../MatterLedger/MatterAccount";
import CONSTANT from "CONSTANT/Matter/ComboxType";
import useMediaQuery from "hooks/useMediaQuery";
import {
  clearMatterDisbRecords,
  clearMatterTimeRecords,
  getMatterDisbRecords,
  getMatterTimeRecords,
} from "actions/bills.action";
import { createposting } from "actions/postings.action";
import MattersTimeRecords from "./BillMatterPopupGrid";
import TimeGrid from "./BillTimeRecords";
import styles from "./Bills.module.scss";
import { Separator } from "assets/separator";
import DisbGrid from "./BillDisbursementGrid";
import MattersDisbRecords from "./BillDisbPopupGrid";
import BillProfitCostGrid from "./BillProfitCostGrid";
import BillFixedHeadersGrid from "./BillFixedFeePopup";
import { clearActiveFeeItems } from "actions/fixedFeeHeader.action";
import { getMattersBalances } from "actions/matters.action";
import SearchComboBox from "components/common/SearchComboBox";
import MatterDetails from "pages/MatterLedger/MatterDetails";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { TransactionPeriod } from "components/common/TransactionPeriod";
import LoadingScreen from "components/common/Loading";
import { getMatterDefaults } from "api";
import { toast } from "react-toastify";
import BillExpenseGrid from "./BillExpenseGrid";
import ReportViewer from "pages/Reports/ReportViewerComponent";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { baseURL } from "api/axios.config";
import ConfirmationDialog from "components/common/ConfirmationDialog";

const Bills = (props) => {
  const matches = useMediaQuery("(min-width: 1200px)");
  const dispatch = useDispatch();
  const date = new Date();
  const [details, setDetails] = useState("");
  const [matterName, setMatterName] = useState("");
  const [reference, setReference] = useState("*Bill No*");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [matterDefaults, setMatterDefaults] = useState(null);
  const [rejectBillingRequestDialog, setRejectBillingRequestDialog] =
    useState(false);
  const [rate, setRate] = useState("");
  const [initialData, setInitialData] = useState();
  const [totalAmount, setTotalAmount] = useState({
    totalAmount: 0,
    totalVat: 0,
  });
  const [nominalVatValue, setNominalVatValue] = useState({
    nominalRef: "",
    vatCode: "",
    feeEarnerRef: "",
  });
  const [showForm, setshowForm] = React.useState(false);
  const [showDisbForm, setshowDisbForm] = React.useState(false);
  const [showModal, setshowModal] = React.useState(false);
  const [dateselector, setdateselector] = useState(date);
  const [matterRef, setmatterref] = React.useState(null);
  const [resetGrd, setResetGrid] = useState(false);
  const [resetmatter, setresetmatter] = useState(false);
  const [modalvalue, setmodalvalue] = useState(false);
  const [billTimeData, setBillTimeData] = useState([]);
  const [feeEarner, setFeeEarner] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [billDisbData, setBillDisbData] = useState([]);
  const [reportPreviewData, setReportPreviewData] = useState(false);
  const [totalValues, setTotalValues] = useState({
    totalAmount: 0,
    totalVat: 0,
  });
  const [showExpenseGrid, setShowExpenseGrid] = useState(false);
  const [shoWProfitGrid, setShowProfitGrid] = useState(false);
  const [showTemplatesGrid, setShowTemplatesGrid] = useState(false);
  const [matterTimeRecords, setMatterTimeRecords] = useState([]);
  const [matterDisbRecords, setMatterDisbRecords] = useState([]);
  const [matterBalanceClear, setMatterBalanceClear] = useState(false);
  const [error, setError] = useState(undefined);
  const [feeearner, setfeeearnerData] = React.useState();
  const [shouldLoad, setShouldLoad] = useState(false);
  const { isSuccess, uploadForm, progress } = useUploadForm();

  const [filesData, setFilesData] = React.useState([]);
  const PRE_SELECTED_MATTER =
    props.component === "BillingRequest" && props.selectedMatter;
  const POST_BILLING_REQUEST =
    props.component === "BillRequest" && props.billingRequestPayload;
  const feeearnerdata = useSelector((state) => {
    return state.postingsdocs.feeearner.data;
  });
  const filesCurrentData = useSelector(
    (state) => state.filesDownload.billFiles
  );
  useEffect(() => {
    setTimeout(() => {
      setfeeearnerData(feeearnerdata.slice());
    }, 1000);
  }, [feeearnerdata]);
  useEffect(() => {
    calculateTotalValues();
  }, [matterDisbRecords, matterTimeRecords]);

  const onChangeFeeEarner = async (event) => {
    try {
      setFeeEarner(event?.value);
    } catch (error) {
      console.error(error);
    }
  };

  const onRejectBill = async () => {
    try {
      setIsSubmitting(true);
      await api.rejectBillingReqeust(
        props.billingRequestPayload?.billRequestId
      );
      props.onClose();
      toast.success("Bill Rejected");
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  const getMatterDefaultsData = async (id) => {
    try {
      const data = await getMatterDefaults(id);
      setMatterDefaults(data?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangematter = (e) => {
    let event = e.value;
    setMatterBalanceClear(false);
    if (event !== null) {
      setLoadingData(true);
      dispatch(getMattersBalances(event.matterId, "Bills"));
      getMatterDefaultsData(event?.matterId);
      setmatterref(event);
      setMatterName(event.matterRef);
      // dispatch(getMatterTimeRecords(event.matterId));
      if (event.feeEarnerRef) {
        setFeeEarner({
          representation: `${
            event?.feeEarnerRef?.userRef || event?.feeEarnerRef
          } - ${
            event?.feeEarnerRef?.displayname ||
            event?.feeearner?.displayname ||
            event?.feeEarnerName ||
            ""
          }`,
          userRef: event?.feeEarnerRef || event?.feeEarnerRef?.userRef,
        });
      }
      setTimeout(() => {
        setLoadingData(false);
      }, 1000);
    } else {
      setmatterref(null);
      setMatterBalanceClear(true);
      dispatch(clearMatterTimeRecords());
      dispatch(clearMatterDisbRecords());
    }
  };
  const onChangedateselect = (event) => {
    setdateselector(event.value);
  };

  const calculateTotalValues = () => {
    let amount = {
      totalAmount: 0,
      totalVat: 0,
    };
    if (matterTimeRecords[0] !== undefined) {
      matterTimeRecords.map((item) => {
        amount.totalAmount += item.value;
        amount.totalVat += item.billVat;
      });
    }
    if (matterDisbRecords[0] !== undefined) {
      matterDisbRecords.map((item) => {
        amount.totalAmount += item.netAmount;
        amount.totalVat += item.vatAmount;
      });
    }
    setTotalValues(amount);
  };

  const preparePostingData = (values) => {
    let amount = {
      totalAmount: 0,
      totalVat: 0,
    };

    if (matterTimeRecords[0] !== undefined) {
      matterTimeRecords.forEach((item) => {
        amount.totalAmount += item.value;
        amount.totalVat += item.billVat;
      });
    }

    if (matterDisbRecords[0] !== undefined) {
      matterDisbRecords.forEach((item) => {
        amount.totalAmount += item.netAmount;
        amount.totalVat += item.vatAmount;
      });
    }

    const data = [
      {
        postingType: "Bill",
        matterID: matterRef?.matterId,
        date: dateselector,
        reference: reference,
        details: details,
        netAmount: amount.totalAmount,
        vatAmount: amount.totalVat,
        feeEarnerRef: feeEarner?.userRef,
        transactionPeriod: 202201,
        fcCurrencyCode: "GBP",
      },
    ];

    if (billTimeData[0] !== undefined) {
      data[0].billTime = [];
      data[0].billProfitCost = [];
      billTimeData.forEach((item) => {
        if (item.type !== "ProfitCost") {
          item.nominalRef =
            nominalVatValue.nominalRef.nominalRef || item?.nominalRef;
          item.vatCode = nominalVatValue.vatCode.vatCodeId || item?.vatCode;
          item.feeEarnerRef =
            nominalVatValue.feeEarnerRef || item?.feeEarnerRef;
          data[0].billTime.push(item);
        } else {
          data[0].billProfitCost.push(item);
        }
      });
    }

    if (billDisbData[0] !== undefined) {
      data[0].billDisb = [];
      data[0].billExpenses = [];
      billDisbData.forEach((item) => {
        if (item.type === "Expense") {
          data[0].billExpenses.push(item);
        } else {
          data[0].billDisb.push(item);
        }
      });
    }

    if (filesCurrentData[0] !== undefined) {
      data[0].DocumentFiles = filesCurrentData.map((item) => item.fileID);
    }

    data[0].billDisb =
      data[0].billDisb?.length > 0 ? data[0].billDisb : undefined;
    data[0].billExpenses =
      data[0].billExpenses?.length > 0 ? data[0].billExpenses : undefined;

    return data;
  };

  const setStateFromApiResponse = (responseData, setStates) => {
    const data = responseData; // Extract first object since it's an array

    setStates.setMatterTimeRecords(
      data.billProfitCost
        ? data.billProfitCost.map((item) => ({
            ...item,
            type: "ProfitCost",
            value: item?.netAmount,
            billVat: item.vatAmount,
          }))
        : []
    );

    setStates.setMatterTimeRecords((prevData) => [
      ...prevData,
      ...(data.billTime
        ? data.billTime.map((item) => ({
            ...item,
            value: item?.netAmount,
            billVat: item.vatAmount,
          }))
        : []),
    ]);

    setStates.setBillTimeData((prevData) => [
      ...prevData,
      ...(data.billTime
        ? data.billTime.map((item) => ({
            ...item,
            value: item?.netAmount,
            billVat: item.vatAmount,
          }))
        : []),
    ]);

    setStates.setBillDisbData(data.billDisb ? [...data.billDisb] : []);
    setStates.setMatterDisbRecords(
      data.billExpenses
        ? data?.billExpenses?.map((item) => ({ ...item, type: "Expense" }))
        : []
    );

    setStates.setMatterDisbRecords((prevData) => [
      ...prevData,
      ...(data.billDisb ? data.billDisb.map((item) => ({ ...item })) : []),
    ]);

    setStates.setFilesCurrentData(
      data.DocumentFiles ? data.DocumentFiles.map((fileID) => ({ fileID })) : []
    );
  };

  const handleSubmit = async (values) => {
    const data = preparePostingData(values);

    if (!data) return; // Stop execution if validation fails
    if (
      data[0]?.matterID === undefined ||
      data[0]?.feeEarnerRef === undefined ||
      data[0]?.details === "" ||
      data[0]?.reference === ""
    ) {
      setError(true);
      return;
    }

    if (POST_BILLING_REQUEST)
      data[0].billRequestId = props.billingRequestPayload?.billRequestId;

    try {
      setIsSubmitting(true);
      let response;
      if (props.component === "BillingRequest") {
        response = await api.createBillingRequest(data[0]);
        toast.success(
          `Posted Bill Request - ${response?.data?.data?.billRequestId}`
        );
      } else {
        response = await api.createposting(data);
        toast.success(
          `Posted Successfully for ${response?.data?.data[0]?.postingType} - ${response?.data?.data[0]?.transactionNo}`
        );
      }
      setTotalValues({ totalAmount: 0, totalVat: 0 });
      setMatterDisbRecords([]);
      setInitialData({ feeEarnerRef: "" });
      reset();
      if (POST_BILLING_REQUEST) props.onClose();
      setMatterBalanceClear(true);
    } catch (error) {
      toast.error("Error occurred while posting");
    } finally {
      setIsSubmitting(false);
    }
  };

  const reset = () => {
    setDetails("");
    // setfeeearnerref(null);
    setReference("BillNo");
    setRate("");
    // setresetmatter(!resetmatter);
    setMatterTimeRecords([]);
    setResetGrid(!resetGrd);
    setError(false);
    calculateTotalValues();
    dispatch(clearMatterTimeRecords());
    dispatch(clearFilesDispPayment());
  };

  useEffect(() => {
    if (PRE_SELECTED_MATTER) {
      onChangematter({ value: props.selectedMatter });
    } else if (POST_BILLING_REQUEST) {
      onChangematter({ value: props.billingRequestPayload });

      setStateFromApiResponse(props.billingRequestPayload, {
        setMatterTimeRecords,
        setMatterDisbRecords,
        setBillTimeData,
        setBillDisbData,
        setFilesCurrentData: setFilesData,
      });
      setDetails(props.billingRequestPayload?.details);
    }
  }, []);

  const handleUpload = async (e) => {
    let data = onFileUpload(e);
    setFilesData([...filesData, data]);

    const parameters = {
      documentType: "Posting",
      matterId: matterRef?.matterId || "",
      nominalRef: nominalVatValue.nominalRef.nominalRef || "",
    };
    const dataChoosen = await uploadForm(data, e.target.files[0], parameters);
    data.fileID = dataChoosen.data.data.fileID;
  };

  const onFileRemove = (fileId) => {
    setFilesData((prev) => prev.filter((file) => file.fileID !== fileId));
  };

  return (
    <div
      className="container-fluid pe-0 ps-0 pt-0 pb-5"
      style={{
        height: props.isDialog ? "calc(100vh - 120px)" : "calc(100vh - 100px)",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      <div className="row h-100">
        <div
          className="ms-2 bgForm pt-4 d-flex justify-content-center align-item-center"
          style={{
            width: "350px",
            overflow: "auto",
            height: props.isDialog && "calc(100vh - 160px)",
            borderBottomLeftRadius: props.isDialog && "30px",
            maxHeight: props.isDialog
              ? "calc(100vh - 130px)"
              : "calc(100vh - 100px)",
          }}
        >
          <Form
            ignoreModified={true}
            onSubmit={handleSubmit}
            initialValues={initialData}
            key={JSON.stringify(initialData)}
            render={(formRenderProps) => {
              return (
                <div className="w-100 px-2">
                  <FormElement id="billForm" name="billForm">
                    <fieldset>
                      {formRenderProps.visited &&
                        formRenderProps.errors &&
                        formRenderProps.errors.VALIDATION_SUMMARY && (
                          <div className={"k-messagebox k-messagebox-error"}>
                            {formRenderProps.errors.VALIDATION_SUMMARY}
                          </div>
                        )}

                      <div className="mb-3">
                        <Label className="label">Matter Ref</Label>
                        <div
                          style={{
                            borderRadius: "10px",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <MattersCombobox
                            required={true}
                            onChangematter={onChangematter}
                            value={
                              PRE_SELECTED_MATTER || POST_BILLING_REQUEST
                                ? matterRef
                                : undefined
                            }
                            disabled={
                              PRE_SELECTED_MATTER || POST_BILLING_REQUEST
                            }
                            // reset={resetmatter}
                            style={{
                              width: "100%",
                            }}
                            // borderRadius={"10px"}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label className="label">Date</Label>
                        <div
                          style={{
                            borderRadius: "15px",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <DatePicker
                            className={`px-2 ${styles["datePicker-bill"]}`}
                            value={dateselector}
                            dateInput={CustomDateInput}
                            toggleButton={CalenderCustomToggleButton}
                            onChange={onChangedateselect}
                            defaultValue={dateselector}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label className="label">Reference</Label>
                        <Input
                          required={true}
                          style={{
                            borderRadius: "10px",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                            paddingRight: "2%",
                          }}
                          width={200}
                          value={reference}
                          placeholder="Reference"
                          onChange={(e) => {
                            setReference(e.target.value);
                          }}
                          className="px-3"
                        />
                      </div>

                      <div className="mb-3">
                        <SearchComboBox
                          name="feeEarnerRef"
                          cbType={CONSTANT.FE}
                          placeholder="Fee earner"
                          customCombo={true}
                          width="auto"
                          value={feeEarner}
                          boxShadow={true}
                          className="px-2"
                          onChangeFunction={onChangeFeeEarner}
                          // borderRadius="10px"
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="label">Details</Label>

                        <TextArea
                          required={true}
                          style={{
                            borderRadius: "10px",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                            height: "3.2rem",
                            padding: "2%",
                          }}
                          rows={10}
                          placeholder="Add Details"
                          value={details}
                          onChange={(e) => {
                            setDetails(e.target.value);
                          }}
                        />
                      </div>

                      <div className="col d-flex justify-content-end">
                        <DocumentsUpload
                          progress={progress}
                          filesData={filesData}
                          onFileRemove={onFileRemove}
                          onUpload={handleUpload}
                          type={"Bill"}
                          onError={() => {
                            const newFiles = filesData.splice(
                              filesData.length,
                              1
                            );
                            setFilesData([...newFiles]);
                          }}
                        />
                      </div>
                    </fieldset>
                  </FormElement>
                </div>
              );
            }}
          />
        </div>
        <div className="col ms-0" style={{ overflowX: "auto" }}>
          <div
            className="scroll-container"
            style={{
              minWidth: "900px",
              whiteSpace: "nowrap",
            }}
          >
            {props.component !== "BillingRequest" && <TransactionPeriod />}
            <div className="w-100 mt-3">
              <Modalposting
                value={modalvalue}
                reset={() => {
                  reset();
                }}
                closeModal={() => {
                  setmodalvalue(false);
                }}
                component={"Posting"}
              ></Modalposting>

              <div>
                <div className="ps-3">
                  <div
                    className="row align-items-stretch "
                    style={{ display: !matches ? "none" : "" }}
                  >
                    <div className="col-6">
                      <MatterDetails
                        matter={matterRef}
                        width={"100%"}
                        clear={matterBalanceClear}
                      />
                    </div>
                    <div className="col-6 ">
                      <MatterAccount
                        mt="0px"
                        float="right"
                        component={"Bills"}
                        matterId={matterName}
                        clear={matterBalanceClear}
                      />
                    </div>
                  </div>

                  <div
                    className="mt-4 billing-grid d-flex flex-column align-items-end"
                    style={{ height: !props.isDialog && "calc(100vh - 350px)" }}
                  >
                    <div>
                      <div className={`${styles["grid-container"]} p-3 me-2`}>
                        <TimeGrid
                          data={matterTimeRecords}
                          reset={resetGrd}
                          openForm={() => {
                            setshowForm(true);
                          }}
                          updateMattersData={(data) => {
                            setMatterTimeRecords(data);
                          }}
                          loading={loadingData}
                          openProfitForm={() => {
                            setShowProfitGrid(true);
                          }}
                          openProfitCostGrid={() => {
                            setShowProfitGrid(true);
                          }}
                          openTemplatesGrid={() => {
                            setShowTemplatesGrid(true);
                          }}
                          changeTotalAmount={(value) => {
                            setTotalAmount(value);
                          }}
                          changeBillTimeData={(item) => {
                            setBillTimeData(item);
                          }}
                          disabled={matterRef === null || loadingData}
                          loa
                        />
                        <DisbGrid
                          data={matterDisbRecords}
                          reset={resetGrd}
                          loading={loadingData}
                          openExpenseForm={() => setShowExpenseGrid(true)}
                          disabled={matterRef === null || loadingData}
                          openForm={() => {
                            setshowDisbForm(true);
                          }}
                          changeBillDisbData={(item) => {
                            setBillDisbData(item);
                          }}
                          changeTotalAmount={(value) => {
                            setTotalAmount(value);
                          }}
                        />
                      </div>

                      <div className="d-flex justify-content-between align-items-end right-align mt-4 me-3">
                        <div>
                          <div
                            className="d-flex gap-2 mb-1"
                            style={{
                              minHeight: "100px",
                            }}
                          >
                            <Button
                              className="common-btn bgBtnSecondary"
                              type=""
                            >
                              Setting
                            </Button>
                            <Button
                              className="common-btn bgBtnSecondary"
                              disabled={isSubmitting || !matterRef}
                              onClick={() =>
                                setReportPreviewData(preparePostingData()[0])
                              }
                            >
                              Preview
                            </Button>
                          </div>
                        </div>
                        <div>
                          <div
                            style={{ gap: "10px" }}
                            className={`d-flex py-0 justify-content-end ${styles["numeric-container"]}`}
                          >
                            <div
                              className={`ps-0 pt-1.5 ${styles["right-border"]} ${styles["bg-Color"]}`}
                              style={{ paddingTop: "5.5px" }}
                            >
                              <span className="fs-sm fw-semibold">
                                Bill Total
                              </span>
                            </div>
                            <div>
                              <NumericTextBox
                                width={150}
                                style={{
                                  textAlign: "right",
                                  border: "none",
                                  height: "35px",
                                }}
                                value={totalValues.totalAmount}
                                readOnly={true}
                                placeholder="0.00"
                                spinners={false}
                                format="n2"
                              />
                            </div>
                            <div className="mt-1">
                              <Separator color={"#E6E6E6"} />
                            </div>
                            <NumericTextBox
                              width={150}
                              style={{
                                textAlign: "right",
                                border: "none",
                                height: "35px",
                              }}
                              value={totalValues.totalVat}
                              readOnly={true}
                              placeholder="0.00"
                              spinners={false}
                              format="n2"
                            />
                          </div>
                          <div
                            className="buttonstyle me-3 mb-4"
                            style={{
                              minHeight: "100px",
                            }}
                          >
                            {POST_BILLING_REQUEST && (
                              <Button
                                onClick={() =>
                                  setRejectBillingRequestDialog(true)
                                }
                                disabled={isSubmitting}
                                className="common-btn bgBtnSecondary"
                                type=""
                              >
                                Reject
                              </Button>
                            )}
                            <Button
                              className="common-btn bgBtnSecondary"
                              type=""
                            >
                              Cancel
                            </Button>
                            <Button
                              className="common-btn"
                              type="submit"
                              disabled={isSubmitting}
                              form="billForm"
                            >
                              {props.component === "BillingRequest"
                                ? "Submit Request"
                                : "Post"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showDisbForm && (
                <MattersDisbRecords
                  cancelEdit={() => {
                    setshowDisbForm(false);
                  }}
                  matterId={matterRef?.matterId}
                  changeMatterDisbList={(data) => {
                    setMatterDisbRecords([...matterDisbRecords, ...data]);
                  }}
                />
              )}
              {showForm && (
                <MattersTimeRecords
                  changeMatterList={(data) => {
                    setMatterTimeRecords([...matterTimeRecords, ...data]);
                  }}
                  addNominalVatInfo={(item) => {
                    setNominalVatValue(item);
                  }}
                  cancelEdit={() => {
                    setshowForm(false);
                  }}
                  matterId={matterRef?.matterId}
                  defaultValues={matterDefaults}
                />
              )}
              {showTemplatesGrid && (
                <BillFixedHeadersGrid
                  changeMatterList={(data) => {
                    setMatterTimeRecords([...matterTimeRecords, ...data]);
                  }}
                  addNominalVatInfo={(item) => {
                    setNominalVatValue(item);
                  }}
                  cancelEdit={() => {
                    setShowTemplatesGrid(false);
                    dispatch(clearActiveFeeItems());
                  }}
                  matterId={matterRef?.matterId}
                  defaultValues={matterDefaults}
                />
              )}

              {shoWProfitGrid && (
                <BillProfitCostGrid
                  profitCostGrid={shoWProfitGrid}
                  defaultValues={matterDefaults}
                  changeMatterList={(data) => {
                    setMatterTimeRecords([...matterTimeRecords, ...data]);
                  }}
                  matterId={matterRef?.matterId}
                  cancelEdit={() => {
                    setShowProfitGrid(false);
                  }}
                />
              )}

              {showExpenseGrid && (
                <BillExpenseGrid
                  profitCostGrid={showExpenseGrid}
                  defaultValues={matterDefaults}
                  changeMatterList={(data) => {
                    setMatterDisbRecords([...matterDisbRecords, ...data]);
                  }}
                  matterId={matterRef?.matterId}
                  cancelEdit={() => {
                    setShowExpenseGrid(false);
                  }}
                />
              )}

              {reportPreviewData && (
                <Dialog
                  title={"Billing Preview"}
                  className="dialog-lg"
                  onClose={() => setReportPreviewData(false)}
                >
                  <div className="px-4 w-100 h-100 pb-2">
                    <ReportViewer
                      modalHeight={"100%"}
                      name="Templates\\BillTemplate.trdp"
                      matterID={props?.matterId}
                      url={`${baseURL}/Accounts/Reporting/reports`}
                      saveActivity={true}
                      parameters={{
                        BillDataSource: JSON.stringify(reportPreviewData),
                      }}
                      shouldLoad={shouldLoad}
                      setShouldLoad={setShouldLoad}
                    />
                  </div>

                  <DialogActionsBar>
                    <div className="dialog-footer w-100">
                      <Button
                        className="common-btn bgBtnSecondary me-2"
                        onClick={() => setReportPreviewData(false)}
                      >
                        Close
                      </Button>
                    </div>
                  </DialogActionsBar>
                </Dialog>
              )}

              <Modalposting
                value={showModal}
                reset={() => {
                  reset();
                }}
                closeModal={() => {
                  setshowModal(false);
                }}
                component={"Posting"}
              ></Modalposting>
            </div>

            {loadingData && <LoadingScreen />}

            {rejectBillingRequestDialog && (
              <ConfirmationDialog
                content={`Are you sure you want to reject billing request'?`}
                onClickYes={async () => {
                  await onRejectBill();
                  setRejectBillingRequestDialog(false);
                }}
                onClickNo={() => setRejectBillingRequestDialog(false)}
                onClose={() => setRejectBillingRequestDialog(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Bills;
