import React, { useEffect, useState } from "react";
import ReportViewer from "./ReportViewerComponent";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Icon from "components/Global/icon";
import styles from "./Reports.module.scss";
import { SectionDividerFull } from "components/Contact/sectionDivider";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { Button } from "@progress/kendo-react-buttons";
import { CustomDateInput } from "components/common/CustomDateInput";
import { baseURL } from "api/axios.config";

const SupplierBalances = () => {

    const DateFormat = (value) => {
        let date = new Date(value);
        return date.toISOString().split("T")[0];
    };

    const orderData = [
        {
            name: "Supplier Ref",
            value: "SupplierRef",
        },
        {
            name: "Name",
            value: "SupplierName",
        },
        {
            name: "Balance",
            value: "Balance",
        },
    ];

    let date = new Date();

    const analyzeParameters = () => {

        setReportType(
            agingCheck ? "SupplierBalancesAging.trdp" : "SupplierBalances.trdp"
        );

        setParameters({
            Type: type,
            ExcludeZero: excludeZero,
            ToDate: includeEndDate ? DateFormat(endDate) : undefined,
            OrderBy: orderbyType?.value,
        });
        setShouldLoad(true);
    };


    const [reportType, setReportType] = useState("SupplierBalances.trdp");
    const [type, setType] = useState("");
    const [shouldLoad, setShouldLoad] = useState(false);
    const [modalHeight, setModalHeight] = useState("55vh");


    const [orderbyType, setOrderByType] = useState({
        name: "Supplier Ref",
        value: "SupplierRef",
    });

    const [agingCheck, setAgingCheck] = useState(false);
    const [includeEndDate, setIncludeEndDate] = useState(false);
    const [excludeZero, setExcludeZero] = useState(true);

    const [endDate, setEndDate] = React.useState(date);
    const [parameters, setParameters] = useState({
        Type: type,
        ExcludeZero: excludeZero,
        ToDate: includeEndDate ? DateFormat(endDate) : undefined,
        OrderBy: orderbyType?.value,
    });

    useEffect(() => {
        const updateHeight = () => {
            setModalHeight(window.innerWidth >= 1200 ? "82vh" : "55vh");
        };

        updateHeight();
        window.addEventListener("resize", updateHeight);

        return () => window.removeEventListener("resize", updateHeight);
    }, []);

    const onChangeEndSelect = (event) => {
        if (includeEndDate) {
            setEndDate(event.value);
        }
    };

    const UpdateButton = (
        <Button className="common-btn" onClick={analyzeParameters}>
            Update
        </Button>
    );

    const toDatePicker = (
        <div className="d-flex align-items-center justify-content-center">
            <Checkbox
                value={includeEndDate}
                className="ms-2 me-2 mt-auto mb-auto"
                onChange={(e) => {
                    setIncludeEndDate(e.value);
                }}
                label={""}
            />
            <DatePicker
                id="to"
                className="date-picker"
                value={endDate || null}
                onChange={onChangeEndSelect}
                dateInput={CustomDateInput}
                toggleButton={CalenderCustomToggleButton}
                defaultValue={endDate || null}
                placeholder="Select to date"
            />
        </div>
    );

    const IncludeCheckbox = (
        <Checkbox
            // defaultChecked={false}
            value={excludeZero}
            className="checkbox-label"
            onChange={(e) => {
                setExcludeZero(e.value);
            }}
            label={"Exclude Zero Balances"}
        />
    );

    const AgingCheckbox = (
        <Checkbox
            // defaultChecked={false}
            className="checkbox-label"
            value={agingCheck}
            onChange={(e) => {
                setAgingCheck(e.value);
            }}
            label={"Show Aged Creditors"}
        />
    );

    const orderByDropDown = (
        <div className="">
            <ComboBox
                className=""
                iconClassName="wa"
                icon={<Icon icon="arrow-down" />}
                data={orderData}
                required
                value={orderbyType}
                onChange={(e) => {
                    setOrderByType(e.value);
                }}
                dataItemKey={"value"}
                textField={"name"}
                placeholder="Order by"
                style={{ maxWidth: "220px", height: "44px" }}
            />
        </div>
    );

    const reportViewer = (
        <ReportViewer
            name={reportType}
            parameters={parameters}
            shouldLoad={shouldLoad}
            setShouldLoad={setShouldLoad}
            modalHeight={modalHeight}
            url={`${baseURL}/Accounts/Reporting/reports`}
        />
    );

    const Filters = () => (
        <div className="px-4 p-xl-4 bg-red">
            <p className={`${styles.heading} d-none d-xl-block`}>Filters</p>
            <div className="d-flex flex-row flex-xl-column gap-1">
                <div className="col-6 col-xl-12 " >
                    <Label className="label mb-1">To</Label>
                    <div className="no-borders" style={{ maxWidth: "220px" }}>{toDatePicker}</div>
                </div>
                <div className="d-none d-xl-block"><SectionDividerFull sectionName={""} /> </div>
                <div className="col-6 col-xl-12">
                    <Label className="label mb-1 ms-1 me-2">Order By</Label>
                    {orderByDropDown}
                </div>
            </div>
            <div className="d-none d-xl-block"><SectionDividerFull sectionName={""} /> </div>
            <div className="row gx-4  g-xl-3 mt-2 align-items-center">
                <div className="col-auto">{IncludeCheckbox}</div>
                <div className="col-auto">{AgingCheckbox}</div>
            </div>
            <div className="d-none d-xl-block"><SectionDividerFull sectionName={""} /> </div>
            <div className="col text-end">{UpdateButton}</div>

        </div>
    );


    return (
        <div className={`container-fluid w-100 ${styles[`report-small-screen`]}`} >
            <div className="row d-flex gx-3 flex-column flex-xl-row m-2 ">
                <div className={`col-12 col-xl-3 overflow-scroll ${styles.maxWidthXL} ${styles.maxHeightXL}`}>
                    <div className={` mt-4 mb-4 ${styles[`filterBackground`]}  ${styles[`filter-height`]}`}>
                        <Filters />
                    </div>
                </div>
                <div className={`mb-4 col-12 col-xl-9`}>
                    <div className={`mt-4 mb-4 ${styles[`reportBackground`]}`}>
                        {reportViewer}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupplierBalances;