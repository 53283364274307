import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Settings.module.scss";
import { process } from "@progress/kendo-data-query";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { getAuthorizations, updateAuthoriations } from "api";
import { SignatureCell } from "../GridComponents";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { LoadingPanel } from "../BillingTemplates";

export const NumericCell = (props) => {
  return (
    <td className="right-align-full numeric-input-padding">
      {true ? (
        <NumericTextBox
          format={"n2"}
          className="mt-auto"
          min={0}
          value={props.dataItem[props.field]}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value,
                counter: props.dataItem.counter,
              });
            }
          }}
          spinners={false}
        />
      ) : (
        ""
      )}
    </td>
  );
};

const initialDataState = {
  sort: [{ field: "postingType", dir: "asc" }],
};
const AuthorizationsGrid = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [gridLoading, setGridLoading] = React.useState(false);

  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };

  const AmountNameHeader = (props) => {
    return (
      <div
        onClick={(e) => {
          props.onClick(e);
        }}
        style={{ textAlign: "center", cursor: "pointer" }}
      >
        {props.title}
        {props.children}
      </div>
    );
  };

  const handleSubmit = async () => {
    try {
      setGridLoading(true);
      await updateAuthoriations(data);
      toast.success("Updated Authorizations");
    } catch (error) {
      handleApiError(error);
      toast.error("Upexpected Error Occured");
    } finally {
      setGridLoading(false);
    }
    // setOpenForm(false);
  };

  const fetchData = async () => {
    try {
      setGridLoading(true);
      const data = await getAuthorizations();
      setData(
        data.data?.data.map((item) => ({
          ...item,
          inEdit: true,
          symbol: item.postingType,
        }))
      );
    } catch (error) {
    } finally {
      setGridLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const itemChange = (e) => {
    let newData = data.map((item) => {
      if (item.postingType === e.dataItem?.postingType) {
        item[e.field || ""] = e.value;
      }
      return item;
    });
    setData(newData);
  };

  return (
    <React.Fragment>
      <div className="settings-grid users-grid ">
        {gridLoading && <LoadingPanel />}
        <Grid
          style={{
            height: "calc(100vh - 260px)",
            padding: 0,
            boxShadow: "0px 0px 16px 0px #ffffff",
          }}
          data={process(data, dataState)}
          dataItemKey={"postingType"}
          onItemChange={itemChange}
          onDataStateChange={dataStateChange}
          //   pageable
          {...dataState}
          sortable
          editField="inEdit"
          className=""
        >
          <Column title=" " field="symbol" cell={SignatureCell} width="60" />
          <Column
            field="name"
            title="Posting Type"
            className="gridBoldField"
            editable={false}
            width="150px"
          />
          <Column
            field="allowChitRequest"
            title="Allows Chit"
            editor="boolean"
            className="text-center"
            width="80px"
          />
          <Column
            field="requiresAuthorisation"
            title="Auth Required"
            editor="boolean"
            className="text-center"
            width="80px"
          />
          <Column
            field="level1Amount"
            title="Amount Level 1"
            editor="numeric"
            cell={NumericCell}
            headerCell={AmountNameHeader}
            headerClassName="pb-3"
            width="140px"
          />
          <Column
            field="level2Amount"
            title="Amount Level 2"
            editor="numeric"
            cell={NumericCell}
            headerCell={AmountNameHeader}
            headerClassName="pb-3"
            width="140px"
          />
          <Column
            field="level3Amount"
            title="Amount Level 3"
            editor="numeric"
            cell={NumericCell}
            headerCell={AmountNameHeader}
            headerClassName="pb-3"
            width="140px"
          />
        </Grid>
      </div>
      <div className="pe-4 float-end mt-4"></div>
      <div
        className={`${styles["buttons-container"]} d-flex align-items-center justify-content-between col-12 p-3 `}
      >
        <div></div>
        <div className="d-flex align-items-center gap-2 me-2">
          <Button
            className={`common-btn bgBtnSecondary me-2`}
            onClick={(e) => {
              navigate("/settings");
            }}
          >
            Cancel
          </Button>
          <Button
            className={`common-btn`}
            disabled={gridLoading}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AuthorizationsGrid;
