import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { getChartOfAccounts } from "api/accountsAPI";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Settings/Settings.module.scss";
import AddEditChartAccounts from "./AddEditChartAccounts";
import {
    ActiveCell,
    SignatureCell,
    EditCommandCell,
} from "../Settings/GridComponents";
import { Dialog } from "@progress/kendo-react-dialogs";

const initialDataState = {
    sort: [{ field: "name", dir: "asc" }],
    take: 10,
    skip: 0,
};

const ChartOfAccounts = () => {

    const [dataState, setDataState] = useState(initialDataState);
    const [accounts, setAccounts] = useState([]);
    const [editData, setEditData] = useState(null);
    const [showAddEdit, setShowAddEdit] = useState(false);    
    const [showDialog, setShowDialog] = useState(false);

    const handleDataStateChange = (e) => {
        setDataState(e.dataState);
    };

    useEffect(() => {
        fetchAccounts();
    }, []);

    const fetchAccounts = async () => {
        try {
            const response = await getChartOfAccounts();
            const accountsWithFirstLetter = (response?.data?.data || []).map(
                (account) => ({
                    ...account,
                    symbol: account.name ? account.name.charAt(0) : "",
                })
            );
            setAccounts(accountsWithFirstLetter);
        } catch (error) {
            console.error("Error fetching accounts:", error);
        }
    };

    const TypeCell = (props) => {
        const formatType = (type) => {
            if (!type) return "";
            return type.replace(/([A-Z])/g, " $1").trim();
        };
        return (
            <td>
                <span>{formatType(props.dataItem.type)}</span>
            </td>
        );
    };

    const handleAddNew = (dataItem) => {
        setShowAddEdit(true);
        setEditData(dataItem || null);
        setShowDialog(true);
    };
    const handleCloseDialog = () => {
        setShowDialog(false);
        setEditData(null);
    };

    const MyEditCommandCell = (props) => (
        <EditCommandCell
            {...props}
            enterEdit={() => handleAddNew(props.dataItem)}
        />
    );

    return (
        <>
            <div className="row">
                <div className="col-lg-10 col-md-8"></div>
                <Button
                    className={`common-btn col-lg-1 col-md-4 p-2 mt-5 ${styles[`button`]
                        }`}
                    onClick={() => handleAddNew("")}
                >
                    Add Chart<br></br>
                    of Accounts
                </Button>
            </div>

            <div className="settings-grid row mx-auto my-4 container">
                <Grid
                    data={process(accounts, dataState)}
                    {...dataState}
                    onDataStateChange={handleDataStateChange}
                >
                    <Column field="symbol" title=" " cell={SignatureCell} width="80" />
                    <Column field="name" title="Name" width="150px" />
                    <Column field="type" title="Type" cell={TypeCell} width="150px" />
                    <Column
                        field="active"
                        title="Active"
                        width="80px"
                        cell={ActiveCell}
                    />
                    <Column title=" " cell={MyEditCommandCell} width="60px" />
                </Grid>
            </div>

            {showDialog && (
                <Dialog
                    title={editData ? "Edit Chart of Accounts" : "Add Chart of Accounts"}
                    onClose={handleCloseDialog}
                    className="dialog-xxl"
                >
                    <AddEditChartAccounts
                        onClose={handleCloseDialog}
                        editData={editData}
                        onSave={() => {
                            handleCloseDialog();
                            fetchAccounts();
                        }}
                    />
                </Dialog>
            )}


        </>
    );
};

export default ChartOfAccounts;
