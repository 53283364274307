import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { AmountFormatCell, DateFormatCell } from "components/common/formatter";
import { useState, useEffect } from "react";
import { Separator } from "assets/separator";
import styles from "./Bills.module.scss";
import { NameTextCell, TextHeader } from "components/common/GridComponents";
import { CrossIcon } from "components/tabs/TabTitle";
import { IoIosAddCircle } from "react-icons/io";

const TimeGrid = (props) => {
  const [data, setData] = React.useState([]);
  const [totalValues, setTotalValues] = useState({
    totalAmount: 0,
    totalVat: 0,
  });
  const calculateTotalAmount = () => {
    if (data[0] !== undefined) {
      let amount = {
        totalAmount: 0,
        totalVat: 0,
      };
      data.map((item) => {
        amount.totalAmount += item.value;
        amount.totalVat += item.billVat;
      });
      setTotalValues(amount);
    } else {
      setTotalValues({
        totalAmount: 0,
        totalVat: 0,
      });
    }
  };
  const mattersDataUpdate = (data) => {
    props.updateMattersData(data);
  };
  const AmountNameHeader = (props) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginTop: 0,
          marginBottom: "8px",
        }}
      >
        {" "}
        <span>{props.title}</span>
      </div>
    );
  };
  const MyEditCommandCell = (props) => {
    return (
      <td>
        <CrossIcon
          onClick={(e) => {
            console.log(props.dataItem, data);
            const updatedData = [...data].filter(
              (record) => record.timeId !== props.dataItem.timeId
            );
            setData([...updatedData]);
            mattersDataUpdate(updatedData);
            calculateTotalAmount();
          }}
        />
      </td>
    );
  };

  const calculateTotalTimeBills = () => {
    let finalData = [];
    console.log(data)
    data.map((item) => {
      let datatemp;
      if (item.type !== "ProfitCost") {
        datatemp = {
          timeId: item.timeId,
          netAmount: item.value,
          vatAmount: item.billVat,
          vatCode: item.vatCode,
          feeEarnerRef: item.feeEarnerRef,
          nominalRef: item.nominalRef,
        };
      } else if (item.type === "ProfitCost") {
        datatemp = {
          date: item.date,
          details: item.details,
          netAmount: item.value,
          vatAmount: item.billVat,
          vatCode: item.vatCode,
          feeEarnerRef: item.feeEarnerRef,
          nominalRef: item.nominalRef,
          type: item.type,
        };
      }
      finalData.push(datatemp);
    });
    props.changeBillTimeData(finalData);
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  useEffect(() => {
    setData([]);
  }, [props.reset]);
  useEffect(() => {
    calculateTotalAmount();
    calculateTotalTimeBills();
    props.changeTotalAmount(totalValues);
  }, [data]);

  return (
    <div style={{ marginTop: "5px" }}>
      <div style={{ marginBottom: "15px" }}>
        <Button
          className={`k-button fw-semibold fs-sm bgBtnSecondary me-2  ${styles["custom-button"]}`}
          onClick={() => {
            props.openForm();
          }}
          disabled={props.disabled}
        >
          <span className="me-1 pb-1">
            <IoIosAddCircle
              style={{
                marginBottom: "2px",
              }}
            />
          </span>
          <span className="pt-1">Select Time</span>
        </Button>
        <Button
          className={`k-button fw-semibold fs-sm bgBtnSecondary me-2  ${styles["custom-button"]}`}
          disabled={props.disabled}
          onClick={() => {
            props.openProfitForm();
          }}
        >
          <span className="me-1 pb-1">
            <IoIosAddCircle
              style={{
                marginBottom: "2px",
              }}
            />
          </span>
          <span className="pt-1"> Add Profit Cost</span>
        </Button>
        <Button
          className={`k-button fw-semibold fs-sm bgBtnSecondary me-2  ${styles["custom-button"]}`}
          disabled={props.disabled}
          onClick={() => {
            props.openTemplatesGrid();
          }}
        >
          <span className="me-1 pb-1">
            <IoIosAddCircle
              style={{
                marginBottom: "2px",
              }}
            />
          </span>
          <span className="pt-1"> Select Fixed Fee</span>
        </Button>
      </div>
      <Grid
        data={data}
        dataItemKey={"timeId"}
        editField="inEdit"
        style={{ maxHeight: "140px", overflow: "auto" }}
        className="grid-smallfont grid-header-sm"
      >
        <Column
          field="date"
          title="Date"
          editable={false}
          cell={DateFormatCell}
          width={"140px"}
          headerCell={TextHeader}
        />
        <Column
          field="details"
          title="Details"
          editable={false}
          cell={NameTextCell}
        />
        <Column
          field="value"
          title="Value"
          editable={false}
          width={120}
          cell={AmountFormatCell}
          headerCell={AmountNameHeader}
        />
        <Column
          field="value"
          title="Bill Net"
          editable={false}
          width={120}
          cell={AmountFormatCell}
          headerCell={AmountNameHeader}
        />
        <Column
          field="billVat"
          title="Bill Vat"
          editable={false}
          width={120}
          cell={AmountFormatCell}
          headerCell={AmountNameHeader}
        />
        <Column cell={MyEditCommandCell} width="60" />
      </Grid>
      <div className="d-flex justify-content-end right-align mt-3">
        <div
          style={{ gap: "10px" }}
          className={`d-flex pe-2 py-0 justify-content-end`}
        >
          <div
            style={{
              width: "150px",
            }}
          >
            <div className={`${styles["font-small"]}`}>Total Net</div>
            <div className={`${styles["font-amount"]}`}>
              {totalValues?.totalAmount?.toFixed(2)}
            </div>
          </div>
          <div
            style={{
              width: "100px",
            }}
          >
            <div className={`${styles["font-small"]}`}>Total VAT</div>
            <div className={`${styles["font-amount"]}`}>
              {totalValues?.totalVat?.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeGrid;
