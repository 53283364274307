import React, { useState, useEffect } from "react";
import {
  MaskedTextBox as input,
  TextArea,
  NumericTextBox,
  Input,
} from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { useSelector, useDispatch } from "react-redux";
import MattersCombobox from "../Postings/MattersComboBox";
import Modalposting from "../Postings/PostingModal";
import { DocumentsUpload } from "../Postings/DcoumentsUpload";
import { clearFile, clearFiles } from "actions/files.action";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { createChitRequest } from "actions/chitRequest.action";
import { useUploadForm } from "components/fileUploadHook";
import { onFileUpload } from "Services/fileDataService";
import { usePreComponentCalls } from "components/layout/PreComponentLoad";
import {
  prepareFeeearnerData,
  preparePostingTypeData,
} from "components/common/ComboRepresentationFunctions";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import MatterDetails from "pages/MatterLedger/MatterDetails";
import { CustomDateInput } from "components/common/CustomDateInput";
import {
  ComboBoxComponent,
  ChitRequestSkeleton,
} from "pages/ChitRequest/ChitRequestComponents";
import styles from "pages/ChitRequest/ChitRequest.module.scss";
import { getMatterAllInfo } from "api";
import { editPosting } from "api/accountsAPI";
import { toast } from "react-toastify";

const EditPosting = (props) => {
  const { postingInfo } = props;

  const [list, setlist] = useState([]);
  const [validator, setvalidator] = useState({});
  const [branchesdata, setbranchesdata] = useState([]);
  const { handleCalls } = usePreComponentCalls();
  const [loading, setLoading] = useState(false);
  const [resetDocs, setresetDocs] = useState(false);

  const postingdata = useSelector((state) => state.chitRequest.postingstypes);
  const postingOfficedata = useSelector(
    (state) => state.postingsdocs.postingsOfficetypes
  );
  const postingClientsdata = useSelector(
    (state) => state.postingsdocs.postingsClienttypes
  );

  useEffect(() => {
    handleCalls("Chit Request");
  }, []);

  useEffect(() => {
    let listtoshow = [];
    const validator = {};
    setTimeout(() => {
      let dataToCheck = [];
      if (props.name === undefined) {
        dataToCheck = postingdata;
      } else if (props.name === "Office Postings") {
        dataToCheck = postingOfficedata;
      } else if (props.name === "Client Postings") {
        dataToCheck = postingClientsdata;
      }
      dataToCheck.data[0].displayFields.map((item) => {
        listtoshow.push(item.name);
        validator[item.name] = item.displayName;
      });
      setlist(listtoshow);
      setvalidator(validator);
    }, 10);
  }, [postingdata, postingClientsdata, postingOfficedata]);

  const [item, setitem] = useState([
    { code: "0", name: "Loading", displayFields: [] },
  ]);
  const date = new Date();
  const [type, settype] = useState("");
  const [vatvalue, setvatvalue] = useState();
  const [matterref, setmatterref] = useState(postingInfo.matterID || null);
  const [feeearnerref, setfeeearnerref] = useState();
  const [matter, setMatter] = useState("");
  const [branch, setbranch] = useState({ branchId: "" });
  const [nominal, setnominal] = useState({ nominalRef: "" });
  const [suppliervalue, setsuppliervalue] = useState({ supplierRef: "" });
  const [errorModal, setErrorModal] = useState(false);
  const [officevalue, setofficevalue] = useState(postingInfo?.officeBank || "");
  const [clientvalue, setclientvalue] = useState(postingInfo?.clientBank || "");
  const [ddavalue, setddavalue] = useState(postingInfo?.ddaBank || "");
  const [reference, setreference] = useState(postingInfo?.reference || "");
  const [payee, setpayee] = useState(postingInfo?.payee || "");
  const [payer, setpayer] = useState(postingInfo?.payer || "");
  const [vatamount, setvatamount] = useState(postingInfo?.vatAmount || 0);
  const [netamount, setnetamount] = useState(postingInfo?.netAmount || 0);
  const [grossamount, setgrossamount] = useState("");
  const [resetmatter, setresetmatter] = useState(false);
  const [details, setdetails] = useState(postingInfo?.details || "");
  const [dateselector, setdateselector] = useState(
    new Date(postingInfo?.date) || date
  );
  const [counselfee, setcounselfee] = useState(
    postingInfo?.isCounselFee || false
  );
  const [statepreserver, setstatepreserver] = useState(false);
  const [modalvalue, setmodalvalue] = useState(false);
  const [validation_message, setvalidation_message] = useState(false);
  const [documentUpload, setDocumentsUpload] = useState(false);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const onChange = (event) => {
    setValue(event.value);
  };
  const onChangesupplier = (event) => {
    if (event !== undefined && event !== null) {
      setsuppliervalue(event.value);
    }
  };
  const onChangeofficebank = (event) => {
    if (event !== undefined && event !== null) {
      setofficevalue(event.value);
    }
  };
  const onChangeclientbank = (event) => {
    if (event !== undefined && event !== null) {
      setclientvalue(event.value);
    }
  };
  const onChangeddabank = (event) => {
    if (event !== undefined && event !== null) {
      setddavalue(event.value);
    }
  };
  useEffect(() => {
    getMatterInfo();
    feeEarnerValue();
  }, []);
  // set Initial feeEarner value
  const feeEarnerValue = () => {
    if (postingInfo.feeEarnerRef !== null) {
      const feeEarner = feeearnerdata.find(
        (item) => item.userRef === postingInfo.feeEarnerRef
      );
      setfeeearnerref(feeEarner);
    }
    return null;
  };

  const getMatterInfo = async () => {
    const matterInfo = await getMatterAllInfo(postingInfo?.matterID);
    setMatter(matterInfo.data.data);
  };

  // const mattersdata = useSelector((state) => {
  //   return state.postingsdocs.mattersList;
  // });

  const feeearnerdata = useSelector((state) => {
    return state.postingsdocs.feeearner.data;
  });
  const suppliersdata = useSelector((state) => {
    return state.suppliersList.supplierrefs.data.map((item) => {
      item.representation = item.supplierRef + " - " + item.supplierName;
    });
  });
  const officeapidata = useSelector((state) => {
    return state.nominalList.officeList.data.map((item) => {
      item.representation = item.nominalRef + " - " + item?.nominalName;
      return item;
    });
  });
  const clientapidata = useSelector((state) => {
    return state.nominalList.clientList.data.map((item) => {
      item.representation = item.nominalRef + " - " + item?.nominalName;
      return item;
    });
  });
  const ddaapidata = useSelector((state) => {
    return state.nominalList.ddaList.data.map((item) => {
      item.representation = item.nominalRef + " - " + item?.nominalName;
      return item;
    });
  });
  const result = useSelector((state) => {
    return state.postingsdocs;
  });
  const brancheslist = useSelector((state) => {
    return state.branchesnames.branchesList;
  });
  const nominallist = useSelector((state) => {
    let array = [];
    let list = state.nominalList.activenominals.data.map((item) => {
      if (item.subType === "ProfitAndLoss_Expense") {
        const templist = item;
        templist.representation = item.nominalRef + "  -  " + item.nominalName;
        array.push(item);
        return templist;
      }
    });
    return array;
  });
  const activevatsdata = useSelector((state) => {
    let list = state.vatcodes.activevats.data.map((item) => {
      const templist = item;
      templist.representation =
        item.vatCodeId + "  -  " + item.details + "  -  " + item.rate + "%";

      return templist;
    });
    return list;
  });

  useEffect(() => {
    if (statepreserver === false) {
      setTimeout(() => {
        setvatsData(activevatsdata.slice());
        setfeeearnerData(prepareFeeearnerData(feeearnerdata.slice()));
        setbranchesdata(brancheslist.slice());
        setsuppliersdata(suppliersdata.slice());
        setnominaldata(nominallist.slice());
        setofficedata(officeapidata.slice());
        setclientdata(clientapidata.slice());
        setddadata(ddaapidata.slice());
        setstatepreserver(true);
      }, 2000);
    }
  }, [
    feeearnerdata,
    activevatsdata,
    suppliersdata,
    officeapidata,
    clientapidata,
    ddaapidata,
  ]);

  const [vatsdata, setvatsData] = useState();
  const [feeearner, setfeeearnerData] = useState();
  const [suppliers, setsuppliersdata] = useState();
  const [nominaldata, setnominaldata] = useState();
  const [officedata, setofficedata] = useState();
  const [clientdata, setclientdata] = useState();
  const [ddadata, setddadata] = useState();

  const filterData = (filter) => {
    const datas = activevatsdata.slice();
    return filterBy(datas, filter);
  };

  const filterChange = (event) => {
    setvatsData(filterData(event.filter));
  };
  const filtersupplier = (filter) => {
    const data = suppliersdata.slice();
    return filterBy(data, filter);
  };
  const filterChangesupplier = (event) => {
    setsuppliersdata(filtersupplier(event.filter));
  };

  const filterofficebank = (filter) => {
    const data = officeapidata.slice();
    return filterBy(data, filter);
  };
  const filterChangeofficebank = (event) => {
    setofficedata(filterofficebank(event.filter));
  };
  const filterclientbank = (filter) => {
    const data = clientapidata.slice();
    return filterBy(data, filter);
  };
  const filterChangeclientbank = (event) => {
    setofficedata(filterclientbank(event.filter));
  };
  const filterddabank = (filter) => {
    const data = ddaapidata.slice();
    return filterBy(data, filter);
  };
  const filterChangeddabank = (event) => {
    setofficedata(filterddabank(event.filter));
  };
  const filterfeeearnerData = (filter) => {
    const data = feeearnerdata.slice();
    return filterBy(data, filter);
  };
  const filterfeeearner = (event) => {
    setfeeearnerData(filterfeeearnerData(event.filter));
  };
  const filternominalData = (filter) => {
    const data = nominallist.slice();
    return filterBy(data, filter);
  };
  const filternominal = (event) => {
    setnominaldata(filternominalData(event.filter));
  };
  const filterbranchesData = (filter) => {
    const data = brancheslist.slice();
    return filterBy(data, filter);
  };
  const filterbranch = (event) => {
    setbranchesdata(filterbranchesData(event.filter));
  };

  const onChangevat = (event) => {
    setvatvalue(event.value);
  };
  const calculatevatamount = () => {
    if (vatvalue !== undefined && vatvalue !== null) {
      const vatamount = netamount * (vatvalue.rate / 100);
      setvatamount(vatamount);
    }
  };
  useEffect(() => {
    calculatevatamount();
  }, [vatvalue, netamount]);
  const onChangedateselect = (event) => {
    setdateselector(event.value);
  };

  useEffect(() => {
    if (props.name === undefined) {
      if (postingdata.data.length !== 1) {
        setitem(preparePostingTypeData(postingdata.data));
      }
    } else if (props.name === "Office Postings") {
      if (postingOfficedata.data.length !== 1) {
        setitem(postingOfficedata.data);
      }
    } else if (props.name === "Client Postings") {
      if (postingClientsdata.data.length !== 1) {
        setitem(postingClientsdata.data);
      }
    }
  }, [postingdata, postingOfficedata, postingClientsdata]);

  const onChangefeeearner = (event) => {
    setfeeearnerref(event.value);
  };
  const onChangebranch = (event) => {
    setbranch(event.value);
  };
  const onChangenominal = (event) => {
    setnominal(event.value);
  };
  const onChangecounselfee = (event) => {
    setcounselfee(event.value);
  };
  const filesCurrentData = useSelector((state) => state.filesDownload.files);
  const filesCurrentOfficeData = useSelector(
    (state) => state.filesDownload.officePostingFiles
  );
  const filesCurrentClientData = useSelector(
    (state) => state.filesDownload.clientPostingFiles
  );
  const filesChitRequest = useSelector(
    (state) => state.filesDownload.chitRequestFiles
  );

  const closeDialog = () => {
    props.cancelViewPosting();
    props.cancelEdit();
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    // if (props.name === undefined) {
    //   if (filesChitRequest[0] !== undefined) {
    //     const docsId = [];
    //     filesChitRequest.map((item) => {
    //       docsId.push(item.fileID);
    //     });
    // data[0].DocumentFiles = docsId;
    //   }
    // }
    // if (IS_MODAL) {
    // data[0].matterID = props.matter?.matterId;
    // }
    // dispatch(createposting(data));

    const data = {
      date: dateselector,
      reference: reference,
      netAmount: netamount,
      vatAmount: vatamount,
      vatCode: postingInfo?.vatCode,
      vatRate: postingInfo?.vatRate,
      feeEarnerRef: feeearnerref?.userRef,
      details: details,
      transactionPeriod: postingInfo?.transactionPeriod,
      isCounselFee: counselfee,
      payer: payer,
      payee: payee,
      officeBank: officevalue,
      clientBank: clientvalue || "",
      ddaBank: ddavalue || "",
      costCentre: postingInfo?.costCentre,
      documentFiles: postingInfo?.documentFiles,
      //   postedDocuments: [
      //     {
      //       documentId: 0,
      //       active: true,
      //     },
      //   ],
    };
    try {
      await editPosting(props?.transNo, data);
      closeDialog();
      props.reloadPosting();
      toast.success("Posting edited successfully");
    } catch (error) {
      toast.error("Error editing posting");
    }
  };
  const resetfields = () => {
    setvalidation_message(false);
    setgrossamount("");
    setreference("");
    setvatamount("");
    // setmatterref(undefined);
    setpayee("");
    setpayer("");
    setdetails("");
    setnetamount(0);
    setfeeearnerref(null);
    setdateselector(date);
    setvatvalue(null);
    setofficevalue(null);
    setclientvalue(null);
    setddavalue(null);
    setsuppliervalue({ supplierRef: "" });
    setnominal({ nominalRef: "" });
    setbranch({ branchId: "" });
    setsuppliersdata(suppliersdata.slice());
    setvatsData(activevatsdata.slice());
    setfeeearnerData(prepareFeeearnerData(feeearnerdata.slice()));
    setresetDocs(!resetDocs);
    setcounselfee(false);
    dispatch(clearFile("Chit Request"));
  };

  const { isSuccess, uploadForm, progress } = useUploadForm();

  const [filesData, setFilesData] = React.useState([]);

  const handleUpload = async (e) => {
    let data = onFileUpload(e);
    setFilesData([...filesData, data]);

    const parameters = {
      documentType: "Posting",
      matterId: postingInfo.matterID || "",
      supplierRef: suppliervalue.supplierRef || "",
      nominalRef: nominal.nominalRef || "",
    };
    const dataChoosen = await uploadForm(data, e.target.files[0], parameters);
    data.fileID = dataChoosen.data.data.fileID;
  };
  const onFileRemove = (fileId) => {
    setFilesData((prev) => prev.filter((file) => file.fileID !== fileId));
  };

  const comboBoxesInfo = [
    {
      key: "OfficeBank",
      label: "Office Bank",
      condition: officevalue === undefined,
      disabled: true,
      value: officevalue,
      placeholder: "Search Office Bank",
    },
    {
      key: "SupplierRef",
      label: "Supplier Ref",
      condition: suppliervalue.supplierRef === "",
      data: suppliers,
      onFilterChange: filterChangesupplier,
      value: suppliervalue,
      onChange: onChangesupplier,
      placeholder: "Search Supplier Ref",
    },
    {
      key: "ClientBank",
      label: "Client Bank",
      condition: clientvalue === undefined,
      data: clientdata,
      onFilterChange: filterChangeclientbank,
      value: clientvalue,
      onChange: onChangeclientbank,
      placeholder: "Search Client Bank",
    },
    {
      key: "DDABank",
      label: "DDA Bank",
      condition: ddavalue === undefined,
      data: ddadata,
      onFilterChange: filterChangeddabank,
      value: ddavalue,
      onChange: onChangeddabank,
      placeholder: "Search DDA Bank",
    },
    {
      key: "NominalRef",
      label: "Nominal Ref",
      condition: nominal.nominalRef === "",
      data: nominaldata,
      onFilterChange: filternominal,
      value: nominal,
      onChange: onChangenominal,
      placeholder: "Search Nominal Ref",
    },
  ];

  const EditPostingContent = () => {
    return (
      <div className={`h-100 ${styles["main-container"]}`}>
        <div className="bgForm overflow-auto">
          <div
            className="px-lg-5 px-3 "
            style={{
              maxHeight: "auto",
              maxWidth: "950px",
              overflow: "auto",
            }}
          >
            <form onSubmit={handlesubmit}>
              <div className="mt-4 px-2 d-flex flex-column">
                <div className="d-flex flex-column mb-2">
                  <Label className="label mb-1">Posting Type</Label>
                  <DropDownList
                    className={`${styles["input-styles"]}`}
                    iconClassName="wa"
                    value={`${postingInfo.postingType} - ${props.postingName}`}
                    disabled
                  />
                </div>
                <div className="d-flex flex-column">
                  <Label className="label mb-1">Matter</Label>
                  <MattersCombobox
                    className={`${styles["input-styles"]}`}
                    disabled
                    error={validation_message && !matterref}
                    value={matter}
                  />
                </div>
                <div className="d-flex flex-column mt-2">
                  <Label className="label mb-1">Date</Label>
                  <DatePicker
                    className={`${styles["input-styles"]} px-2`}
                    value={dateselector}
                    width={280}
                    onChange={onChangedateselect}
                    dateInput={CustomDateInput}
                    toggleButton={CalenderCustomToggleButton}
                    defaultValue={dateselector}
                  />
                </div>

                <div className="d-flex">
                  {list.includes("Reference") && (
                    <div className="d-flex flex-column mt-2 me-5">
                      <Label className="label mb-1">Reference</Label>
                      <Input
                        className={`${styles["input-styles"]}`}
                        value={reference}
                        onChange={(e) => {
                          setreference(e.target.value);
                        }}
                        placeholder={"Add Reference"}
                      />
                    </div>
                  )}
                  <div className="d-flex flex-column">
                    {comboBoxesInfo.map(
                      ({
                        key,
                        label,
                        condition,
                        data,
                        onFilterChange,
                        value,
                        onChange,
                        placeholder,
                        disabled,
                      }) =>
                        list.includes(key) ? (
                          <ComboBoxComponent
                            key={key}
                            label={label}
                            className={`${styles["input-styles"]}`}
                            data={data}
                            textField={"representation"}
                            filterable={true}
                            onFilterChange={onFilterChange}
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            disabled={disabled}
                          />
                        ) : null
                    )}
                  </div>
                </div>
                <div className="d-flex mt-2">
                  {list.includes("NetAmount") && (
                    <div className="d-flex flex-column me-5 ">
                      <Label className="label mb-1">Net amount</Label>
                      <NumericTextBox
                        value={netamount}
                        className={`${styles["input-styles"]} right-align`}
                        disabled
                        spinners={false}
                        format="n2"
                        placeholder="0.00"
                      />
                    </div>
                  )}
                  <div className="d-flex">
                    {list.includes("VatCode") && (
                      <div className="d-flex flex-column me-3">
                        <Label className="label mb-1">VAT</Label>
                        <ComboBox
                          className={`${styles["small-inputbox"]}`}
                          data={vatsdata}
                          textField={"representation"}
                          filterable={true}
                          onFilterChange={filterChange}
                          value={vatvalue}
                          onChange={onChangevat}
                          placeholder={"Code"}
                        />
                      </div>
                    )}
                    {list.includes("VatAmount") && (
                      <div className="d-flex flex-column">
                        <Label className="label mb-1">VAT Amount</Label>
                        <NumericTextBox
                          className={`${styles["small-inputbox"]} right-align`}
                          value={vatamount}
                          readOnly={true}
                          placeholder="0.00"
                          spinners={false}
                          format="n2"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex between mt-2">
                  {list.includes("FeeEarnerRef") && (
                    <div className="d-flex flex-column me-5">
                      <Label className="label mb-1">Fee Earner</Label>
                      <ComboBox
                        className={`${styles["input-styles"]}`}
                        data={feeearner}
                        iconClassName="wa"
                        textField={`representation`}
                        filterable
                        onFilterChange={filterfeeearner}
                        placeholder={"Search Fee Earner"}
                        value={feeearnerref}
                        onChange={onChangefeeearner}
                      />
                    </div>
                  )}
                  <div className="d-flex flex-column">
                    {list.includes("Payer") && (
                      <div className="d-flex flex-column">
                        <Label className="label mb-1">Payer</Label>
                        <div className="validator">
                          <Input
                            value={payer}
                            className={`${styles["input-styles"]}`}
                            onChange={(e) => {
                              setpayer(e.target.value);
                            }}
                            placeholder={"Add Payer"}
                          ></Input>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column">
                    {list.includes("Payee") && (
                      <div className="d-flex flex-column">
                        <Label className="label mb-1">Payee</Label>
                        <div className="validator">
                          <Input
                            className={`${styles["input-styles"]}`}
                            value={payee}
                            onChange={(e) => {
                              setpayee(e.target.value);
                            }}
                            placeholder={"Add Payee"}
                          ></Input>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column mt-2">
                  <Label className="label mb-1">Details</Label>
                  <TextArea
                    value={details}
                    className={`${styles["details-textbox"]}`}
                    rows={5}
                    multiple={true}
                    placeholder="Enter Details"
                    onChange={(e) => {
                      setdetails(e.target.value);
                    }}
                  />
                </div>
                <div className="mt-1 d-flex justify-content-start me-5">
                  <DocumentsUpload
                    open={documentUpload}
                    value={postingInfo?.postingType}
                    type={"Edit Posting"}
                    resetDocs={resetDocs}
                    progress={progress}
                    filesData={filesData}
                    onFileRemove={onFileRemove}
                    onError={() => {
                      const newFiles = filesData.splice(filesData.length, 1);
                      setFilesData([...newFiles]);
                    }}
                    onUpload={handleUpload}
                  />
                </div>
              </div>
              <Modalposting
                value={modalvalue}
                reset={() => {
                  resetfields();
                }}
                closeModal={() => {
                  setmodalvalue(false);
                  props.cancelEdit();
                }}
                component={"Posting"}
              />
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      title={"Edit Posting"}
      onClose={props.cancelEdit}
      className="dialog-xmd"
    >
      {EditPostingContent()}
      <DialogActionsBar>
        <div className={`float-end w-100 dialog-footer`}>
          <Button
            className="common-btn bgBtnSecondary me-3"
            type="button"
            onClick={() => {
              //   resetfields();
              props.cancelEdit();
            }}
          >
            Cancel
          </Button>
          <Button
            className="common-btn me-3"
            type="submit"
            id="chit-request-post"
            onClick={handlesubmit}
          >
            Update
          </Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};
export default EditPosting;
