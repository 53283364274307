import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import styles from "../../pages/Postings/Posting.module.scss";
import image from "../../assets/success.png";
import Bills from "./Bills";

const BillingRequestModal = ({ selectedMatter, onClose }) => {
  return (
    <Dialog
      onClose={onClose}
      width={"90%"}
      height={"90vh"}
      title={"Add Billing Request"}
      className="batch-posting-dialog"
    >
      <Bills
        component="BillingRequest"
        isDialog={true}
        selectedMatter={selectedMatter}
      />
    </Dialog>
  );
};

export default BillingRequestModal;
