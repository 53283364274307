import React, { useEffect, useState } from "react";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import LoadingScreen from "components/common/Loading";
import { baseURL } from "api/axios.config";
import { useUploadForm } from "components/fileUploadHook";
import { toast } from "react-toastify";
import { handleDownloadAndUpload } from "components/common/HandleReportDownload";

const ReportViewer = (props) => {

    let viewer;
    const { accessToken } = useOidcAccessToken();
    const [isLoading, setIsLoading] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { uploadForm } = useUploadForm();

    const exportToPDF = () => {
        if (viewer) {
            viewer.commands.export.exec("PDF");
        }
    };

    useEffect(() => {
        if (props.exportFunction) {
            exportToPDF();
        }
    }, [props.exportFunction]);

    const handleExportEnd = (e, args) => {
        if (props.saveActivity) {
            args.handled = true;
            const modifiedUrl = replaceDisposition(args.url);
            handleDownloadAndUpload(modifiedUrl, props.matterID, uploadForm, () => {
                toast.success("Activity Added");
                props.exportCallback();
            });
        }
    };

    function replaceDisposition(url) {
        return url.replace("attachment", "inline");
    }


    useEffect(() => {
        const handleWindowResize = () => {
            if (window.innerWidth > 1400 && windowWidth < 1400) {
                viewer.refreshReport();
                setWindowWidth(window.innerWidth);
            } else if (window.innerWidth < 1400 && windowWidth > 1400) {
                viewer.refreshReport();
                setWindowWidth(window.innerWidth);
            }
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });


    useEffect(() => {
        if (props.shouldLoad) {
            setIsLoading(true);
            viewer.setReportSource({
                report: props.name,
                parameters: {
                    ...props.parameters,
                },
            });
            props.setShouldLoad(false);
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
    }, [props.shouldLoad]);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <TelerikReportViewer
                ref={(el) => (viewer = el)}
                serviceUrl={props.url ? props.url : `${baseURL}/Reporting/reports/`}
                pageReady={() => {
                    setIsLoading(false);
                }}
                disabledButtonClass="checkedClass"
                authenticationToken={accessToken}
                exportEnd={handleExportEnd}
                viewerContainerStyle={{
                    height: props?.modalHeight ? props?.modalHeight : "82vh",
                    windowWidth: "100%",
                    clear: "both",
                    fontFamily: "ms sans serif",
                }}
                reportSource={{
                    report: props.name,
                    parameters: {
                        ...props.parameters,
                    },
                }}
                viewMode="INTERACTIVE"
                scaleMode="SPECIFIC"
                scale={1}
                enableAccessibility={false}
                error={(e) => {
                    setIsLoading(false);
                }}
            />
        </>
    );
};

export default ReportViewer;
