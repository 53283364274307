import { useState } from "react";
import axios from "axios";
import * as api from "../api/index";
import { TOKEN } from "../api/axios.config";
import { useDispatch } from "react-redux";

export const useUploadForm = (url) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const reset = () => {
    setIsSuccess(false);
    setProgress(0);
  };

  const uploadForm = async (fileData, formData, metaData) => {
    const data = await api.createFileEntry(fileData.filename, metaData);

    await axios.put(data.data.data.uploadURL, formData, {
      headers: {
        "x-ms-blob-type": "BlockBlob",
        Authentication: `${TOKEN}`,
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
      onDownloadProgress: (progressEvent) => {
        const progress =
          100 + (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    });

    let result;
    const datacomplete = await api.uploadCompleted(data.data.data.fileID);
    result = datacomplete;
    setIsSuccess(true);
    setProgress(0);
    return result;
  };

  return { uploadForm, isSuccess, progress, reset };
};
