import MenuItemId from "./MenuItemId.ts";
// import Contacts from "../../assets/MenuSvgIcons/Contacts.svg"
// import Admin from "../../assets/MenuSvgIcons/Admin.svg"
// import Feeearner from "../../assets/MenuSvgIcons/Feeearner.svg"
// import Matters from "../../assets/MenuSvgIcons/Matters.svg"
// import Nominals from "../../assets/MenuSvgIcons/Nominals.svg"
// import Purchase from "../../assets/MenuSvgIcons/Purchase.svg"
// import Reports from "../../assets/MenuSvgIcons/Reports.svg"
// import Dashboard from "../../assets/MenuSvgIcons/Dashboard.svg"
// import Sales from "../../assets/MenuSvgIcons/ClientAccounts.svg"
// import Clients from "../../assets/MenuSvgIcons/Clients.svg"
import Contacts from "../../assets/MenuPngIcons/Contact.png";
import Admin from "../../assets/MenuPngIcons/Admin.png";
import Feeearner from "../../assets/MenuPngIcons/Feeearner.png";
// import Matters from "../../assets/MenuPngIcons/Matter.png";
import Nominals from "../../assets/MenuPngIcons/Nominal.png";
import Purchase from "../../assets/MenuPngIcons/Purchase.png";
import Consultant from "../../assets/MenuPngIcons/Consultant.png";
import Reports from "../../assets/MenuPngIcons/Reports.png";
import Dashboard from "../../assets/MenuPngIcons/Dashboard.png";
import Sales from "../../assets/MenuPngIcons/Account.png";
import Clients from "../../assets/MenuPngIcons/Client.png";
import Matters from "../../assets/MenuSvgIcons/Matters.svg";
import { Tabs } from "components/Enums/TabEnums.ts";
import { useSelector } from "react-redux";

export const sideBarData = [
  {
    id: MenuItemId.Dashboard,
    text: "Dashboard",
    name: "Dashboard",
    icon: <img src={Dashboard} className={`icon-styles`} />,
    selected: true,
    route: "/dashboard",
    tabId: Tabs.ACCOUNTS_DASHBOARD,
  },
  {
    id: MenuItemId.Clients,
    text: "Clients",
    name: "Clients",
    icon: <img src={Clients} className={`icon-styles`} />,
    route: "/clients",
    tabId: Tabs.CLIENTS,
  },
  {
    id: MenuItemId.Matters,
    text: "Matters",
    name: "Matters",
    icon: <img src={Matters} className={`icon-styles`} />,
    route: "/matters",
    tabId: Tabs.MATTERS,
  },
  {
    id: MenuItemId.Contacts,
    text: "Contacts",
    name: "Contact",
    icon: <img src={Contacts} className={`icon-styles`} />,
    route: "/contacts",
    tabId: Tabs.CONTACTS,
  },
  {
    id: MenuItemId.Accounts,
    text: "Accounts",
    icon: <img src={Sales} className={`icon-styles`} />,
    ["data-expanded"]: false,
    parent: true.toString(),
    route: "/accounts",
    tabId: Tabs.ACCOUNTS,
  },
  {
    id: MenuItemId.Billing,
    parentId: MenuItemId.Accounts,
    text: "Billing",
    name: "Billing",
    icon: "k-i-minus",
    route: "/billing",
    flag: "first",
  },
  {
    id: MenuItemId.OfficePostings,
    parentId: MenuItemId.Accounts,
    text: "Office Postings",
    name: "Office Postings",
    icon: "k-i-minus",
    route: "/officepostings",
  },
  {
    id: MenuItemId.ClientPostings,
    parentId: MenuItemId.Accounts,
    text: "Client Postings",
    name: "Client Postings",
    icon: "k-i-minus",
    route: "/clientpostings",
  },
  {
    id: MenuItemId.OfficeCreditAndAlloc,
    parentId: MenuItemId.Accounts,
    text: "Office Allocations",
    name: "Office Allocations",
    icon: "k-i-minus",
    route: "/officeallocations",
  },

  {
    id: MenuItemId.MatterLedger,
    parentId: MenuItemId.Accounts,
    text: "Matter Ledger",
    name: "Matter Ledger",
    icon: "k-i-minus",
    route: "/matterledger",
  },
  {
    id: MenuItemId.BatchPosting,
    parentId: MenuItemId.Accounts,
    text: "Batch Posting",
    name: "Batch Posting",
    icon: "k-i-minus",
    route: "/batchposting",
  },
  {
    id: MenuItemId.Transfers,
    parentId: MenuItemId.Accounts,
    text: "Transfers",
    name: "Transfers",
    icon: "k-i-minus",
    route: "/transfer",
    flag: "last",
  },
  {
    id: MenuItemId.Nominal,
    text: "Nominal",
    icon: <img src={Nominals} className={`icon-styles`} />,
    ["data-expanded"]: false,
    parent: true.toString(),
    route: "/nominal",
    tabId: Tabs.NOMINAL,
  },
  {
    id: MenuItemId.Nominals,
    parentId: MenuItemId.Nominal,
    text: "Nominals",
    name: "Nominal",
    icon: "k-i-minus",
    route: "/nominals",
    flag: "first",
  },
  {
    id: MenuItemId.NominalLedger,
    parentId: MenuItemId.Nominal,
    text: "Nominal Ledger",
    name: "Nominal Ledger",
    icon: "k-i-minus",
    route: "/nominalledger",
  },
  {
    id: MenuItemId.BankPostings,
    parentId: MenuItemId.Nominal,
    text: "Bank Postings",
    name: "Bank Postings",
    icon: "k-i-minus",
    route: "/bankpostings",
  },
  {
    id: MenuItemId.JournalPostings,
    parentId: MenuItemId.Nominal,
    text: "Journal Posting",
    name: "Journal Posting",
    icon: "k-i-minus",
    route: "/journalposting",
  },
  {
    id: MenuItemId.VATReturn,
    parentId: MenuItemId.Nominal,
    text: "VAT Return",
    name: "VAT Return",
    icon: "k-i-minus",
    route: "/vatreturn",
  },
  {
    id: MenuItemId.BankRec,
    parentId: MenuItemId.Nominal,
    text: "Bank Rec",
    name: "Bank Rec",
    icon: "k-i-minus",
    route: "/bankrec",
    flag: "last",
  },
  {
    id: MenuItemId.Purchase,
    text: "Purchase",
    icon: <img src={Purchase} className={`icon-styles`} />,
    ["data-expanded"]: false,
    parent: true.toString(),
    route: "/purchase",
    flag: "first",
    tabId: Tabs.PURCHASE,
  },

  {
    id: MenuItemId.Consultant,
    text: "Consultant",
    icon: <img src={Consultant} className={`icon-styles`} />,
    ["data-expanded"]: false,
    parent: true.toString(),
    route: "/consultant",
    flag: "first",
    tabId: Tabs.CONSULTANT,
  },

  {
    id: MenuItemId.Consultants,
    parentId: MenuItemId.Consultant,
    text: "Consultants",
    icon: "k-i-minus",
    ["data-expanded"]: false,
    route: "/consultants",
    flag: "first",
  },

  {
    id: MenuItemId.ConsultantLedger,
    parentId: MenuItemId.Consultant,
    text: "Consultant Ledger",
    icon: "k-i-minus",
    ["data-expanded"]: false,
    route: "/consultantledger",
  },

  {
    id: MenuItemId.PayConsultant,
    parentId: MenuItemId.Consultant,
    text: "Pay Consultant",
    icon: "k-i-minus",
    ["data-expanded"]: false,
    route: "/payconsultant",
    flag: "last",
  },

  {
    id: MenuItemId.Suppliers,
    parentId: MenuItemId.Purchase,
    text: "Suppliers",
    name: "Suppliers",
    icon: "k-i-minus",
    route: "/suppliers",
    flag: "first",
  },
  {
    id: MenuItemId.SupplierLedger,
    parentId: MenuItemId.Purchase,
    text: "Supplier Ledger",
    name: "Supplier Ledger",
    icon: "k-i-minus",
    route: "/supplierledger",
  },
  {
    id: MenuItemId.PurchaseInvoicePostings,
    parentId: MenuItemId.Purchase,
    text: "Purchase Invoice",
    name: "Purchase Invoice",
    icon: "k-i-minus",
    route: "/purchaseinvoice",
  },
  {
    id: MenuItemId.DisbursementPostings,
    parentId: MenuItemId.Purchase,
    text: "Disbursements",
    name: "Disbursement",
    icon: "k-i-minus",
    route: "/disbursements",
  },
  {
    text: "Pay Disbursement",
    name: "Pay Disbursement",
    icon: "k-i-minus",
    id: MenuItemId.DisbursementPayments,
    parentId: MenuItemId.Purchase,
    route: "/paydisbursement",
  },
  {
    text: "Pay Supplier",
    name: "Pay Supplier",
    icon: "k-i-minus",
    id: MenuItemId.SupplierPayments,
    parentId: MenuItemId.Purchase,
    route: "/paysupplier",
    flag: "last",
  },
  {
    id: MenuItemId.Admin,
    text: "Admin",
    icon: <img src={Admin} className={`icon-styles`} />,
    ["data-expanded"]: false,
    parent: true.toString(),
    route: "/admin",
    tabId: Tabs.ADMIN,
  },
  {
    id: MenuItemId.TransactionPeriods,
    parentId: MenuItemId.Admin,
    text: "Transaction Period",
    name: "Transaction Period",
    icon: "k-i-minus",
    route: "/transactionperiod",
    flag: "first",
  },
  {
    id: MenuItemId.YearEnd,
    parentId: MenuItemId.Admin,
    text: "Year end",
    name: "Year End",
    icon: "k-i-minus",
    route: "/yearend",
  },
  {
    id: MenuItemId.ChitPosting,
    parentId: MenuItemId.Admin,
    text: "Chit Posting",
    name: "Chit Posting",
    icon: "k-i-minus",
    route: "/chitposting",
  },
  {
    id: MenuItemId.BillRequests,
    parentId: MenuItemId.Admin,
    text: "Bill Requests",
    name: "Bill Requests",
    icon: "k-i-minus",
    route: "/billrequests",
  },
  {
    id: MenuItemId.PaymentProcessing,
    parentId: MenuItemId.Admin,
    text: "Payment Processing",
    name: "Payment Processing",
    icon: "k-i-minus",
    route: "/paymentprocessing",
    flag: "last",
  },
  {
    id: MenuItemId.FeeEarner,
    text: "Fee Earner",
    ["data-expanded"]: false,
    icon: <img src={Feeearner} className={`icon-styles`} />,
    parent: true.toString(),
    route: "/feeearner",
    tabId: Tabs.FEE_EARNER,
  },
  {
    id: MenuItemId.TimeRecorded,
    parentId: MenuItemId.FeeEarner,
    text: "Time Recorded",
    name: "Time Recorded",
    route: "/timerecorded",
    flag: "first",
  },
  {
    id: MenuItemId.MyMatters,
    parentId: MenuItemId.FeeEarner,
    text: "My Matters",
    name: "My Matters",
    route: "/myMatters",
  },
  {
    id: MenuItemId.TimeRecording,
    parentId: MenuItemId.FeeEarner,
    text: "Time Recording",
    name: "Time Recording",
    route: "/timerecording",
  },
  {
    id: MenuItemId.ChitRequest,
    parentId: MenuItemId.FeeEarner,
    text: "Chit Request",
    name: "Chit Request",
    icon: "k-i-minus",
    route: "/chitrequest",
  },
  {
    id: MenuItemId.BillingRequest,
    parentId: MenuItemId.FeeEarner,
    text: "Billing Request",
    name: "Billing Request",
    icon: "k-i-minus",
    route: "/billingrequest",
    flag: "last",
  },

  {
    id: MenuItemId.Reports,
    text: "Reports",
    name: "Reports",
    icon: "k-i-minus",
    icon: <img src={Reports} className={`icon-styles`} />,
    route: "/reports",
    tabId: Tabs.REPORTS,
  },
  {
    id: MenuItemId.ClientListingReport,
    parentId: MenuItemId.Reports,
    text: "Client Listing",
    name: "Client Listing",
    icon: "k-i-minus",
    route: "/clientlisting",
    flag: "first",
  },
  {
    id: MenuItemId.MatterBalanceReport,
    parentId: MenuItemId.Reports,
    text: "Matter Balances",
    name: "Matter Balances",
    icon: "k-i-minus",
    route: "/matterbalances",
  },
  {
    id: MenuItemId.DisbursementReport,
    parentId: MenuItemId.Reports,
    text: "Disbursements",
    name: "Disbursements",
    icon: "k-i-minus",
    route: "/disbursements",
  },
  {
    id: MenuItemId.BillingReport,
    parentId: MenuItemId.Reports,
    text: "Billing",
    name: "Billing",
    icon: "k-i-minus",
    route: "/billing",
  },
  {
    id: MenuItemId.SupplierBalanceReport,
    parentId: MenuItemId.Reports,
    text: "Suppliers",
    name: "Suppliers",
    icon: "k-i-minus",
    route: "/supplierbalances",
  },
  {
    id: MenuItemId.TrialBalanceReport,
    parentId: MenuItemId.Reports,
    text: "Nominals",
    name: "Nominals",
    icon: "k-i-minus",
    route: "/nominalbalances",
  },

  {
    id: MenuItemId.BankRecReport,
    parentId: MenuItemId.Reports,
    text: "Bank Rec",
    name: "Bank Rec",
    icon: "k-i-minus",
    route: "/bankrec",
    flag: "last",
  },
];
