import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AmountFormatCell } from "components/common/formatter";
import {
    editFeeHeaderItems,
    getFeeHeaderItems,
} from "actions/fixedFeeHeader.action";
import EditFeeHeaderItems from "./EditFeeHeaderItems";
import { ActiveCell, EditCommandCell } from "../GridComponents";

const EditCommandCell1 = (props) => {

    if (typeof props.dataItem.allowedApps === "string") {
        const array = props?.dataItem?.allowedApps?.split(",");
        props.dataItem.allowedApps = array;
    }
    return (
        <td>
            <EditCommandCell {...props} component="ProfitCost" />
        </td>
    )
}

const ViewCommandCell = (props) => {
    return (
        <td>
            <Button
                icon="view"
                onClick={() => {
                    props.enterViewEdit(props.dataItem);
                }}
            ></Button>
        </td>
    );
};
const FixedFeeHeaderItemsList = (props) => {
    const [openForm, setOpenForm] = React.useState(false);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [editItem, setEditItem] = React.useState({
        ProductID: 1,
    });
    const [viewItem, setViewItem] = React.useState({
        ProductID: 1,
    });
    const [openViewForm, setOpenViewForm] = React.useState(false);

    const templatesData = useSelector(
        (state) => state.profitCostTemplate.fixedFeeHeaderItems
    );
    useEffect(() => {
        if (templatesData !== null && templatesData !== undefined) {
            setData(templatesData);
        }
    }, [templatesData]);
    const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);
    };

    const enterViewEdit = (item) => {
        setOpenViewForm(true);
        setViewItem(item);
    };
    const handleSubmit = (event) => {
        if (typeof event.vatCode === "object") {
            event.vatRate = event?.vatCode.rate;
            event.vatAmount = (event.vatCode.rate / 100) * event.netAmount;
            event.vatCode = event.vatCode.vatCodeId;
        }
        let item = [];
        item.push(event);

        dispatch(editFeeHeaderItems(props.item.fixedFeeHeaderID, item));
        setOpenForm(false);

        setTimeout(() => {
            dispatch(getFeeHeaderItems(props.item.fixedFeeHeaderID));
        }, 1000);
    };
    const [products, setProducts] = React.useState({
        data: [],
        total: 20,
    });
    const [dataState, setDataState] = React.useState({
        take: 10,
        skip: 0,
        total: 20,
    });

    const handleCancelEdit = () => {
        setOpenForm(false);
    };
    const MyEditCommandCell = (props) => (
        <EditCommandCell1 {...props} enterEdit={enterEdit} />
    );
    const MyViewCommandCell = (props) => (
        <ViewCommandCell {...props} enterViewEdit={enterViewEdit} />
    );
    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const dataReceived = (products) => {
        setProducts(products);
    };

    return (
        <div>
            <Grid
                data={data}
                onDataStateChange={dataStateChange}
                className="accounts-table p-4 pt-2 mb-4"
            >
                <Column field="details" title="Details" />
                <Column field="vatCode" title="Vat Code" />
                <Column field="nominalRef" title="Nominal Ref" />

                <Column field="netAmount" title="Net Amount" cell={AmountFormatCell} />
                <Column cell={ActiveCell} title="Active" width="80" />
                <Column title="Edit" cell={MyEditCommandCell} width="80" />
            </Grid>
            {openForm && (
                <EditFeeHeaderItems
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    item={editItem}
                />
            )}
        </div>
    );
};

export default FixedFeeHeaderItemsList;
