import { DateInput } from "@progress/kendo-react-dateinputs";
import React from "react";

export const CustomDateInput = (props) => {
  return (
    <DateInput
      placeholder="Select Date"
      value={props.value ? props.value : null}
      className="margin-date"
      formatPlaceholder={{
        year: " year ",
        month: " month ",
        day: props.value ? new Date(props.value).getDay() : " day ",
      }}
      onChange={(e) => {
        if (e?.value !== null) {
          props.onChange(e);
        }
      }}
    />
  );
};
