import * as React from "react";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api/index";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import documentIcon from "../../assets/fileIcons/Document.svg";
import {
    clearMattersBalances,
    getMattersBalances,
} from "actions/matters.action";
import styles from "../BatchPosting/BatchPosting.module.scss";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import MatterAccount from "../MatterLedger/MatterAccount";
import { AmountFormatCell, DateFormatCell } from "components/common/formatter";
import { PayCell } from "components/BatchPosting/GridComponents";
import { PostingSubmitFilter } from "components/BatchPosting/SubmitFunction";
import Form from "components/BatchPosting/Form";
import MatterDetails from "pages/MatterLedger/MatterDetails";
import { TransactionPeriod } from "components/common/TransactionPeriod";
import { toast } from "react-toastify";

const BatchPostings = ({ type, selectedList, onClose }) => {
    const editField = "inEdit";
    const [errorModal, setErrorModal] = useState(false);
    const [updateDataIndex, setUpdateDataIndex] = useState(0);
    const [vatCode, setVatCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentTransactionPeriod, setCurrentTransactionPeriod] = useState();
    let locale = useSelector((state) => state?.company?.company?.locale);
    const [initialData, setInitialData] = useState({
        date: new Date(),
    });
    const dispatch = useDispatch();

    const [matterref, setmatterref] = useState(null);
    const [showChits, setShowChits] = useState(false);
    const [list, setList] = useState([]);
    const [renderList, setRenderList] = useState([]);
    const [updateResetData, setUpdateResetData] = useState({});
    const [selectedPostingCode, setSelectedPostingCode] = useState(null);
    const [item, setitem] = useState([
        { code: "0", name: "Loading", displayFields: [] },
    ]);

    const handleCancel = () => {
        return;
    };

    const handleClearFile = (event) => {
        setSelectedPostingCode();
        setFilesData([]);

        onChagngePosting(event);
    };

    useEffect(() => {
        if (type === "chitPosting") {
            setList([...selectedList]);
        }
    }, [selectedList]);

    //dup
    const onChagngePosting = (event) => {
        const code = event?.value?.code;
        if (code === "PIN") {
            setRenderList(["PIN"]);
        }
        if (code === "DIN") {
            setRenderList(["DIN"]);
        }
        if (code === "OCR") {
            setRenderList(["OCR"]);
        }
        if (code === "ODR") {
            setRenderList(["ODR"]);
        }
        if (code === "CCR") {
            setRenderList(["CCR"]);
        }
        if (code === "CDR") {
            setRenderList(["CDR"]);
        }
        if (code === "DDR") {
            setRenderList(["DDR"]);
        }
        if (code === "DCR") {
            setRenderList(["DCR"]);
        }
        if (code === "DUU") {
            setRenderList(["DUU"]);
        }
        if (code === "CTO") {
            setRenderList(["CTO"]);
        }
        if (code === "OTC") {
            setRenderList(["OTC"]);
        }
        if (code === "BR") {
            setRenderList(["BR"]);
        }
        if (code === "BP") {
            setRenderList(["BP"]);
        }
        if (code === "BT") {
            setRenderList(["BT"]);
        }
        if (code === "DPU") {
            setRenderList(["DPU"]);
        }
        if (code === "CTC") {
            setRenderList(["CTC"]);
        }
        if (code === "OTN") {
            setRenderList(["OTN"]);
        }
        if (code === "NTO") {
            setRenderList(["NTO"]);
        }
        if (code === "NTB") {
            setRenderList(["NTB"]);
        }
        setSelectedPostingCode(code);
    };

    const handleRemove = (row) => {
        if (list.length === 0) return;
        const listcopy = [...list];
        listcopy.splice(row, 1);
        setList([...listcopy]);
    };

    const handleSubmit = async (e) => {
        const data = PostingSubmitFilter(list);
        if (data.vatAmount === null) {
            data.vatAmount = 0;
        }
        if (data.length === 0) return;
        const formattedData = data.map((postingItem) => {
            postingItem.transactionPeriod = currentTransactionPeriod?.period;
            return postingItem;
        });
        setLoading(true);
        try {
            const response = await api.createposting(formattedData);
            toast.success(
                `Posted Successfully for ${response?.data?.data[0]?.postingType} - ${response?.data?.data[0]?.transactionNo} `
            );
            setList([]);
            if (onClose) {
                onClose();
            }
        } catch (error) {
            toast.error("Error occured in posting");
        } finally {
            setLoading(false);
        }
    };

    const DocumentCounterCell = (e) => {
        return (
            <td>
                <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                    {e?.dataItem?.documentCount > 0 && (
                        <>
                            <img
                                role="button"
                                src={documentIcon}
                                alt="Document Icon"
                                onClick={() => { }}
                            ></img>
                        </>
                    )}
                    <span>
                        {e?.dataItem?.documentCount === 0 ? "" : e?.dataItem?.documentCount}
                    </span>
                </div>
            </td>
        );
    };

    const [matterId, setMatterId] = useState(null);
    const [matterIdTo, setMatterIdTo] = useState(null);

    const onChangematter = (event) => {
        if (event.value) {
            setMatterId(event.value);
            dispatch(getMattersBalances(event.value.matterId, "All Postings"));
            setmatterref(event.value.matterId);
        } else {
            setMatterId(null);
            setmatterref(null);
            dispatch(clearMattersBalances("All Postings"));
        }
    };

    const [filesData, setFilesData] = useState([]);

    const [isUpdate, setIsUpdate] = useState(false);
    //posting filer
    // const [postingDataN, setPostingDataN] = React.useState(item.slice());
    const [postingCode, setPostingCode] = useState();
    const [postingNames, setPostingNames] = useState();

    const addToBatch = (data) => {
        setList((prevList) => [...prevList, ...data]);
    };

    const handleUpdate = (props) => {
        const { dataIndex, dataItem } = props;
        onChangematter({ value: dataItem.matterID });
        setMatterIdTo(dataItem?.matterIDTo);
        setFilesData(dataItem.documentFiles);
        setUpdateDataIndex(dataIndex);
        setUpdateResetData({ ...dataItem });
        setPostingCode(dataItem.postingType);
        setRenderList([dataItem.postingType.code]);
        setVatCode(dataItem.vatCode);
        setIsUpdate(true);
        const initialData = {
            ...dataItem,
            ddaBank:
                dataItem.ddaBank && typeof dataItem.ddaBank === "object"
                    ? {
                        ...dataItem.ddaBank,
                        representation: `${dataItem.ddaBank?.nominalRef} - ${dataItem.ddaBank?.nominalName}`,
                    }
                    : dataItem.ddaBank,
            officeBank:
                dataItem.officeBank && typeof dataItem.officeBank === "object"
                    ? {
                        ...dataItem.officeBank,
                        representation: `${dataItem.officeBank?.nominalRef} - ${dataItem.officeBank?.nominalName}`,
                    }
                    : dataItem.officeBank,
            clientBank:
                dataItem.clientBank && typeof dataItem.clientBank === "object"
                    ? {
                        ...dataItem.clientBank,
                        representation: `${dataItem.clientBank?.nominalRef} - ${dataItem.clientBank?.nominalName}`,
                    }
                    : dataItem.clientBank,
            feeEarnerRef:
                dataItem.feeEarnerRef && typeof dataItem.feeEarnerRef === "object"
                    ? {
                        ...dataItem.feeEarnerRef,
                        representation: `${dataItem.feeEarnerRef?.userRef} - ${dataItem.feeEarnerRef?.displayname}`,
                    }
                    : dataItem.feeEarnerRef,
        };
        setInitialData(initialData);
    };

    const CommandCell = (props) => {
        return (
            <td style={{ display: "flex", flexDirection: "column" }}>
                <button
                    type="button"
                    onClick={() => handleUpdate(props)}
                    className="k-button k-button-md k-rounded-md "
                >
                    <AiOutlineEdit color="blue" />
                </button>

                <button
                    type="button"
                    style={{ marginTop: "5px" }}
                    onClick={() => handleRemove(props.dataIndex)}
                    className="k-button k-button-md k-rounded-md "
                >
                    <AiOutlineDelete color="red" />
                </button>
            </td>
        );
    };

    return (
        <div
            className="container-fluid ps-0 pt-0"
            style={{ height: "calc(100vh - 100px)" }}
        >
            <div
                className="row  ps-0 ms-0 g-0"
                style={{
                    height: "760px",
                    maxHeight: "calc(100vh - 100px)",
                }}
            >

                <Form
                    postingType={type}
                    list={list}
                    setList={setList}
                    setIsUpdate={setIsUpdate}
                    onChagngePosting={onChagngePosting}
                    updateDataIndex={updateDataIndex}
                    setInitialData={setInitialData}
                    matterId={matterId}
                    matterIdTo={matterIdTo}
                    setMatterIdTo={setMatterIdTo}
                    vatCode={vatCode}
                    setVatCode={setVatCode}
                    isUpdate={isUpdate}
                    setErrorModal={() => setErrorModal(true)}
                    setRenderList={setRenderList}
                    renderList={renderList}
                    onChangematter={onChangematter}
                    initialData={initialData}
                    setUpdateDataIndex={setUpdateDataIndex}
                    item={item}
                    changePostingCode={setPostingCode}
                    postingCodeEdit={postingCode}
                    openChits={() => setShowChits(true)}
                    type={type}
                />

                <div className="col" style={{ overflowX: "auto" }}>
                    <div
                        style={{
                            overflowX: "auto",
                            // minWidth: "1000px",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <TransactionPeriod reloadIcon onRefreshTransactionPeriod={setCurrentTransactionPeriod} />

                        <div style={{ minHeight: "110px" }} className="mt-5 ms-3 me-2  ">
                            {renderList[0] !== "PIN" && (
                                <div className="row mx-0 mt-2 mb-2">
                                    <div className="col g-0 mb-lg-2 mb-xl-0">
                                        <MatterDetails matter={matterId} width={"100%"} />
                                    </div>
                                    <div className="col ms-xl-2 g-0 mb-lg-2 mb-xl-0">
                                        <MatterAccount
                                            mt="0px"
                                            float="right"
                                            component={"All Postings"}
                                            matterId={matterId}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="mt-4   ">
                                <Grid
                                    data={list}
                                    editField={editField}
                                    className={`${styles["batch-posting-grid"]} text-align-right`}
                                    style={{
                                        width: type === "chitPosting" ? "calc(810px)" : "100%",
                                        overflow: "auto",
                                    }}
                                // className=""
                                >
                                    <Column
                                        title="Type"
                                        field="postingType.code"
                                        width="70px"
                                        headerClassName="ps-3"
                                    />
                                    <Column
                                        title="Matter"
                                        field="matterID.matterRef"
                                        width="100px"
                                    />
                                    <Column title="Nominal" field="nominalRef.nominalRef" />
                                    <Column
                                        title="Date"
                                        field="date"
                                        width="100px"
                                        cell={DateFormatCell}
                                    />
                                    <Column title="Ref" field="reference" width="100px" />
                                    <Column title="Detail" field="details" />
                                    <Column
                                        title="Net"
                                        field="netAmount"
                                        cell={AmountFormatCell}
                                        width="80px"
                                    />
                                    <Column
                                        title="Vat"
                                        field="vatAmount"
                                        cell={AmountFormatCell}
                                        width="80px"
                                    />
                                    <Column title="Supplier" field="supplierRef.supplierRef" />
                                    <Column title="Fee Earner" field="feeEarnerRef.userRef" />
                                    <Column title="Payee(r)" cell={PayCell} width="120px" />
                                    <Column
                                        title="Docs"
                                        field="documentFiles"
                                        cell={DocumentCounterCell}
                                        width="80px"
                                    />
                                    <Column cell={CommandCell} width="60px" />
                                </Grid>

                                <div className="buttonstyle">
                                    <Button
                                        className="common-btn"
                                        disabled={loading}
                                        onClick={handleSubmit}
                                    >
                                        Post
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default BatchPostings;
