import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineFile } from "react-icons/ai";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import products from "products.json";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import ViewPostingDetails from "../Postings/PostingDetails";
import * as api from "../../api/index.js";
import { handleApiError } from "Helper/index.js";
import { toast } from "react-toastify";
import { Label } from "@progress/kendo-react-labels";
import { Checkbox } from "@progress/kendo-react-inputs";
import { HandleGridBody } from "components/common/HandleGridBody.js";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import {
  DateFormatCell,
  AmountFormatCell,
  CreditAmountFormatCell,
  DebitAmountFormatCell,
} from "components/common/formatter";
import {
  getSupplierrefs,
  getSuppliersByRef,
  getConsultantRefs,
} from "actions/suppliers.action";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import previousIcon from "assets/previous-icon.png";
import { Button } from "@progress/kendo-react-buttons";
import { getSupplierBalances } from "actions/suppliers.action";
import { getPreviousTransactions } from "api/accountsAPI";
import { getPostingDetails, resetFields } from "actions/postingDetails.action";
import SupplierBalances from "./SupplierBalances";
import DocumentViewer from "components/common/DocumentViewer";
import InfoBox from "components/Global/InfoBox";
import styles from "./Supplier.module.scss";
import { changeLedgerLink } from "actions/ledgerLinks.action";
import { RedirectCell } from "components/common/GridComponents";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const SupplierLedger = ({ type, component }) => {
  const dispatch = useDispatch();

  let gridPDFExport;
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [isSearched, setIsSearched] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [activeSupplierRefList, setActiveSupplierRefList] = useState([]);
  const [supplierName, setSupplierName] = useState(undefined);
  const [currenttransNo, setTransNo] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [showDV, setShowDV] = useState(false);

  const [list, setLists] = useState({
    data: [],
    total: 1,
  });
  useEffect(() => {
    if (type) {
      dispatch(getConsultantRefs());
    } else {
      dispatch(getSupplierrefs());
    }
  }, [type]);

  const [IncludeReversals, setIncludeReversals] = useState(false);
  const handleReserved = () => {
    setIsSearched(false);
    setIsRefreshed(false);
    setUpdateData(false);
    setIncludeReversals(!IncludeReversals);
  };
  const [dataState, setDataState] = useState({
    take: 200,
    skip: 0,
  });

  const { yearStartDate } = useSelector((state) => state.company.company);
  const date = new Date();
  let yearsStartDate = new Date(
    yearStartDate === undefined ? date : yearStartDate
  );

  const [fromDate, setFromDate] = useState(
    yearsStartDate.toISOString().split("T")[0]
  );
  const [fromDateSelector, setFromDateSelector] = useState(yearsStartDate);
  const onChangeFromDateSelect = (event) => {
    setFromDateSelector(event.value);
    let date = new Date(event.value);
    let fromDate = null;
    if (event.value) {
      fromDate = date.toISOString().split("T")[0];
    }
    setIsSearched(false);
    setIsRefreshed(false);
    setUpdateData(false);
    setFromDate(fromDate);
  };
  const [toDate, setToDate] = useState(date.toISOString().split("T")[0]);
  const [toDateSelector, setToDateSelector] = useState(date);
  const onChangeToDateSelect = (event) => {
    setToDateSelector(event.value);
    let date = new Date(event.value);
    let toDate = null;
    if (event.value) {
      toDate = date.toISOString().split("T")[0];
    }
    setIsSearched(false);
    setIsRefreshed(false);
    setUpdateData(false);
    setToDate(toDate);
  };

  const [updateData, setUpdateData] = useState(false);

  // const searchSupplierComboRef = useRef("searchSupplierComboRef");
  const [searchSupplierComboRef, setSearchSupplierComboRef] = useState(null);

  const [supplierRefdata, setSupplierRefData] = useState();
  const [show, setShow] = useState(false);

  const { suppliersList } = useSelector((state) => state);
  const { getSuppliersByRefs } = useSelector((state) => state.suppliersList);
  const { getConsultantsByRefs } = useSelector((state) => state.suppliersList);
  const [showPrevTrans, setShowPrevTrans] = useState(false);
  const [prevTransactions, setPrevTransactions] = useState([]);

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [25, 50, 100, 200],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state] = useState(createState(0, 10));

  const changeSupplierLedgerRef = useSelector(
    (state) => state.ledgerLinks.currentSupplierRef
  );
  useEffect(() => {
    if (changeSupplierLedgerRef !== null) {
      dispatch(changeLedgerLink("Suppliers", null));
      setIsSearched(true);
      setSearchSupplierComboRef({
        representation: `${changeSupplierLedgerRef.supplierRef} - ${changeSupplierLedgerRef.supplierName}`,
        payeeName: changeSupplierLedgerRef?.payeeName,
      });
      dispatch(getSuppliersByRef(changeSupplierLedgerRef.supplierRef));
      dispatch(
        getSupplierBalances(
          changeSupplierLedgerRef.supplierRef,
          fromDate,
          toDate
        )
      );
      setSearchValue(changeSupplierLedgerRef.supplierRef);
    }
  }, [changeSupplierLedgerRef]);
  useEffect(() => {
    if (suppliersList?.consultantrefs && type) {
      let list = suppliersList.consultantrefs?.data.map((item) => {
        const templist = item;
        templist.representation =
          item.supplierRef + "  -  " + item.supplierName;

        return templist;
      });
      setActiveSupplierRefList(list);
      setSupplierRefData(list.slice());
    } else if (suppliersList) {
      let list = suppliersList.supplierrefs.data.map((item) => {
        const templist = item;
        templist.representation =
          item.supplierRef + "  -  " + item.supplierName;

        return templist;
      });
      setActiveSupplierRefList(list);
      setSupplierRefData(list.slice());
    }
  }, [suppliersList]);
  useEffect(() => {
    if (getSuppliersByRefs) {
      setSupplierName(getSuppliersByRefs.data.supplierName);
    }
  }, [getSuppliersByRefs]);

  const filterData = (filter) => {
    const datas = activeSupplierRefList.slice();
    return filterBy(datas, filter);
  };

  const postingDoc = async (transNo) => {
    setShow(!show);
    try {
      const { data } = await api.getPostingDocuments(transNo);
      setFileData(data.data);
      setShowDV(true);
    } catch (error) {
      handleApiError(error);
    }
  };

  const onFilterChange = (event) => {
    setSupplierRefData(filterData(event.filter));
  };

  const changeSupplierRef = (event) => {
    setSearchSupplierComboRef(event.value);
    setIsSearched(true);
    let pageData = {
      dataState: {
        take: 200,
        skip: 0,
      },
    };
    dataStateChange(pageData);

    let searchedVal = null;
    if (event.value == null) {
      searchedVal = null;
    } else {
      searchedVal = event?.value?.supplierRef;
    }
    if (searchedVal) {
      // dispatch(getSuppliersByRef(searchedVal));
      // dispatch(getSupplierBalances(searchedVal, fromDate, toDate));
    }
    setSearchValue(searchedVal);
    if (event.value === null) {
      setLists([]);
      return;
    }
    dispatch(getSupplierBalances(event?.value?.supplierRef, fromDate, toDate));
  };

  const handleAction = (transNo) => {
    // dispatch(getPostingDetails(transNo));
    setVisible(!visible);
    setTransNo(transNo);
    setShowForm(true);
  };
  const handleActionRef = (e, transNo) => {
    if (!transNo) return toast("Transcation No is required");
    // dispatch(getPostingDetails(transNo));
    setTransNo(transNo);
    setVisible(!visible);
    setShowForm(true);
  };
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };
  const dataReceived = (list) => {
    if (list.data) {
      if (list.total == 0) {
        list["total"] = 1;
      }
      setLists(list);
    }
  };

  const Redirect = (props) => (
    <RedirectCell
      handleClick={() => handleAction(props.dataItem.transactionNo)}
    />
  );

  const RefCell = (e) => {
    return (
      <td
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          handleActionRef(event, e.dataItem.transactionNo);
        }}
      >
        <u>{e.dataItem.reference}</u>
      </td>
    );
  };

  const refreshData = () => {
    if (searchValue) {
      let pageData = {
        dataState: {
          take: 200,
          skip: 0,
        },
      };
      dataStateChange(pageData);
      setSearchValue(searchValue);
      setIsSearched(true);
      setIsRefreshed(true);
      // dispatch(getSupplierBalances(searchValue, fromDate, toDate));
    }
  };

  const exportPDF = () => {
    setTimeout(() => {
      if (gridPDFExport) {
        gridPDFExport.save(products);
      }
    }, 250);
  };
  const _export = React.useRef();

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const DocumentCounterCell1 = (e) => {
    return (
      <td>
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          {e.dataItem.documentCount !== null && (
            <>
              <Button
                onClick={() => postingDoc(e.dataItem.transactionNo)}
                className="border-0"
              >
                <AiOutlineFile color="blue" size={20} />
              </Button>
            </>
          )}
          <span>
            {e.dataItem.documentCount === null ? "" : e.dataItem.documentCount}
          </span>
        </div>
      </td>
    );
  };

  const fetchPreviousTransactions = async () => {
    try {
      const { data } = await getPreviousTransactions(
        "",
        "",
        searchSupplierComboRef?.supplierRef
      );
      setPrevTransactions(data);
    } catch (error) {
      handleApiError(error);
    }
  };
  const renderGrid = ({ data, isPrevTrans = false }) => (
    <div className="pr mt-3">
      <Grid
        {...dataState}
        data={data}
        onDataStateChange={dataStateChange}
        pageable={state.pageable}
        pageSize={state.pageSize}
        className={`${styles.supplierledger_gridheight}`}
      >
        <GridColumn>
          <GridColumn
            field="date"
            title="Date"
            width="120px"
            cell={DateFormatCell}
          />
          <GridColumn field="postingType" title="Type" width="80px" />
          <GridColumn
            field="reference"
            title="Ref"
            cell={RefCell}
            width="100px"
          />
          <GridColumn field="details" title="Details" />
          <GridColumn field="transactionPeriod" title="Period" width="80px" />
          <GridColumn
            field="vatAmount"
            title="VAT"
            cell={AmountFormatCell}
            width="100px"
            headerClassName="grid-header-center"
          />

          <GridColumn
            field="grossAmount"
            title="Credit"
            className="creditCol"
            cell={CreditAmountFormatCell}
            width="120px"
            headerClassName="grid-header-center"
          />
          <GridColumn
            field="grossAmount"
            title="Debit"
            className="debitCol"
            cell={DebitAmountFormatCell}
            width="120px"
            headerClassName="grid-header-center"
          />
          <GridColumn
            field="balance"
            title="Balance"
            className="balanceCol balanceTd"
            cell={AmountFormatCell}
            width="140px"
            headerClassName="grid-header-center"
          />
          <GridColumn
            field="documentCount"
            title="Doc"
            width="80px"
            cell={DocumentCounterCell1}
            headerClassName="grid-header-center"
          />
          <GridColumn width="50px" cell={Redirect} />
        </GridColumn>
      </Grid>
      {!isPrevTrans &&
        ((isSearched && searchValue) || (isRefreshed && searchValue)) && (
          <HandleGridBody
            dataState={dataState}
            onDataReceived={dataReceived}
            searchValue={(isSearched && searchValue) || null}
            isSearched={isSearched}
            isRefreshed={isRefreshed}
            IncludeReversals={IncludeReversals}
            FromDate={fromDate}
            ToDate={toDate}
            type={type ? type : null}
            currentComponent={component ? component : "supplierLedger"}
          />
        )}
    </div>
  );

  return (
    <div className="page">
      {showDV && (
        <DocumentViewer data={fileData} onClose={() => setShowDV(false)} />
      )}

      <div className="d-flex w-100">
        <span className="mt-2 me-3">
          {type ? "Consultant Ref" : "Supplier Ref"}
        </span>
        <div className="ms-0">
          {supplierRefdata && (
            <ComboBox
              style={{ width: "300px" }}
              data={supplierRefdata}
              textField={"representation"}
              filterable={true}
              onFilterChange={onFilterChange}
              iconClassName="wa"
              placeholder={
                type ? "Search Consultant Ref" : "Search Supplier Ref"
              }
              value={searchSupplierComboRef}
              onChange={changeSupplierRef}
            />
          )}
          <InfoBox
            className="mt-2 w-100"
            text={
              searchSupplierComboRef !== null &&
              `${searchSupplierComboRef?.payeeName}`
            }
            title="Payee "
          />
        </div>
        <Button
          className="common-btn ms-3"
          icon="refresh"
          onClick={refreshData}
        >
          Refresh
        </Button>

        <div className="float-end ms-auto align-items-end">
          <SupplierBalances
            type={"SupplierLedger"}
            supplier={searchSupplierComboRef}
          />
        </div>
      </div>
      {showForm && (
        <ViewPostingDetails
          reloadData={refreshData}
          transNo={currenttransNo}
          cancelEdit={() => {
            setShowForm(false);
            dispatch(resetFields());
          }}
        />
      )}
      <div className="move-up">
        <div className="nominal-form-to-parent-row">
          <div className="labels-containers">
            <Label editorId={"dp"}>From</Label>
            <div className="label-to">
              <Label editorId="to">To</Label>
            </div>
          </div>
        </div>
        <div className="nominal-form-to-row">
          <div className="col-3 date-form-to-wrapper">
            <div className="from">
              <DatePicker
                id="dp"
                className="date-picker"
                value={fromDateSelector || null}
                onChange={onChangeFromDateSelect}
                defaultValue={fromDateSelector || null}
                dateInput={CustomDateInput}
                toggleButton={CalenderCustomToggleButton}
                placeholder="Select from date"
              />
            </div>
            <div className="divider"></div>
            <div className="to">
              <DatePicker
                id="to"
                className="date-picker"
                value={toDateSelector || null}
                onChange={onChangeToDateSelect}
                dateInput={CustomDateInput}
                toggleButton={CalenderCustomToggleButton}
                defaultValue={toDateSelector || null}
                placeholder="Select to date"
              />
            </div>
          </div>

          <div className="show-reversed">
            <Checkbox
              defaultChecked={false}
              onClick={handleReserved}
              label={"Show Reversed"}
            />
          </div>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {renderGrid({ data: list, isPrevTrans: false })}
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "5px",
          }}
        >
          <button
            className="btn p-0 d-flex align-items-center justify-content-center border-0"
            onClick={() => {
              if (searchSupplierComboRef) {
                fetchPreviousTransactions();
                setShowPrevTrans(true);
              }
            }}
            disabled={!searchSupplierComboRef}
          >
            <div className={`${styles["dark-background"]}  `}>
              <img src={previousIcon} width={15} height={15} />
            </div>
          </button>
        </div>
      </div>

      {showPrevTrans && (
        <Dialog
          title="Previous Transactions"
          onClose={() => setShowPrevTrans(false)}
          className="dialog-xxl"
        >
          <div className="m-4">
            {renderGrid({ data: prevTransactions, isPrevTrans: true })}
          </div>
          <DialogActionsBar>
            <div className="dialog-footer d-flex justify-content-end">
              <button
                className="common-btn me-5"
                onClick={() => setShowPrevTrans(false)}
              >
                Close
              </button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default SupplierLedger;
