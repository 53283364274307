import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import "@progress/kendo-theme-default/dist/all.css";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
import { sideBarData as allMenusData } from "./MenuData";
import { ProfileBox } from "../layout/ProfileBox.jsx";
import styles from "./Sidebar.module.scss";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import { useMatch } from "react-router-dom";
import variables from "../../styles/_theme.scss";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";

const SideBarIcons = (props) => {
  let menuItemFlag = props.data.flag;
  return (
    <>
      <div className={"rounded-circle align-items-center " + styles["dot"]} />
      <div
        className={
          styles["bar"] +
          " " +
          (menuItemFlag ? styles[`bar${menuItemFlag}`] : "")
        }
      />
    </>
  );
};

const SideBar = forwardRef((props, ref) => {
  const [option, setoption] = useState(-1);
  const { collapseSidebar, collapsed } = useProSidebar();
  const [collapse, setCollapse] = useState(false);
  const [sideBarData, setSidebarData] = useState([]);
  const { allowedList } = useAllowedLiscence();
  useImperativeHandle(ref, () => ({
    toggleSidebar() {
      collapseSidebar();
    },
  }));
  const location = useLocation();

  const active = () => {
    let activeLocation = "";
    if (
      (window.location.pathname.match(new RegExp("/", "g")) || []).length > 1
    ) {
      const index = window.location.pathname.indexOf("/", 2);
      activeLocation = window.location.pathname
        ?.substring(0, index)
        ?.toLowerCase();
    } else {
      activeLocation = location.pathname.toLowerCase();
    }

    if (activeLocation === "/") {
      activeLocation = "/dashboard";
    }
    return activeLocation;
  };

  let mainMenuItems = sideBarData.filter((item) => {
    return (
      item.parentId === undefined ||
      item.parentId === "" ||
      item.parentId === NaN
    );
  });

  const images = require.context("../../assets/Logos", true);
  const navigate = useNavigate();
  const params = useMatch("/settings/:data");
  const settings = useMatch("/settings");
  useEffect(() => {
    if (params !== null || settings !== null) {
      props.showSettings();
    } else {
      props.hideSettings();
    }
  }, [location]);

  useEffect(() => {
    if (allowedList.length) {
      const newData = allMenusData?.filter((item) => {
        if (item.parentId) {
          return item;
        } else if (allowedList.includes(item.tabId)) {
          return item;
        }
      });

      setSidebarData(newData);
    }
  }, [allowedList]);

  return (
    <>
      <Sidebar
        className="vh-100 border-0"
        backgroundColor="white"
        width="100%"
        transitionDuration="300"
        collapsedWidth="100px"
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            borderRadius: "0px 25px 0px 0px",
          },
          [`.${menuClasses.root}`]: {
            width: "80%",
            margin: "auto",
          },
          [`.${menuClasses.button}`]: {
            marginBottom: "6px",
            height: "30px",
            "&:hover": {
              height: "30px",
            },
            "&:hover": {
              [`.${menuClasses.label}`]: {
                opacity: "1",
              },
            },
          },
          [`.${menuClasses.label}`]: {
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            color: "#707070",
            // opacity: '0.5',
            lineHeight: "13px",
          },
          [`.${menuClasses.SubMenuExpandIcon}`]: {
            color: "#262626",
            opacity: "0.5",
          },

          [`.${menuClasses.active}`]: {
            [`.${menuClasses.label}`]: {
              color: "#262626",
              opacity: 1,
            },
            [`.${menuClasses.button}`]: {
              backgroundColor: "#F4F4F3",
              borderRadius: "5px",
              height: "30px",
            },
          },
          [`.${menuClasses.subMenuRoot}`]: {
            [`.${menuClasses.label}`]: {
              lineHeight: "20px",
            },
            [`.${menuClasses.subMenuContent}`]: {
              [`.${menuClasses.button}`]: {
                padding: "0px 0px 0px 15px",
                marginBottom: "0px",
              },
            },
          },
        }}
      >
        <div className="text-center pt-4">
          <img
            alt="Collapse Icon"
            className={!collapsed ? "menuLogo" : "menuLogoCollapsed"}
            src={
              collapsed
                ? images(variables.collapsedLogo?.replace(/['"]+/g, ""))
                : images(variables.logo?.replace(/['"]+/g, ""))
            }
          ></img>
        </div>

        <Menu
          className="mt-3"
          iconShape="circle"
          closeOnClick={true}
          style={{ height: "calc(100vh - 220px", overflow: "auto" }}
        >
          {mainMenuItems.map((item) => {
            let subMenuItems = sideBarData.filter((i) => {
              return i.parentId === item.id;
            });

            if (
              subMenuItems.length === undefined ||
              subMenuItems.length === 0
            ) {
              return (
                <MenuItem
                  key={item.id}
                  active={active() === item.route ? true : false}
                  icon={item.icon}
                  onClick={() => {
                    if (item.id === 10) {
                      //   props.showSettings();
                    } else {
                    }
                    navigate(`${item.route}`);
                    setoption(item.id);
                  }}
                  component={<Link to={`${item.route}`} />}
                >
                  {!collapsed && item.text}
                </MenuItem>
              );
            } else {
              return (
                <SubMenu
                  key={item.id}
                  // className={location.pathname.toLowerCase() === item.route ? "mainMenuItem active" : "mainMenuItem"}
                  label={!collapsed && item.text}
                  icon={item.icon}
                  open={collapse === item.text ? true : false}
                  onOpenChange={(e) => {
                    if (e === true) {
                      setCollapse(item.text);
                    } else {
                      setCollapse("");
                    }
                  }}
                >
                  {subMenuItems.map((subItem) => {
                    return (
                      <MenuItem
                        key={subItem.id}
                        active={location.pathname.includes(subItem.route)}
                        className={styles["submenu-item"]}
                        icon={<SideBarIcons data={subItem} />}
                        onClick={() => {
                          setoption(subItem.id);
                          // props.openTabs(item.id, subItem.text);
                          navigate(`${item.route}${subItem.route}`);
                          // props.currentopentabs(item.text);
                        }}
                      >
                        <div
                          className={
                            option === subItem.id ? styles["active"] : ""
                          }
                        >
                          <div className={styles["submenu-text"]}>
                            {subItem.text}
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              );
            }
          })}
        </Menu>

        {!collapsed ? (
          <div className={`justify-content-center text-center`}>
            <hr className="w-100 m-auto p-2" />
            <ProfileBox />
          </div>
        ) : (
          <ProfileBox collapsed />
        )}
      </Sidebar>
    </>
  );
});

export default SideBar;
