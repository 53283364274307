import { API } from "./axios.config";

export const getBankRecNominals = () =>
  API.get(`/accounts/nominals?banks=true&Orderby=subType,nominalRef`);

export const addNominal = (data) =>
  API.post(`/accounts/nominals`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getOfficeBankNominals = () =>
  API.get(`/accounts/nominals?subtype=BalanceSheet_OfficeBank&archived=false`);

export const getclientList = () =>
  API.get(`/accounts/nominals?subtype=BalanceSheet_ClientBank&archived=false`);

export const getddaList = () =>
  API.get(`/accounts/nominals?subtype=BalanceSheet_DDABank&archived=false`);

export const getViewNominals = (id) => API.get(`/accounts/nominals/${id}`);

export const updateNominal = (id, data) =>
  API.patch(`/accounts/nominals/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const searchNominal = (page, limit, search) =>
  API.get(`/accounts/nominals?search=${search}&page=${page}&limit=${limit}`);

export const getNominalBalances = (nominalRef, FromDate, ToDate) =>
  API.get(
    `/accounts/nominals/Ref/${nominalRef}/Balances?FromDate=${FromDate}&ToDate=${ToDate}`
  );

export const getNominalsByRef = (nominalRef) =>
  API.get(`/accounts/nominals/Ref/${nominalRef}`);

export const getactivenominals = () =>
  API.get(`/accounts/nominals?archived=false&active=true`);

export const getActiveIncomeNominals = () =>
  API.get(`/accounts/nominals?banks=false&Orderby=subType,nominalRef`);

export const getBankTransferNominals = () =>
  API.get(
    `/accounts/nominals?subType=BalanceSheet_OfficeBank&active=true&Orderby=subType,nominalRef`
  );

export const getIncomeNominals = () =>
  API.get(
    "/accounts/nominals?archived=false&active=true&subtype=ProfitAndLoss_Income"
  );

export const getDDABankNominals = () =>
  API.get(
    "/accounts/nominals?archived=false&active=true&subtype=BalanceSheet_DDABank"
  );

export const getBillRequestsList = (page, limit, parameter) =>
  API.get(`/Accounts/BillRequest?${parameter}&page=${page}&limit=${limit}`);

export const getNominalsByType = (type) =>
  API.get(`/accounts/nominals?type=${type}&active=true`);

export const getNominalsList = (parameter) => {
  let url = `/accounts/nominals?`;
  if (parameter !== "") {
    url += `${parameter}`;
  }
  return API.get(url);
};

export const getNominalJournalPosting = () =>
  API.get(`/accounts/nominals?archived=false&controlNominal=false`);

export const getNominalsBySubtype = (
  subtype,
  active = true,
  archived = false
) => {
  return API.get(
    `accounts/nominals?subtype=${subtype}&active=${active}&archived=${archived}`
  );
};

export const editPosting = (transactionNo, data) =>
  API.patch(`/accounts/postings/${transactionNo}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const reversePosting = (transactionNo, data) =>
  API.request({
    url: `/accounts/postings/${transactionNo}`,
    method: "DELETE",
    data: JSON.stringify({ data }),
    headers: { "Content-Type": "application/json" },
  });

export const getTransactionPeriod = (page, limit) =>
  API.get(`/Accounts/TransactionPeriods`);

export const getCurrentSessionTransactionPeriod = (sessionPeriod) =>
  API.get(
    `/Accounts/TransactionPeriods/Current?sessionPeriod=${sessionPeriod}`
  );

export const getCurrentTransactionPeriod = () =>
  API.get(`/Accounts/TransactionPeriods/Current`);

export const updateTransactionPeriod = (data) =>
  API.post(`/Accounts/TransactionPeriods`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getDefaultNominals = (branchId) =>
  API.get(`/Accounts/DefaultNominal/${branchId}`);

export const getNewDefaultNominals = (branchId) =>
  API.get(`/Accounts/DefaultNominal/${branchId}/New`);

export const getAuthorizations = () => API.get(`/Accounts/Authorisations`);

export const updateAuthoriations = (data) =>
  API.patch(`/Accounts/Authorisations`, JSON.stringify({ data: data }), {
    headers: { "Content-Type": "application/json" },
  });

export const updateDefaultNominals = (branchId, data) =>
  API.post(`/Accounts/DefaultNominal/${branchId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getReportTemplates = (type) =>
  API.get(`/Accounts/ReportTemplates?reportType=${type}`);

export const addReportTemplate = (data, type) =>
  API.post(
    `/Accounts/ReportTemplates?reportType=${type}`,
    JSON.stringify({ data }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const editReportTemplate = (data, type, id) =>
  API.patch(
    `/Accounts/ReportTemplates/${id}?reportType=${type}`,
    JSON.stringify({ data }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const getPreviousTransactions = (matterId, nominalRef, supplierRef) => {
  let url = `/Accounts/PreviousTransactions?`;
  if (matterId) {
    url += `MatterID=${matterId}`;
  }
  if (nominalRef) {
    url += `&nominalRef=${nominalRef}`;
  }
  if (supplierRef) {
    url += `&supplierRef=${supplierRef}`;
  }
  return API.get(url);
};

export const getChartOfAccounts = () => API.get(`/Accounts/ChartOfAccounts`);

export const createChartOfAccounts = (data) =>
  API.post(`/Accounts/ChartOfAccounts`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const updateChartOfAccounts = (id, data) =>
  API.patch(`/Accounts/ChartOfAccounts/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getChartOfAccountBalance = (type) =>
  API.get(
    `/Accounts/ChartOfAccounts?type=${type}&active=true&fields=chartOfAccountId,name,type&orderby=name`
  );

export const getBankRecData = (nominlaId, reconcile) =>
  API.get(`/Accounts/bankrec/${nominlaId}?todate=${reconcile}`);

export const addBankRecs = (nominalRef, data) =>
  API.post(`/Accounts/bankrec/${nominalRef}/save`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
export const postBankRecs = (nominalRef, data) =>
  API.post(`/Accounts/bankrec/${nominalRef}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const editFeeHeader = (id, data) =>
  API.patch(`/Accounts/Bill/FixedFeeHeaders/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const createFixedFeeHeaderItems = (id, data) =>
  API.post(`/Accounts/Bill/FixedFeeItems/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getFeeHeaderItems = (id) =>
  API.get(`/Accounts/Bill/FixedFeeItems/${id}`);

export const getActiveFeeItems = (id) =>
  API.get(`/Accounts/Bill/FixedFeeItems/${id}?active=true`);

export const editFeeHeaderItems = (id, data) =>
  API.put(`/Accounts/Bill/FixedFeeItems/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getProfitCostDetails = () =>
  API.get(`/Accounts/Bill/FixedFeeHeaders`);

export const getActiveFeeHeaders = () =>
  API.get(
    `/Accounts/Bill/FixedFeeHeaders?active=true&type=Profitcost&Fields=fixedFeeHeaderID,name`
  );

export const addProfitCostTemplate = (data) =>
  API.post(`/Accounts/Bill/FixedFeeHeaders`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getBillingRequestDetails = (id) =>
  API.get(`/Accounts/BillRequest/${id}`);

export const AuthorizeChits = (data) =>
  API.post(`/Accounts/Chits/Authorise`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getBankRec = (subtype) => {
  const url = subtype
    ? `/Accounts/BankRec?active=true&subtype=${subtype}`
    : `/Accounts/BankRec?active=true`;
  return API.get(url);
};
