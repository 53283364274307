import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import {
  createChartOfAccounts,
  getNominalsByType,
  updateChartOfAccounts,
} from "api/accountsAPI";
import styles from "./ChatAccounts.module.scss";
import { getter } from "@progress/kendo-react-common";
import GroupItem from "./GroupItem";
import {
  updateGroups,
  transformGroupsToTreeData,
  transformTreeViewToAPI,
} from "./treeUtils";
import { toast } from "react-toastify";
import { orderBy } from "@progress/kendo-react-data-tools";
import {
  TreeView,
  TreeViewDragAnalyzer,
  moveTreeViewItem,
  processTreeViewItems,
  handleTreeViewCheckChange,
} from "@progress/kendo-react-treeview";

const AddEditChartAccounts = ({ onClose, editData, onSave }) => {
  const [formData, setFormData] = useState({
    expanded: true,
    name: editData?.name || "",
    type: editData?.type || "",
    active: editData?.active ?? true,
    groups: editData?.groups || [],
    id: editData?.chartOfAccountId || null,
  });

  const [treedata, setTreeData] = useState(
    transformGroupsToTreeData(editData?.groups || [])
  );

  const [availableItems, setAvailableItems] = useState([]);

  const [groupDialog, setGroupDialog] = useState({
    isOpen: false,
    isSubGroup: false,
    parentGroup: null,
  });

  const [editGroupDialog, setEditGroupDialog] = useState({
    isOpen: false,
    groupName: "",
    groupPath: [],
  });
  const initialSort = [
    {
      field: "nominalRef",
      dir: "asc",
    },
  ];
  const [sort, setSort] = useState(initialSort);
  const [selectedState, setSelectedState] = useState({});
  const [newGroupName, setNewGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupPath, setSelectedGroupPath] = useState([]);
  const [errors, setErrors] = useState({});
  const [addedItems, setAddedItems] = useState([]);
  const [clickedItem, setClickedItem] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    message: "",
    groupPath: [],
  });
  const [check, setCheck] = useState({
    ids: [],
    applyCheckIndeterminate: true,
  });
  const SEPARATOR = "_";
  const isDragDrop = useRef(false);
  const dragOverCnt = useRef(0);

  const SELECTED_FIELD = "selected";
  const idGetter = getter("nominalId");

  const fetchNominals = async (type) => {
    const response = await getNominalsByType(type || "BalanceSheet");
    setAvailableItems(response.data?.data);
  };

  useEffect(() => {
    fetchNominals(formData.type);
  }, [formData.type]);

  const handleEditGroupName = (groupPath) => {
    setEditGroupDialog({
      isOpen: true,
      groupName: groupPath[groupPath.length - 1],
      groupPath,
    });
  };

  const onCheckChange = (event) => {
    const settings = {
      singleMode: event.item.type === "group",
      checkChildren: false,
      checkParents: false,
    };

    const newCheckState = handleTreeViewCheckChange(
      event,
      check,
      treedata,
      settings
    );

    const checkedItemIndex = event.itemHierarchicalIndex;
    const checkedItemGroupIndex = checkedItemIndex
      .split("_")
      .slice(0, -1)
      .join("_");

    const isSameGroup = check.ids.every((id) => {
      const groupIndex = id.split("_").slice(0, -1).join("_");

      return groupIndex === checkedItemGroupIndex;
    });

    if (!isSameGroup) {
      newCheckState.ids = [checkedItemIndex];
    }

    if (event.item.type === "item") {
      if (newCheckState.type === "group") {
        newCheckState.ids = [checkedItemIndex];
        newCheckState.type = "item";
      }

      setClickedItem((prev = []) => {
        const newClickedItems = newCheckState.ids.includes(checkedItemIndex)
          ? [...prev, { id: event.itemHierarchicalIndex, item: event.item }]
          : (prev || []).filter((item) => item.id !== checkedItemIndex);
        return isSameGroup
          ? newClickedItems
          : [{ id: event.itemHierarchicalIndex, item: event.item }];
      });
      setSelectedGroup(null);
    } else {
      newCheckState.type = "group";
      setSelectedGroup(event.item.text);
      setSelectedGroupPath(event.item.path || [event.item.text]);
      setClickedItem([]);
    }
    setCheck(newCheckState);
  };

  const handleDeleteGroup = (groupPath) => {
    const group = getGroupByPath(formData.groups, groupPath);
    if (!group) return;

    const hasSubGroups = group.groups && group.groups.length > 0;
    const message = hasSubGroups
      ? `Are you sure you want to delete "${group.groupName}" and its subgroups?`
      : `Are you sure you want to delete "${group.groupName}"?`;

    setConfirmationDialog({
      isOpen: true,
      message,
      groupPath,
    });
  };

  const getGroupByPath = (groups, path) => {
    let currentGroup = null;
    for (let i = 0; i < path.length; i++) {
      currentGroup = groups.find((group) => group.groupName === path[i]);
      if (currentGroup && i < path.length - 1) {
        groups = currentGroup.groups;
      } else if (!currentGroup) {
        return null;
      }
    }
    return currentGroup;
  };

  const handleConfirmDelete = () => {
    const { groupPath } = confirmationDialog;

    const updateGroupsAfterDelete = (groups, path) => {
      return groups.filter((group) => {
        if (group.groupName === path[0]) {
          if (path.length === 1) {
            return false;
          }
          group.groups = updateGroupsAfterDelete(group.groups, path.slice(1));
        }
        return true;
      });
    };

    let updatedGroups = updateGroupsAfterDelete(formData.groups, groupPath);

    setFormData((prev) => ({
      ...prev,
      groups: updatedGroups,
    }));

    setTreeData(transformGroupsToTreeData(updatedGroups));

    if (selectedGroupPath.join() === groupPath.join()) {
      setSelectedGroup(null);
      setSelectedGroupPath([]);
    }

    setConfirmationDialog({
      isOpen: false,
      message: "",
      groupPath: [],
    });
  };

  const handleCancelDelete = () => {
    setConfirmationDialog({
      isOpen: false,
      message: "",
      groupPath: [],
    });
  };

  const handleSaveGroupName = () => {
    const { groupName, groupPath } = editGroupDialog;

    if (!groupName.trim()) {
      toast.info("Group name cannot be empty");
      return;
    }

    let updatedGroups = updateGroups(formData.groups, groupPath, (group) => ({
      ...group,
      groupName,
    }));

    setFormData((prev) => ({
      ...prev,
      groups: updatedGroups,
    }));

    setTreeData(transformGroupsToTreeData(updatedGroups));

    setEditGroupDialog({
      isOpen: false,
      groupName: "",
      groupPath: [],
    });
  };

  const onTreeItemSelect = (event) => {
    const { item } = event;

    if (item.type === "group") {
      setSelectedGroup(item.text);
      setSelectedGroupPath(item.path || [item.text]);
      setClickedItem([]);
      setCheck({ ids: [], applyCheckIndeterminate: true });
    } else {
      setSelectedGroup(null);
      setSelectedGroupPath(item.path.slice(0, -1));
    }
  };

  const updateOrderProperties = (groups) => {
    return groups.map((group, groupIndex) => {
      const updatedGroup = {
        ...group,
        order: groupIndex + 1,
        items: updateOrderForItems(group.items),
      };

      return updatedGroup;
    });
  };

  const updateOrderForItems = (items) => {
    return items.map((item, itemIndex) => {
      const updatedItem = {
        ...item,
        order: itemIndex + 1,
        items: item.items ? updateOrderForItems(item.items) : undefined,
      };

      return updatedItem;
    });
  };

  const onItemDragEnd = (event) => {
    isDragDrop.current = dragOverCnt.current > 0;
    dragOverCnt.current = 0;

    const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
    if (!eventAnalyzer.isDropAllowed) {
      console.warn("Drop operation not allowed.");
      return;
    }

    const draggedItem = event.item;
    const draggedIndex = event.itemHierarchicalIndex;
    const targetIndex = eventAnalyzer.destinationMeta.itemHierarchicalIndex;

    const findItemByIndex = (index, data) => {
      const indices = index.split(SEPARATOR).map(Number);
      let result = data;
      for (let i = 0; i < indices.length; i++) {
        if (!result[indices[i]]) {
          return null;
        }
        if (i === indices.length - 1) {
          return result[indices[i]];
        }
        result = result[indices[i]].items;
      }
      return null;
    };

    const targetItem = findItemByIndex(targetIndex, treedata);

    const isDraggedGroup =
      draggedItem.type === "group" && !draggedIndex.includes(SEPARATOR);
    const isDraggedSubgroup =
      draggedItem.type === "group" && draggedIndex.includes(SEPARATOR);
    const isDraggedItem = draggedItem.type === "item";

    const isTargetGroup =
      targetItem?.type === "group" && !targetIndex.includes(SEPARATOR);
    const isTargetSubgroup =
      targetItem?.type === "group" && targetIndex.includes(SEPARATOR);
    const isTargetItem = targetItem?.type === "item";

    if (
      (isDraggedGroup && !isTargetGroup) || // Groups can only be reordered with groups
      (isDraggedSubgroup && !isTargetSubgroup) || // Subgroups can only be reordered with subgroups
      (isDraggedItem && !isTargetItem) // Items can only be reordered with items
    ) {
      console.warn("Invalid reordering: Types do not match.");
      return;
    }

    const draggedIndices = draggedIndex.split(SEPARATOR).map(Number);
    const targetIndices = targetIndex.split(SEPARATOR).map(Number);
    const isMovingDown =
      JSON.stringify(draggedIndices) < JSON.stringify(targetIndices);

    const dropOperation = isMovingDown ? "after" : "before";

    const updatedTree = moveTreeViewItem(
      draggedIndex,
      treedata,
      dropOperation,
      targetIndex
    );

    if (updatedTree) {
      const updatedTreeWithOrder = updateOrderProperties(updatedTree);

      setFormData((prev) => ({
        ...prev,
        groups: transformTreeViewToAPI(updatedTreeWithOrder),
      }));

      setTreeData(updatedTreeWithOrder);
    } else {
      console.warn("Reordering failed. Tree not updated.");
    }
  };

  const handleMoveToGroup = () => {
    if (!selectedGroup) {
      return;
    }

    const selectedItems = transformedData.filter(
      (item) => selectedState[idGetter(item)]
    );

    if (selectedItems.length === 0) {
      toast.info("Please select items to move");
      return;
    }

    let updatedGroups = updateGroups(
      formData.groups,
      selectedGroupPath,
      (group) => ({
        ...group,
        items: [
          ...group.items,
          ...selectedItems.map((item, index) => ({
            nominalRef: item.nominalRef,
            nominalName: item.nominalName,
            order: (group.items.length || 0) + index + 1,
          })),
        ],
      })
    );

    setFormData((prev) => ({
      ...prev,
      groups: updatedGroups,
    }));

    setTreeData(transformGroupsToTreeData(updatedGroups));

    setAddedItems((prev) => [...prev, ...selectedItems]);

    setAvailableItems((prev) =>
      prev.filter(
        (availableItem) =>
          !selectedItems.some(
            (selectedItem) =>
              selectedItem.nominalRef === availableItem.nominalRef
          )
      )
    );

    setSelectedState({});
  };

  const handleRemoveFromGroup = () => {
    if (!clickedItem || clickedItem.length === 0) {
      toast.info("Please select an item to remove");
      return;
    }

    let updatedGroups = formData.groups;

    clickedItem.forEach(({ item }) => {
      const clickedItemNominalRef = item.text.split(" - ")[0];

      updatedGroups = updateGroups(
        updatedGroups,
        item.path.slice(0, -1),
        (group) => ({
          ...group,
          items: group.items.filter(
            (groupItem) => groupItem.nominalRef !== clickedItemNominalRef
          ),
        })
      );

      setAddedItems((prev) =>
        prev.filter(
          (addedItem) => addedItem.nominalRef !== clickedItemNominalRef
        )
      );

      const itemToAddBack = addedItems.find(
        (addedItem) => addedItem.nominalRef === clickedItemNominalRef
      );

      if (itemToAddBack) {
        setAvailableItems((prev) => [...prev, itemToAddBack]);
      }
    });

    setFormData((prev) => ({
      ...prev,
      groups: updatedGroups,
    }));

    setTreeData(transformGroupsToTreeData(updatedGroups));

    setClickedItem([]);
    setCheck({ ids: [], applyCheckIndeterminate: true });
  };

  const getAvailableGridItems = () => {
    const usedNominalRefs = new Set();

    const collectUsedRefs = (groups) => {
      groups?.forEach((group) => {
        group.items?.forEach((item) => {
          usedNominalRefs.add(item.nominalRef);
        });
        if (group.groups?.length) {
          collectUsedRefs(group.groups);
        }
      });
    };

    collectUsedRefs(formData.groups);
    return transformedData.filter(
      (item) => !usedNominalRefs.has(item.nominalRef)
    );
  };

  const handleAddGroup = () => {
    if (!newGroupName.trim()) return;

    const newGroup = {
      groupName: newGroupName,
      order: 1,
      items: [],
      groups: [],
    };

    let updatedGroups;

    if (groupDialog.isSubGroup && groupDialog.parentGroup) {
      updatedGroups = updateGroups(
        formData.groups,
        selectedGroupPath,
        (group) => ({
          ...group,
          groups: [...group.groups, newGroup],
        })
      );
    } else {
      updatedGroups = [...formData.groups, newGroup];
    }

    setFormData((prev) => ({
      ...prev,
      groups: updatedGroups,
    }));

    setTreeData(transformGroupsToTreeData(updatedGroups));

    setNewGroupName("");
    setGroupDialog({ isOpen: false, isSubGroup: false, parentGroup: null });
  };

  const handleSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: "nominalId",
    });
    setSelectedState(newSelectedState);
  };

  const transformedData = availableItems.map((item) => ({
    ...item,
    [SELECTED_FIELD]: selectedState[idGetter(item)],
  }));

  const handleChange = (e) => {
    fetchNominals(e.value);
    setFormData((prev) => ({ ...prev, type: e.value }));
  };

  const onExpandChange = (event) => {
    event.item.expanded = !event.item.expanded;
    transformGroupsToTreeData(formData.groups || []);
  };

  const handleSave = async () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      let response;
      if (editData?.chartOfAccountId) {
        response = await updateChartOfAccounts(formData.id, formData);
      } else {
        response = await createChartOfAccounts(formData);
      }

      if (response.status === 200) {
        onSave && onSave();
        // onClose();
        toast.success("Chart of accounts saved successfully");
      }
    } catch (error) {
      console.error("Error saving chart of accounts:", error);
    }
  };

  const handleSortChange = (event) => {
    setSort(event.sort);
  };

  return (
    <>
      <div
        className="p-4"
        style={{
          height: "calc(100% - 65px)",
        }}
      >
        <div className="row mb-4 align-items-center">
          <div className="col-3 me-3">
            <Label className="d-block mb-2">Name</Label>
            <Input
              style={{
                height: "36px",
              }}
              placeholder="Add Name"
              value={formData.name}
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, name: e.value }));
                if (e.value.trim()) {
                  setErrors((prev) => ({ ...prev, name: "" }));
                }
              }}
            />
            {errors.name && (
              <div className="fs-sm my-1" style={{ color: "red" }}>
                {errors.name}
              </div>
            )}
          </div>
          <div className="col-4  ">
            <Label className="d-block mb-2 ms-3">Type</Label>
            <RadioButton
              className="ms-3"
              name="Balance Sheet"
              value="BalanceSheet"
              checked={formData.type === "BalanceSheet"}
              label="Balance Sheet"
              onChange={handleChange}
              disabled={editData?.chartOfAccountId}
            />
            <RadioButton
              className="ms-3"
              name="Profit and Loss"
              value="ProfitAndLoss"
              checked={formData.type === "ProfitAndLoss"}
              label="Profit and Loss"
              onChange={handleChange}
              disabled={editData?.chartOfAccountId}
            />
          </div>
          <div className="col-1">
            <Checkbox
              className="mt-4"
              label="Active"
              checked={formData.active}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, active: e.value }))
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="col-5">
            <Grid
              className="grid-row-select"
              style={{
                maxHeight: "calc(100vh - 365px )",
              }}
              data={orderBy(getAvailableGridItems(), sort)}
              selectedField={SELECTED_FIELD}
              selectable={{
                enabled: true,
                cell: false,
                mode: "multiple",
              }}
              onSelectionChange={handleSelectionChange}
              sortable={true}
              sort={sort}
              onSortChange={handleSortChange}
            >
              <Column
                width="50px"
                field={SELECTED_FIELD}
                headerCell={() => <></>}
              />
              <Column field="nominalRef" title="Nominal Ref" width="110px" />
              <Column field="nominalName" title="Nominal Name" width="210px" />
              <Column field="subTypeName" title="Type" width="85px" />
            </Grid>
          </div>

          <div className="col-1 d-flex flex-column align-items-center justify-content-center">
            <Button
              className={`${styles["move-button"]} mb-2`}
              onClick={handleMoveToGroup}
              disabled={!selectedGroup}
            >
              <span> &gt; </span>
            </Button>
            <Button
              className={styles["move-button"]}
              onClick={handleRemoveFromGroup}
              disabled={!clickedItem}
            >
              <span> &lt; </span>
            </Button>
          </div>

          <div className="col-6 ">
            <div
              className="d-flex flex-column p-4 h-100"
              style={{
                border: "1px solid #e0e0e0",
                borderRadius: "5px",
                maxHeight: "calc(100vh - 365px)",
              }}
            >
              <div style={{ flex: 1, overflowY: "auto", marginBottom: "10px" }}>
                <TreeView
                  className={styles.treeviewItem}
                  data={processTreeViewItems(treedata, { check })}
                  item={(prop) => (
                    <GroupItem
                      {...prop}
                      item={prop.item}
                      selectedGroup={selectedGroup}
                      clickedItem={clickedItem}
                      handleEditGroupName={handleEditGroupName}
                      handleDeleteGroup={handleDeleteGroup}
                    />
                  )}
                  expandIcons={true}
                  onItemClick={onTreeItemSelect}
                  selected={selectedGroup}
                  onExpandChange={onExpandChange}
                  draggable={true}
                  onItemDragEnd={onItemDragEnd}
                  checkboxes={true}
                  onCheckChange={onCheckChange}
                />
              </div>
              <div className="d-flex gap-2 justify-content-end">
                <Button
                  className="common-btn"
                  onClick={() =>
                    setGroupDialog({
                      isOpen: true,
                      isSubGroup: false,
                      parentGroup: null,
                    })
                  }
                >
                  Add Main Group
                </Button>
                <Button
                  className="common-btn"
                  onClick={() => {
                    if (!selectedGroup) {
                      toast.info("Please select a parent group first");
                      return;
                    }
                    setGroupDialog({
                      isOpen: true,
                      isSubGroup: true,
                      parentGroup: selectedGroup,
                    });
                  }}
                  disabled={!selectedGroup || selectedGroupPath.length > 1}
                >
                  Add Sub Group
                </Button>
              </div>
            </div>
          </div>
        </div>

        {groupDialog.isOpen && (
          <Dialog
            title={`Add ${groupDialog.isSubGroup ? "Sub" : "Main"} Group`}
            onClose={() =>
              setGroupDialog({
                isOpen: false,
                isSubGroup: false,
                parentGroup: null,
              })
            }
            height="250px"
            width="400px"
          >
            <div className="p-4">
              {groupDialog.isSubGroup && (
                <div className="mb-2">
                  <Label>Parent Group: {selectedGroup}</Label>
                </div>
              )}
              <Label className="d-block mb-2">Group Name</Label>
              <Input
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.value)}
                placeholder="Enter group name"
              />
            </div>
            <div className="text-end">
              <Button
                onClick={() =>
                  setGroupDialog({
                    isOpen: false,
                    isSubGroup: false,
                    parentGroup: null,
                  })
                }
                className="me-2 common-btn button-small p-2"
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddGroup}
                className="me-4 common-btn button-small p-2"
              >
                Add
              </Button>
            </div>
          </Dialog>
        )}

        {editGroupDialog.isOpen && (
          <Dialog
            title="Edit Group Name"
            onClose={() =>
              setEditGroupDialog({
                isOpen: false,
                groupName: "",
                groupPath: [],
              })
            }
            height="225px"
            width="400px"
          >
            <div className="p-4">
              <Label className="d-block mb-2">Group Name</Label>
              <Input
                value={editGroupDialog.groupName}
                onChange={(e) =>
                  setEditGroupDialog((prev) => ({
                    ...prev,
                    groupName: e.value,
                  }))
                }
                placeholder="Rename Group"
              />
            </div>
            <div className="text-end">
              <Button
                onClick={() =>
                  setEditGroupDialog({
                    isOpen: false,
                    groupName: "",
                    groupPath: [],
                  })
                }
                className="me-2 common-btn button-small p-2"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveGroupName}
                className="me-4 common-btn button-small p-2"
              >
                Update
              </Button>
            </div>
          </Dialog>
        )}

        {confirmationDialog.isOpen && (
          <Dialog
            title="Confirm Delete"
            onClose={handleCancelDelete}
            width={400}
          >
            <div className="p-4">
              <p className="text-center">{confirmationDialog.message}</p>
            </div>
            <DialogActionsBar>
              <div className="d-flex align-items-center justify-content-center pb-3">
                <Button
                  onClick={handleCancelDelete}
                  className="me-2 common-btn button-small"
                >
                  No
                </Button>
                <Button
                  onClick={handleConfirmDelete}
                  className=" common-btn button-small"
                >
                  Yes
                </Button>
              </div>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
      <DialogActionsBar>
        <div className="dialog-footer ">
          <button onClick={onClose} className="me-2 common-btn">
            Cancel
          </button>
          <button
            onClick={() => handleSave(formData)}
            className="common-btn me-4"
          >
            Save
          </button>
        </div>
      </DialogActionsBar>
    </>
  );
};

export default AddEditChartAccounts;
